import * as React from 'react';
import { IndicatorProps } from 'react-select';

import classnames from 'classnames';

import { ReactComponent as DropdownArrow } from '../../images/Project/dropdown-arrow.svg';

const DropdownIndicator: React.FC<
  IndicatorProps<{ label: string; value: string }, false>
> = ({ selectProps }) => {
  const { menuIsOpen, isDisabled } = selectProps;
  return (
    <div
      className={classnames('fields-dropdown-arrow', {
        menuIsOpen,
        isDisabled,
      })}
    >
      <DropdownArrow />
    </div>
  );
};

export default DropdownIndicator;
