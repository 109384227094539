import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectActiveObject, setActiveObject } from 'store/mapSlice';
import { selectProjectName, setShowDetailsPanel } from 'store/projectSlice';
import { selectExperimentType, setShowResults } from 'store/taskSlice';

import { IProjectHeader } from '../types';
import ControlPanel from './ControlPanel';
import DrawPanel from './DrawPanel';
import MenuPanel from './MenuPanel';

const ProjectHeader: React.FC<IProjectHeader> = () => {
  const dispatch = useDispatch();
  const activeObject = useSelector(selectActiveObject);
  const experimentType = useSelector(selectExperimentType);
  const title = useSelector(selectProjectName);

  const onSettingsClick = () => {
    dispatch(
      setActiveObject({
        node_uid: activeObject.node_uid,
        type: null,
        object_uid: null,
        object_name: null,
      }),
    );
    dispatch(setShowDetailsPanel(null));
    dispatch(setShowResults(false));
  };

  return (
    <div className="project-header">
      <MenuPanel title={title} />
      <DrawPanel />
      <div className="project-header__control-panel">
        <ControlPanel
          experimentName={experimentType}
          onSettingsClick={onSettingsClick}
          activeObjects={activeObject.object_uid}
        />
      </div>
    </div>
  );
};

export default ProjectHeader;
