import * as React from 'react';

import classnames from 'classnames';

import Icons from './icons';
import { IProps } from './types';

import './style.scss';

class SearchInput extends React.Component<IProps> {
  onClearButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    this.props.clearInputValue();
  };

  render() {
    const { className, clearInputValue, ...inputProps } = this.props;
    return (
      <div className="search-input-container">
        <input
          {...inputProps}
          className={classnames('search-input', className)}
        />
        <div className="search-input-search-icon">
          <Icons.SearchIcon />
        </div>
        <button
          className="search-input-clear-button"
          onMouseDown={this.onClearButtonClick}
          type="button"
        >
          <Icons.ClearIcon />
        </button>
      </div>
    );
  }
}

export default SearchInput;
