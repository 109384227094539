import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';

import classNames from 'classnames';
import { components } from 'generated/apiTypes';
import {
  selectNodeById,
  selectShowDetailsPanel,
  selectWellById,
  selectWellControlModelById,
  selectWellFactMeasurementById,
  selectWellHydroModelById,
  selectWellModelById,
  setShowDetailsPanel,
  updateNodes,
  updateNodesNetwork,
  updateWell,
  updateWellModal,
  updateWellsControlModel,
  updateWellsFactMeasurement,
  updateWellsHydroModel,
} from 'store/projectSlice';
import { selectExperimentType } from 'store/taskSlice';

import {
  setNode as saveNode,
  setWell as saveWell,
  setWellControlModel as saveWellControlModel,
  setWellFactMeasurement as saveWellFactMeasurement,
  setWellHydroModel as saveWellHydroModel,
  setWellModel as saveWellModel,
} from 'services/apiRequests';
import { ReactComponent as DetailsIcon } from '../../../../images/Project/icn-details.svg';
import { IObject } from '../../types';
import ObjectInput from '../components/ObjectInput';
import { OptionType } from '../components/ObjectSelect';
import ObjectStringInput from '../components/ObjectStringInput';
import {
  getGasFractionOptions,
  getWaterFractionOptions,
} from '../components/options';
import SourceState from './SourceState';
import SourceView from './SourceView';
import SourceViewAF from './SourceViewAF';
import SourceViewGR from './SourceViewGR';

const Source: React.FC<IObject> = ({ nodeUid, objectUid, projectUid }) => {
  const wellsById = useSelector(selectWellById);
  const wellsModelsById = useSelector(selectWellModelById);
  const wellsControlModelsById = useSelector(selectWellControlModelById);
  const wellsHydroModelsById = useSelector(selectWellHydroModelById);
  const wellsFactMeasurementById = useSelector(selectWellFactMeasurementById);
  const nodeById = useSelector(selectNodeById);
  const experimentType = useSelector(selectExperimentType);
  const showDetailsPanel = useSelector(selectShowDetailsPanel);
  const dispatch = useDispatch();

  const [waterFractionType, setWaterFractionType] = React.useState<
    ValueType<OptionType<string>, false> | undefined
  >(getWaterFractionOptions()[0]);
  const [gasFractionType, setGasFractionType] = React.useState<
    ValueType<OptionType<string>, false> | undefined
  >(getGasFractionOptions()[0]);

  React.useEffect(() => {
    setWaterFractionType(getWaterFractionOptions()[0]);
    setGasFractionType(getGasFractionOptions()[0]);
    return () => {
      // dispatch(setShowDetailsPanel(null));
    };
  }, [objectUid]);

  const saveNewWellValue = async (value: number | string, name: string) => {
    const newValue = { ...wellsById[nodeUid as string], [name]: value };
    try {
      // TODO нужен рефакторинг всей этой части так она избыточна
      // и следует формировать параллельные запросы для таких вещей.
      // Также проследить какие данные уходят в редьюсеры и убрать
      // вспомогательные библиотечные методы update и сформировать
      // собственную функцию для таких вещей в тч отлавливать
      // ошибки которые все-таки должны возникать, когда прилетает массив
      // вместо объекта в редьюсер.
      const response = await saveWell(
        projectUid,
        newValue.uid as string,
        newValue,
      );
      dispatch(updateWell(response.data));
      if ((name as string) === 'name') {
        try {
          const newNodes = await saveNode(projectUid, nodeUid as string, {
            ...nodeById[nodeUid as string],
            [name]: response?.data.name,
          });
          const newNode = newNodes.data.nodes.find(
            (item: any) => item.uid === nodeUid,
          );
          dispatch(updateNodes(newNode));
          dispatch(updateNodesNetwork(true));
        } catch (e) {
          //
        }
      }
    } catch (e) {
      //
    }
  };

  const saveNewWellModelValue = async (
    value: number | string,
    name: string,
  ) => {
    const newValue = { ...wellsModelsById[objectUid as string], [name]: value };
    try {
      const response = await saveWellModel(
        projectUid,
        objectUid as string,
        newValue,
      );
      if (response.data) {
        dispatch(updateWellModal(response.data));
      }
    } catch (e) {
      //
    }
  };

  const saveNewWellControlValue = async (
    value: number | string,
    name: string,
  ) => {
    const newValue = {
      ...wellsControlModelsById[objectUid as string],
      [name]: value,
    };
    try {
      const response = await saveWellControlModel(
        projectUid,
        objectUid as string,
        newValue,
      );
      if (response.data) dispatch(updateWellsControlModel(response.data));
    } catch (e) {
      //
    }
  };

  const saveNewWellFactMeasurementValue = async (
    value: number | string,
    name: string,
  ) => {
    const newValue = {
      ...wellsFactMeasurementById[objectUid as string],
      [name]: value,
    };
    try {
      const response = await saveWellFactMeasurement(
        projectUid,
        objectUid as string,
        newValue,
      );
      if (response.data) dispatch(updateWellsFactMeasurement(response.data));
    } catch (e) {
      //
    }
  };

  const saveNewWellHydroValue = async (
    value: number | string,
    name: string,
  ) => {
    const newValue = {
      ...wellsHydroModelsById[objectUid as string],
      [name]: value,
    };
    try {
      const response = await saveWellHydroModel(
        projectUid,
        objectUid as string,
        newValue,
      );
      if (response.data) dispatch(updateWellsHydroModel(response.data));
    } catch (e) {
      //
    }
  };

  const saveNewNodeValue = async (value: number | string, name: string) => {
    let newValue: components['schemas']['PNOPipelineNode'];
    if (name === 'lat') {
      newValue = {
        ...nodeById[nodeUid as string],
        coordinates: [
          value as number,
          nodeById[nodeUid as string].coordinates?.[1],
        ],
      };
    } else if (name === 'lon') {
      newValue = {
        ...nodeById[nodeUid as string],
        coordinates: [
          nodeById[nodeUid as string].coordinates?.[0],
          value as number,
        ],
      };
    } else {
      newValue = { ...nodeById[nodeUid as string], [name]: value };
    }
    try {
      const response = await saveNode(
        projectUid,
        nodeById[nodeUid as string]?.uid as string,
        newValue,
      );
      if (response.data) {
        dispatch(updateNodes(newValue));
        dispatch(updateNodesNetwork(true));
      }
    } catch (e) {
      //
    }
  };

  const saveNewWaterFractionType = (
    value: ValueType<OptionType<string>, false>,
  ) => {
    setWaterFractionType(value);
  };

  const saveNewGasFractionType = (
    value: ValueType<OptionType<string>, false>,
  ) => {
    setGasFractionType(value);
  };

  const handleToggleDetails = () => {
    if (showDetailsPanel === 'wellModel') dispatch(setShowDetailsPanel(null));
    else dispatch(setShowDetailsPanel('wellModel'));
  };

  return (
    <div>
      <div className="active-object__top-row source-state-row">
        <SourceState
          state={wellsById[objectUid as string]?.current_state}
          saveNewValue={saveNewWellValue}
        />
        {['Узловой анализ', 'Оптимизация режима', 'Оптимизация сети'].includes(
          experimentType,
        ) && (
          <button
            className={classNames(
              'active-object__well-model-button',
              showDetailsPanel === 'wellModel' && 'active',
            )}
            type="button"
            onClick={handleToggleDetails}
          >
            <DetailsIcon className="well-model-button-icon" />
          </button>
        )}
      </div>
      <div>
        <div className="active-object__row">
          <ObjectStringInput
            className="input active-object__row-input long"
            label="Идентификатор"
            name="name"
            initValue={wellsById[objectUid as string]?.name}
            saveNewValue={saveNewWellValue}
          />
        </div>
        <div className="active-object__row">
          <label className="input-label">Координаты</label>
          <div className="d-flex">
            <div className="active-object__row-input-prefix">lon</div>
            <ObjectInput
              className="input active-object__row-input short with-prefix left input-with-prefix"
              label=""
              name="lon"
              initValue={nodeById[nodeUid as string]?.coordinates?.[1]}
              saveNewValue={saveNewNodeValue}
            />
            <div className="active-object__row-input-prefix">lat</div>
            <ObjectInput
              className="input active-object__row-input short with-prefix input-with-prefix"
              label=""
              name="lat"
              initValue={nodeById[nodeUid as string]?.coordinates?.[0]}
              saveNewValue={saveNewNodeValue}
            />
          </div>
        </div>
        {['Узловой анализ', 'Оптимизация режима', 'Оптимизация сети'].includes(
          experimentType,
        ) && (
          <SourceView
            well={wellsById[objectUid as string]}
            wellModel={wellsModelsById[objectUid as string]}
            wellControlModel={wellsControlModelsById[objectUid as string]}
            saveNewWellValue={saveNewWellValue}
            saveNewWellModelValue={saveNewWellModelValue}
            saveNewWellControlValue={saveNewWellControlValue}
            waterFractionType={
              waterFractionType as ValueType<OptionType<string>, false>
            }
            gasFractionType={
              gasFractionType as ValueType<OptionType<string>, false>
            }
            saveNewWaterFractionType={saveNewWaterFractionType}
            saveNewGasFractionType={saveNewGasFractionType}
          />
        )}
        {['Адаптация на факт'].includes(experimentType) && (
          <SourceViewAF
            well={wellsById[objectUid as string]}
            wellFactMeasurement={wellsFactMeasurementById[objectUid as string]}
            saveNewWellValue={saveNewWellValue}
            saveNewWellFactMeasurementValue={saveNewWellFactMeasurementValue}
            waterFractionType={
              waterFractionType as ValueType<OptionType<string>, false>
            }
            gasFractionType={
              gasFractionType as ValueType<OptionType<string>, false>
            }
            saveNewWaterFractionType={saveNewWaterFractionType}
            saveNewGasFractionType={saveNewGasFractionType}
          />
        )}
        {['Гидравлический расчет'].includes(experimentType) && (
          <SourceViewGR
            wellHydroModel={wellsHydroModelsById[objectUid as string]}
            well={wellsById[objectUid as string]}
            saveNewWellHydroModelValue={saveNewWellHydroValue}
            saveNewWellValue={saveNewWellValue}
            waterFractionType={
              waterFractionType as ValueType<OptionType<string>, false>
            }
            gasFractionType={
              gasFractionType as ValueType<OptionType<string>, false>
            }
            saveNewWaterFractionType={saveNewWaterFractionType}
            saveNewGasFractionType={saveNewGasFractionType}
          />
        )}
      </div>
    </div>
  );
};

export default Source;
