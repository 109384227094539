import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classnames from 'classnames';
import { ReactComponent as RefreshIcon } from 'images/Project/refresh.svg';
import { ReactComponent as ValidationMenuIcon } from 'images/Project/validation-menu.svg';
import {
  selectErrors,
  selectShowErrors,
  toggleIssues,
} from 'store/projectSlice';
import { selectExperimentType } from 'store/taskSlice';

import useValidation from './hooks/use-validation';
import { composeIssues } from './Issues/utils';

interface ILeftColBottom {
  taskId: string;
}

const LeftColBottom: React.FC<ILeftColBottom> = ({ taskId }) => {
  const dispatch = useDispatch();
  const showErrors = useSelector(selectShowErrors);
  const { fetchValidation } = useValidation(taskId);
  const handleToggleIssues = () => dispatch(toggleIssues());
  const errorsObj = useSelector(selectErrors);
  const experimentType = useSelector(selectExperimentType);

  const errorsArray = composeIssues(experimentType)(errorsObj);

  return (
    <div className="project-left-col-bottom">
      <p
        className={classnames('project-validation-title', {
          success: !errorsArray.length,
          error: errorsArray.length,
        })}
      >
        {errorsArray.length} ошибок
      </p>
      <div className="project-left-col-bottom__handlers">
        <button
          type="button"
          onClick={handleToggleIssues}
          className={classnames('project-left-col-bottom__button', {
            active: showErrors,
          })}
        >
          <ValidationMenuIcon />
        </button>
        <button
          type="button"
          onClick={fetchValidation}
          className="project-left-col-bottom__button"
        >
          <RefreshIcon />
        </button>
      </div>
    </div>
  );
};

export default LeftColBottom;
