import * as React from 'react';

import { ReactComponent as DeleteIcon } from '../../../../images/Project/delete-icon.svg';

interface IDeleteButton {
  index: number;
  deletePoint(ix: number): void;
}

const DeleteButton: React.FC<IDeleteButton> = ({ index, deletePoint }) => {
  return (
    <button
      type="button"
      className="active-object__delete-point-btn"
      onClick={() => {
        deletePoint(index);
      }}
    >
      <DeleteIcon />
    </button>
  );
};

export default DeleteButton;
