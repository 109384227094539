import * as React from 'react';

interface IToggleList {
  topRow: React.ReactNode;
  body: React.ReactNode;
  setActiveList(val: boolean): void;
  activeList: boolean;
}

const ToggleList: React.FC<IToggleList> = ({
  topRow,
  body,
  setActiveList,
  activeList,
}) => {
  const onClickHandler = () => {
    setActiveList(!activeList);
  };

  return (
    <div>
      <div
        role="button"
        tabIndex={-1}
        onKeyPress={onClickHandler}
        onClick={onClickHandler}
        style={{ cursor: 'pointer' }}
      >
        {topRow}
      </div>
      {activeList && <>{body}</>}
    </div>
  );
};

export default ToggleList;
