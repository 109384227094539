import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { selectSolution } from 'store/taskSlice';

import { components } from '../../../../../generated/apiTypes';
import TableWrapper from '../../components/TableWrapper';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { nodesTableHead, templateRowsNumber } from './utils';

const Table: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNONetworkFactAdaptationProblem'];
  const headKeys = Object.keys(nodesTableHead);
  const [data, setData] = React.useState<any[]>([]);

  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.node_table?.integral_nodes_states.forEach(
      node_state => {
        newData.push({
          name: solution?.solution?.node_table.pipeline_nodes.find(
            node => node.uid === node_state.node_uid,
          )?.name,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fluid_flow_rate: node_state?.fluid_flow_rate_volume?.m3_per_day,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          oil_flow_rate: node_state?.oil_flow_rate_mass?.t_per_day,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          gas_flow_rate: node_state?.gas_flow_rate_volume?.thousand_m3_per_day,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          temperature: node_state?.temperature?.celsius,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pressure_fact: node_state?.fact_pressure?.atm,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pressure_calc: node_state?.calc_pressure?.atm,
          pressure_abs_error: node_state?.abs_pressures_error,
          pressure_rel_error: node_state?.rel_pressures_error,
        });
      },
    );
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__nodes-table')}>
        <TableHead headKeys={headKeys} tableHead={nodesTableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default Table;
