import * as React from 'react';

import classnames from 'classnames';
import { ReactComponent as FileAdd } from 'images/NewProject/file-add-fill.svg';
import { ReactComponent as FileDownload } from 'images/NewProject/file-download-fill.svg';

interface ICreateProjectButtons {
  active: 'project' | 'file';
  setActive: React.Dispatch<React.SetStateAction<'project' | 'file'>>;
}

const CreateProjectButtons: React.FC<ICreateProjectButtons> = ({
  active,
  setActive,
}) => (
  <div className="create-project-buttons">
    <button
      className={classnames(
        'create-project-buttons__button create-project-buttons__button-new-project',
        { active: active === 'project' },
      )}
      onClick={() => setActive('project')}
      type="button"
    >
      <FileAdd className="create-project-buttons__button-icon" />
      Новый проект
    </button>
    <button
      className={classnames(
        'create-project-buttons__button create-project-buttons__button-file',
        { active: active === 'file' },
      )}
      type="button"
      onClick={() => setActive('file')}
    >
      <FileDownload className="create-project-buttons__button-icon" />
      Файл проекта
    </button>
  </div>
);

export default CreateProjectButtons;
