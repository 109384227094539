import * as React from 'react';

export const templateRowsNumber = 5;

export const nodesTableHead = {
  name: 'Узел',
  oil_flow_rate: {
    title: 'Факт. Qн, т/сут',
    component: <>Факт. Qн, т/сут</>,
  },
  fluid_flow_rate: {
    title: 'Факт. Qж, м3/сут',
    component: (
      <>
        Факт. Qж,{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Факт. Qг, м3/сут',
    component: (
      <>
        Факт. Qг,{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  pressure_fact: 'Факт. P, атм',
  pressure_calc: 'Расч. P, атм',
  pressure_abs_error: 'ΔP, атм',
  pressure_rel_error: 'δP, %',
  temperature: 't смеси, ˚C',
};
