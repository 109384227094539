import * as React from 'react';

import classNames from 'classnames';

interface IMenuList {
  className?: string;
  closeMenu(): void;
  controlRef: React.RefObject<any>;
}

const MenuList: React.FC<IMenuList> = ({
  children,
  className,
  closeMenu,
  controlRef,
}) => {
  const listRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        !listRef?.current?.contains(event.target) &&
        !controlRef?.current?.contains(event.target)
      ) {
        closeMenu();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div
      className={classNames('project-header__menu-list', className)}
      ref={listRef}
    >
      {children}
    </div>
  );
};

export default MenuList;
