import * as React from 'react';

import { formatter } from '../../../../../utils';

export const dashboardItems = ({
  fluid_flow_rate,
  oil_flow_rate,
  gas_flow_rate,
  pressure,
  temperature,
}: any = {}) => [
  {
    dem: 'Qн, т/сут',
    value:
      oil_flow_rate !== undefined
        ? formatter.format(oil_flow_rate)
        : oil_flow_rate,
  },
  {
    dem: (
      <>
        Qж,{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
    value:
      fluid_flow_rate !== undefined
        ? formatter.format(fluid_flow_rate)
        : fluid_flow_rate,
  },
  {
    dem: (
      <>
        Qг,{' тыс '}
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
    value:
      gas_flow_rate !== undefined
        ? formatter.format(gas_flow_rate)
        : gas_flow_rate,
  },
  {
    dem: 'P, атм',
    value: pressure !== undefined ? formatter.format(pressure) : pressure,
  },
  {
    dem: 't, ˚C',
    value:
      temperature !== undefined ? formatter.format(temperature) : temperature,
  },
];
