import { ChartOptions } from 'chart.js';

import { components } from '../../../../../generated/apiTypes';
import { ChartType } from '../types';

export const seriesColors = [
  '#019DDC',
  '#FF72BE',
  '#FAB400',
  '#23D692',
  '#FA8700',
  '#F9D100',
  '#F153FF',
  '#644BA5',
  '#FA3C00',
];

export const getOptions = (
  chartType: ChartType,
  wellOperationType: components['schemas']['PNOWellOperationType'],
): ChartOptions => {
  const getLegendNameByType = () => {
    if (wellOperationType === 'Газлифт') {
      return 'Расход газлифтного газа (Qг/г)';
    }
    return 'Частоты (F)';
  };

  const getYAxisTitleByType = () => {
    switch (chartType) {
      case 'FlowRates':
        return 'Qж, м3/сут';
      case 'Power':
        return 'Мощность, Дж';
      case 'BottomHolePressures':
        return 'P заб, атм';
      case 'Amperage':
        return 'Сила тока, А';
      case 'Loading':
        return 'Загрузка, %';
      default:
        return '';
    }
  };

  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          pointStyle: 'line',
          usePointStyle: true,
        },
        title: {
          display: true,
          text: getLegendNameByType(),
          position: 'start',
          color: '#aaa',
          font: {
            family: 'Inter, sans-serif',
            size: 10,
          },
          padding: { top: 10, bottom: 6 },
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: true,
          borderWidth: 0,
          drawBorder: false,
        },
        title: {
          display: true,
          text: getYAxisTitleByType(),
          color: '#aaa',
          font: {
            family: 'Inter, sans-serif',
            size: 10,
          },
          padding: { top: 0, bottom: 4 },
        },
        ticks: {
          padding: 0,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
      x: {
        grid: {
          display: true,
          borderDash: [6, 3],
          borderWidth: 0,
          drawBorder: false,
        },
        title: {
          display: true,
          text: 'Р лин, атм',
          color: '#aaa',
          font: {
            family: 'Inter, sans-serif',
            size: 10,
          },
          padding: { top: 4, bottom: 0 },
        },
        ticks: {
          padding: 0,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
    },
  };
};
