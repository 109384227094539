import * as React from 'react';
import { useSelector } from 'react-redux';
import { ValueType } from 'react-select';

import { components } from '../../../../generated/apiTypes';
import { ReactComponent as LinkedIcon } from '../../../../images/Project/linked-icon.svg';
import { selectPhysChemPackages } from '../../../../store/projectSlice';
import ObjectInput from '../components/ObjectInput';
import ObjectSelect, { OptionType } from '../components/ObjectSelect';
import {
  getGasFractionOptions,
  getWaterFractionOptions,
  getWellControlTypeOptions,
  getWellOperationTypeOptions,
  getWellPidStateOptions,
} from '../components/options';

interface ISourceView {
  well: components['schemas']['PNOWell'] | undefined;
  wellModel: components['schemas']['PNOWellModel'] | undefined;
  wellControlModel: components['schemas']['PNOWellControlModel'] | undefined;
  saveNewWellValue(value: number | string, name: string): void;
  saveNewWellModelValue(value: number | string, name: string): void;
  saveNewWellControlValue(value: number | string, name: string): void;
  waterFractionType: ValueType<OptionType<string>, false>;
  gasFractionType: ValueType<OptionType<string>, false>;
  saveNewWaterFractionType(value: ValueType<OptionType<string>, false>): void;
  saveNewGasFractionType(value: ValueType<OptionType<string>, false>): void;
}

const SourceView: React.FC<ISourceView> = ({
  well,
  wellModel,
  wellControlModel,
  saveNewWellValue,
  saveNewWellModelValue,
  saveNewWellControlValue,
  waterFractionType,
  gasFractionType,
  saveNewWaterFractionType,
  saveNewGasFractionType,
}) => {
  const physChemPackages = useSelector(selectPhysChemPackages);
  const PCPackages: OptionType<string>[] = physChemPackages.map(value => {
    return {
      value: value?.uid as string,
      label: value?.name as string,
    };
  });
  return (
    <>
      <div className="active-object__row">
        <div className="d-flex">
          <ObjectSelect
            label="Способ эксплуатации"
            classNamesWrapper=""
            classNames="active-object__row-input short left"
            name="operation_type"
            options={getWellOperationTypeOptions()}
            value={getWellOperationTypeOptions()?.find(
              el => el.value === well?.operation_type,
            )}
            saveNewValue={saveNewWellValue}
          />
          {well?.operation_type === 'ЭЦН' && (
            <div className="active-object__input-with-unit-wrapper">
              <ObjectInput
                className="input active-object__row-input short with-unit"
                label="Частота работы"
                name="pumping_control_action"
                unit="hertz"
                initValue={wellControlModel?.pumping_control_action?.hertz}
                saveNewValue={saveNewWellControlValue}
              />
              <p className="active-object__row-input-unit">Гц</p>
            </div>
          )}
        </div>
      </div>
      <div className="active-object__row">
        <label className="input-label">Обводненность</label>
        <div className="d-flex">
          <ObjectSelect
            label=""
            classNamesWrapper=""
            classNames="active-object__row-input short"
            name=""
            options={getWaterFractionOptions()}
            value={waterFractionType}
            saveNewOption={saveNewWaterFractionType}
          />
          <LinkedIcon className="active-object__linked-icon" />
          <div className="active-object__input-with-unit-wrapper">
            {waterFractionType?.value === 'wor' ? (
              <>
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label=""
                  name="water_fraction"
                  unit="m3_per_m3"
                  subName="wor"
                  initValue={well?.water_fraction?.wor?.m3_per_m3}
                  saveNewValue={saveNewWellValue}
                />
                <p className="active-object__row-input-unit">
                  <span className="relative">
                    м<span className="index small">3</span>
                  </span>
                  &nbsp; /
                  <span className="relative">
                    м<span className="index small">3</span>
                  </span>
                  &ensp;
                </p>{' '}
              </>
            ) : (
              <>
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label=""
                  name="water_fraction"
                  subName="wct"
                  unit="percents"
                  maxValue={99.999}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  initValue={well?.water_fraction?.wct?.percents}
                  saveNewValue={saveNewWellValue}
                />
                <p className="active-object__row-input-unit">%</p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <label className="input-label">Газовый фактор</label>
        <div className="d-flex">
          <ObjectSelect
            label=""
            classNamesWrapper=""
            classNames="active-object__row-input short"
            options={getGasFractionOptions()}
            name=""
            value={gasFractionType}
            saveNewOption={saveNewGasFractionType}
          />
          <LinkedIcon className="active-object__linked-icon" />
          <div className="active-object__input-with-unit-wrapper">
            {gasFractionType?.value === 'gor' ? (
              <>
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label=""
                  name="gas_fraction"
                  subName="gor"
                  unit="m3_per_m3"
                  extraSubName="oil_density"
                  extraObject={well?.gas_fraction.oil_density}
                  initValue={well?.gas_fraction?.gor?.m3_per_m3}
                  saveNewValue={saveNewWellValue}
                />
                <p className="active-object__row-input-unit">
                  <span className="relative">
                    м<span className="index small">3</span>
                  </span>
                  &nbsp; /
                  <span className="relative">
                    м<span className="index small">3</span>
                  </span>
                  &ensp;
                </p>
              </>
            ) : (
              <>
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label=""
                  name="gas_fraction"
                  subName="gas_factor"
                  unit="m3_per_t"
                  extraSubName="oil_density"
                  extraObject={well?.gas_fraction.oil_density}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  initValue={well?.gas_fraction?.gas_factor?.m3_per_t}
                  saveNewValue={saveNewWellValue}
                />
                <p className="active-object__row-input-unit">
                  <span className="relative">
                    м<span className="index small">3</span>
                  </span>
                  &nbsp; /т
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="active-object__input-with-unit-wrapper short">
          <ObjectInput
            className="input active-object__row-input with-unit"
            label="Температура смеси"
            name="temperature"
            unit="celsius"
            initValue={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              well?.temperature?.celsius
            }
            saveNewValue={saveNewWellValue}
          />
          <p className="active-object__row-input-unit">˚C</p>
        </div>
      </div>
      <div className="active-object__row">
        <div className="active-object__input-with-unit-wrapper">
          <ObjectSelect
            label="Пакет ФХС"
            classNames="active-object__row-input"
            classNamesWrapper="active-object__row-input-wrapper wide"
            name="phys_chem_package_id"
            options={PCPackages}
            value={PCPackages?.find(
              el => el.value === well?.phys_chem_package_id,
            )}
            saveNewValue={saveNewWellValue}
          />
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <ObjectSelect
            label="Режим работы"
            classNames="active-object__row-input short left"
            classNamesWrapper=""
            name="control_type"
            options={getWellControlTypeOptions()}
            value={getWellControlTypeOptions()?.find(
              el => el.value === wellControlModel?.control_type,
            )}
            saveNewValue={saveNewWellControlValue}
          />
          {['ЭЦН', 'Газлифт'].includes(well?.operation_type as string) &&
            ['ПКВ', 'ЧЧ'].includes(
              wellControlModel?.control_type as string,
            ) && (
              <div className="active-object__input-with-unit-wrapper">
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label="Отступ от начала цикла"
                  name="offset_time"
                  initValue={wellControlModel?.offset_time}
                  saveNewValue={saveNewWellControlValue}
                />
                <p className="active-object__row-input-unit">мин</p>
              </div>
            )}
        </div>
      </div>
      {well?.operation_type === 'ЭЦН' && (
        <div className="active-object__row">
          <div className="d-flex">
            <ObjectSelect
              label="Тип уставки ПИД"
              classNames="active-object__row-input short left"
              classNamesWrapper=""
              name="pid_state"
              options={getWellPidStateOptions()}
              value={getWellPidStateOptions()?.find(
                el => el.value === wellControlModel?.pid_state,
              )}
              saveNewValue={saveNewWellControlValue}
            />
            {(wellControlModel?.pid_state === 'Ток' ||
              wellControlModel?.pid_state === 'Загрузка') && (
              <div className="active-object__input-with-unit-wrapper">
                <ObjectInput
                  className="input active-object__row-input short with-unit"
                  label="Значение уставки ПИД"
                  name={
                    wellControlModel?.pid_state === 'Ток'
                      ? 'pid_current'
                      : 'pid_load'
                  }
                  unit={wellControlModel?.pid_state === 'Ток' ? 'amperes' : ''}
                  initValue={
                    wellControlModel?.pid_state === 'Ток'
                      ? wellControlModel?.pid_current?.amperes
                      : wellControlModel?.pid_load
                  }
                  saveNewValue={saveNewWellControlValue}
                />
                <p className="active-object__row-input-unit">
                  {wellControlModel?.pid_state === 'Ток' ? 'A' : '%'}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {['ЭЦН', 'Газлифт'].includes(well?.operation_type as string) &&
        ['ПКВ', 'ЧЧ'].includes(wellControlModel?.control_type as string) && (
          <>
            <div className="active-object__row">
              <div className="d-flex">
                <div className="active-object__input-with-unit-wrapper left">
                  <ObjectInput
                    className="input active-object__row-input short with-unit"
                    label="Время работы"
                    name="pumping_time"
                    initValue={wellControlModel?.pumping_time}
                    saveNewValue={saveNewWellControlValue}
                  />
                  <p className="active-object__row-input-unit">мин</p>
                </div>
                <div className="active-object__input-with-unit-wrapper">
                  <ObjectInput
                    className="input active-object__row-input short with-unit"
                    label={
                      wellControlModel?.control_type === 'ПКВ'
                        ? 'Время накопления'
                        : 'Время паузы'
                    }
                    name="accumulation_time"
                    initValue={wellControlModel?.accumulation_time}
                    saveNewValue={saveNewWellControlValue}
                  />
                  <p className="active-object__row-input-unit">мин</p>
                </div>
              </div>
            </div>
            {wellControlModel?.control_type === 'ЧЧ' && (
              <div className="active-object__row">
                <div className="active-object__input-with-unit-wrapper short">
                  <ObjectInput
                    className="input active-object__row-input with-unit"
                    label="Частота паузы"
                    name="accumulation_control_action"
                    unit="hertz"
                    initValue={
                      wellControlModel?.accumulation_control_action?.hertz
                    }
                    saveNewValue={saveNewWellControlValue}
                  />
                  <p className="active-object__row-input-unit">Гц</p>
                </div>
              </div>
            )}
          </>
        )}
      {well?.operation_type === 'Газлифт' && (
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper short">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Расход газлифтного газа"
              name="pumping_control_action"
              unit="m3_per_second"
              initValue={
                wellControlModel?.pumping_control_action?.m3_per_second
              }
              saveNewValue={saveNewWellControlValue}
            />
            <p className="active-object__row-input-unit">
              <span className="relative">
                м<span className="index small">3</span>
              </span>
              &nbsp; /сут
            </p>
          </div>
        </div>
      )}
      <p className="active-object__subtitle limitations">
        Технологические ограничения
      </p>
      {well?.operation_type === 'ЭЦН' && (
        <div className="active-object__row">
          <label className="input-label">Частота</label>
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <div className="active-object__row-input-prefix">min</div>
              <ObjectInput
                className="input active-object__row-input with-prefix with-unit input-with-prefix"
                label=""
                name="min_control_action"
                unit="hertz"
                initValue={wellModel?.min_control_action?.hertz}
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">Гц</p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <div className="active-object__row-input-prefix">max</div>
              <ObjectInput
                className="input active-object__row-input with-prefix with-unit input-with-prefix"
                label=""
                name="max_control_action"
                unit="hertz"
                initValue={wellModel?.max_control_action?.hertz}
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">Гц</p>
            </div>
          </div>
        </div>
      )}
      {well?.operation_type === 'Газлифт' && (
        <div className="active-object__row">
          <label className="input-label">Расход г/г</label>
          <div className="d-flex">
            <div className="active-object__input-with-unit-wrapper left">
              <div className="active-object__row-input-prefix">min</div>
              <ObjectInput
                className="input active-object__row-input with-prefix with-unit input-with-prefix"
                label=""
                name="min_control_action"
                unit="m3_per_day"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                initValue={wellModel?.min_control_action.m3_per_day}
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">
                <span className="relative">
                  м<span className="index small">3</span>
                </span>
                &nbsp; /сут
              </p>
            </div>
            <div className="active-object__input-with-unit-wrapper">
              <div className="active-object__row-input-prefix">max</div>
              <ObjectInput
                className="input active-object__row-input with-prefix with-unit input-with-prefix"
                label=""
                name="max_control_action"
                unit="m3_per_day"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                initValue={wellModel?.max_control_action.m3_per_day}
                saveNewValue={saveNewWellModelValue}
              />
              <p className="active-object__row-input-unit">
                <span className="relative">
                  м<span className="index small">3</span>
                </span>
                &nbsp; /сут
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="active-object__row">
        <label className="input-label">Допустимое давление</label>
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper">
            <div className="active-object__row-input-prefix">max</div>
            <ObjectInput
              className="input active-object__row-input with-prefix with-unit input-with-prefix"
              label=""
              name="max_allowed_pressure"
              unit="atm"
              initValue={
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                wellModel?.max_allowed_pressure.atm
              }
              saveNewValue={saveNewWellModelValue}
            />
            <p className="active-object__row-input-unit">атм</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SourceView;
