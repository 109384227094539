import * as React from 'react';

import ResultsPanel from './ResultsPanel';

interface ILeftCol {
  showResultPanel: boolean;
  taskId: string | null;
}

const LeftCol: React.FC<ILeftCol> = ({ children, showResultPanel, taskId }) => {
  if (showResultPanel) {
    return <ResultsPanel taskId={taskId} />;
  }

  return <div className="project-left-col">{children}</div>;
};

export default LeftCol;
