import React from 'react';

import classNames from 'classnames';

import './style.scss';

interface ISkeletonProps {
  className: string;
}

const Skeleton: React.FC<ISkeletonProps> = ({ className }) => {
  return <div className={classNames('skeleton-root', className)} />;
};

export default Skeleton;
