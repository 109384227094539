import AppIndexedDB from './AppIndexedDB';

export default class RequestSocketActions extends AppIndexedDB {
  getData = async () => {
    return this.requestSocketActions.toArray();
  };

  addData = async (data: any) => {
    if (!data.object_uid) {
      const params: any = { ...data };
      if (data.params.uid || data.params.node_uid || data.params.segment_uid)
        params.object_uid =
          data.params.uid || data.params.node_uid || data.params.segment_uid;
      await this.requestSocketActions.add(params);
    }
  };

  deleteData = async (data: any) => {
    const beforeDataDB = await this.requestSocketActions.toArray();
    console.log('beforeDataDB', beforeDataDB);
    await this.requestSocketActions
      .where({
        command: data.command,
        object_uid:
          data.params.uid || data.params.node_uid || data.params.segment_uid,
      })
      .delete();
    const afterDataDB = await this.requestSocketActions.toArray();
    console.log('afterDataDB', afterDataDB);
  };
}
