import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { RootState } from './store';
import { ICommonSlice } from './types';

const commonInitialState = initialState as ICommonSlice;

export const commonSlice = createSlice({
  name: 'common',
  initialState: { showDialog: commonInitialState.showDialog, auth: false },
  reducers: {
    setShowDialog: (
      state,
      action: PayloadAction<ICommonSlice['showDialog']>,
    ) => {
      state.showDialog = { ...state.showDialog, ...action.payload };
    },
    setAuth: (state, action: PayloadAction<ICommonSlice['auth']>) => {
      state.auth = action.payload;
    },
    resetCommonSliceState: state => {
      state.showDialog = commonInitialState.showDialog;
    },
  },
});

export const { setShowDialog, resetCommonSliceState } = commonSlice.actions;

const selectSelf = (state: RootState) => state.common;

export const selectShowDialog = createSelector(
  selectSelf,
  common => common.showDialog,
);

export const selectAuth = createSelector(selectSelf, common => common.auth);
export default commonSlice.reducer;
