import * as React from 'react';

import EditModel from './EditModel';
import NoModelView from './NoModelView';
import { ITable } from './types';
import ViewModel from './ViewModel';

import './style.scss';

const Table: React.FC<ITable> = ({
  wellOperationType,
  tableType,
  tableState,
  setTableState,
}) => {
  return (
    <>
      {tableState === 'noModel' && (
        <NoModelView
          createNewModel={() => {
            setTableState('edit');
          }}
        />
      )}
      {tableState === 'view' && (
        <ViewModel
          tableType={tableType}
          wellOperationType={wellOperationType}
          editModel={() => {
            setTableState('edit');
          }}
        />
      )}
      {tableState === 'edit' && (
        <EditModel
          tableType={tableType}
          wellOperationType={wellOperationType}
          setViewMode={() => {
            setTableState('view');
          }}
        />
      )}
    </>
  );
};

export default Table;
