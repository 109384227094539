import { IStore } from './types';

export const initialState: IStore = {
  id: null,
  name: '',
  taskId: '',
  issuesState: {
    show: false,
    issues: {
      nodal_analysis_issues: [],
      hydraulic_issues: [],
      calibration_issues: [],
      tech_mode_optimization_issues: [],
      tech_network_optimization_issues: [],
    },
  },
  showDetailsPanel: null,
  experiment: {
    type: 'Узловой анализ',
    status: 'INITIAL',
  },
  showResults: false,
  showDialog: {
    dialogType: null,
    objectType: null,
    objectName: null,
    objectId: null,
    isConfirm: null,
    isDiscard: null,
    isCancel: null,
  },
  activeObject: {
    node_uid: null,
    type: null,
    object_uid: null,
    object_name: null,
  },
  nodes: {
    items: [],
    isFetching: false,
    isUpdate: false,
  },
  wells: {
    items: [],
    isFetching: false,
  },
  wellsModels: { items: [], updatedModels: [] },
  wellsControlModels: [],
  wellsHydroModels: [],
  wellsFactMeasurements: [],
  gatheringCentersModels: [],
  gatheringCentersFactPressures: [],
  segments: {
    items: [],
    isFetching: false,
    isUpdate: false,
  },
  physChemPackages: [],
  pipesCatalog: [],
  solution: { type: 'Узловой анализ', solution: null },
  drawState: {
    isActive: false,
    activeElement: null,
    params: null,
  },
  loopingsState: { items: [] },
  featureState: {
    uid: null,
    type: null,
    name: null,
    coordinates: [],
  },
  projects: {
    items: null,
    isFetching: false,
  },
  auth: false,
  socketState: {
    isConnect: false,
    isLoading: false,
    sendData: null,
    receiveData: null,
    error: null,
  },
  mapContext: null,
  linesColor: null,
};
