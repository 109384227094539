import * as React from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { selectIntegralCharacteristics } from 'store/taskSlice';

import Skeleton from '../../../../../Components/Skeleton';
import { components } from '../../../../../generated/apiTypes';
import { dashboardItems } from './utils';

const Dashboard: React.FC = () => {
  const integralCharacteristics = useSelector(
    selectIntegralCharacteristics,
  ) as components['schemas']['PNOPeriodicNodalAnalysisSolutionIntegralCharacteristic'];

  return (
    <div className="dashboard">
      {dashboardItems(integralCharacteristics).map((el, key) => {
        return (
          <div key={key} className={classNames('dashboard__item', el.style)}>
            {key < 3 && (
              <div className="min-max-values">
                <p>
                  min:{' '}
                  {el.min === undefined ? (
                    <Skeleton className="skeleton-dashboard min-max" />
                  ) : (
                    el.min
                  )}
                </p>
                <p>
                  {' '}
                  max:{' '}
                  {el.max === undefined ? (
                    <Skeleton className="skeleton-dashboard min-max" />
                  ) : (
                    el.max
                  )}
                </p>
              </div>
            )}
            <p className="dashboard__item-dem">{el.dem}</p>
            <div className="dashboard__bottom">
              <p className="dashboard__item-value">
                {el.value === undefined ? (
                  <Skeleton
                    className={classNames(
                      'skeleton-dashboard',
                      key > 2 && 'short',
                    )}
                  />
                ) : (
                  el.value
                )}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Dashboard;
