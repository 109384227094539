export const formatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 2,
});

export const accurateFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 6,
});

export const oneDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

export const twoDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 2,
});

export const threeDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 3,
});

export const coordsFormatter = new Intl.NumberFormat('ru', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 8,
});

export const shortenTitle = (title: string, maxLength: number): string => {
  if (title.length > maxLength) {
    return `${title.slice(0, maxLength - 1)} ...`;
  }
  return title;
};
