import React from 'react';

import Skeleton from '../../Components/Skeleton';

import './style.scss';

const TemplateProjectRow: React.FC = () => {
  return (
    <div className="project-row">
      <div className="project-row-name">
        <Skeleton className="skeleton-project-name" />
      </div>
      <div className="project-row-date">
        <Skeleton className="skeleton-project-date" />
      </div>
    </div>
  );
};

export default TemplateProjectRow;
