import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { selectTaskId } from 'store/taskSlice';

import { cancelTask, deleteProject } from '../../services/apiRequests';
import { selectShowDialog, setShowDialog } from '../../store/commonSlice';
import { setProjects } from '../../store/projectsSlice';
import { RootState } from '../../store/store';
import CancelTaskConfirmationWindow from './CancelTaskConfirmationWindow';
import DeleteConfirmationWindow from './DeleteConfirmationWindow';
import WellModelConfirmationWindow from './WellModelConfirmationWindow';

const DialogWindow: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const showDialog = useSelector(selectShowDialog);
  const taskId = useSelector(selectTaskId);

  const closeDialog = () => {
    dispatch(
      setShowDialog({
        dialogType: null,
        objectType: null,
        objectName: null,
        objectId: null,
        isConfirm: null,
        isDiscard: null,
        isCancel: true,
      }),
    );
  };

  const stopTask = async () => {
    try {
      await cancelTask({ task_id: taskId });
    } catch (e) {
      //
    }
  };

  const saveWellModel = async () => {
    dispatch(setShowDialog({ isConfirm: true }));
  };

  const discardWellModel = async () => {
    dispatch(setShowDialog({ isDiscard: true }));
  };

  const deleteObject = async () => {
    switch (showDialog?.objectType) {
      case 'JUNCTION':
        dispatch(setShowDialog({ isConfirm: true }));
        break;
      case 'PIPELINE':
        dispatch(setShowDialog({ isConfirm: true }));
        break;
      case 'SOURCE':
        dispatch(setShowDialog({ isConfirm: true }));
        break;
      case 'SINK':
        dispatch(setShowDialog({ isConfirm: true }));
        break;
      case 'PROJECT': {
        const response = await deleteProject(showDialog?.objectId as string);
        if (response.data) dispatch(setProjects(response.data));
        closeDialog();
        history.push('/');
        break;
      }
      default:
        break;
    }
  };

  if (showDialog?.dialogType === 'DELETE_CONFIRM')
    return (
      <DeleteConfirmationWindow
        closeWindow={closeDialog}
        deleteObject={deleteObject}
      />
    );
  if (showDialog?.dialogType === 'CANCEL_TASK_CONFIRM')
    return (
      <CancelTaskConfirmationWindow
        closeWindow={closeDialog}
        cancelTask={stopTask}
      />
    );
  if (showDialog?.dialogType === 'WELL_MODEL_CONFIRM')
    return (
      <WellModelConfirmationWindow
        closeWindow={closeDialog}
        saveChanges={saveWellModel}
        discardChanges={discardWellModel}
      />
    );
  return <></>;
};

const mapStateToProps = (state: RootState) => ({
  socketState: state.socket.socketState,
  nodes: state.project.nodes,
});

export default withRouter(connect(mapStateToProps)(DialogWindow));
