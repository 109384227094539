import * as React from 'react';
import { useSelector } from 'react-redux';
import { NamedProps } from 'react-select';

import Dropdown from 'Components/Dropdown';
import GantChart from 'Components/GantChart';
import { ArrayElement } from 'Pages/Project/types';
import { selectSolution } from 'store/taskSlice';

import { components } from '../../../../../../generated/apiTypes';
import useChartData from './hooks/use-gant-data';
import { dropdownOptions } from './utils';

import '../../../chartStyle.scss';

type OptionType = ArrayElement<typeof dropdownOptions> | null;

const Gant: React.FC = () => {
  const [option, setOption] = React.useState<OptionType>(dropdownOptions[0]);

  const projectSolution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'];
  const onChangeHandler: NamedProps['onChange'] = value =>
    setOption(value as OptionType);

  const chartData: any = useChartData(projectSolution, option);

  return (
    <div className="results__single-chart">
      <div className="results__single-chart-dropdown">
        <Dropdown
          options={dropdownOptions}
          onChange={onChangeHandler}
          value={option}
          menuWidth="100%"
        />
      </div>
      <GantChart chartData={chartData} />
    </div>
  );
};

export default Gant;
