import * as React from 'react';

export const templateRowsNumber = 5;

export const segmentsAvgTableHead = {
  start_node_name: {
    title: 'V0',
    component: (
      <>
        V<span className="subscript">0</span>
      </>
    ),
  },
  end_node_name: {
    title: 'V1',
    component: (
      <>
        V<span className="subscript">1</span>
      </>
    ),
  },
  length: 'L, м',
  diameter: 'D, м',
  p_in: {
    title: 'avg P0, атм',
    component: (
      <>
        avg Р<span className="subscript">0</span>,
        <br /> атм
      </>
    ),
  },
  oil_flow_rate: {
    title: 'avg Qн, т/сут',
    component: (
      <>
        avg Qн,
        <br />
        т/cут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'avg Qж, м3/сут',
    component: (
      <>
        avg Qж,
        <br />
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'avg Qг, тыс. м3/сут',
    component: (
      <>
        avg Qг, <br />
        <span className="relative">
          тыс. м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  dp_dl: {
    title: 'avg ΔP/ΔL, атм/км',
    component: (
      <>
        avg ΔP/ΔL,
        <br />
        атм/км
      </>
    ),
  },
  dt_dl: {
    title: 'avg Δt/ΔL, °С/км',
    component: (
      <>
        avg Δt/ΔL,
        <br />
        °С/км
      </>
    ),
  },
  fluid_velocity: {
    title: 'avg υж-max, м/c',
    component: (
      <>
        avg
        <br />υ<span className="subscript">ж-max</span>, м/c
      </>
    ),
  },
  gas_velocity: {
    title: 'avg υг-max, м/c',
    component: (
      <>
        avg
        <br />υ<span className="subscript">г-max</span>, м/c
      </>
    ),
  },
};

export const segmentsByStepTableHead = {
  start_node_name: {
    title: 'V0',
    component: (
      <>
        V<span className="subscript">0</span>
      </>
    ),
  },
  end_node_name: {
    title: 'V1',
    component: (
      <>
        V<span className="subscript">1</span>
      </>
    ),
  },
  length: 'L, м',
  diameter: 'D, м',
  p_in: {
    title: 'P0, атм',
    component: (
      <>
        Р<span className="subscript">0</span>,
        <br /> атм
      </>
    ),
  },
  oil_flow_rate: {
    title: 'Qн, т/сут',
    component: (
      <>
        Qн,
        <br />
        т/cут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Qж, м3/сут',
    component: (
      <>
        Qж,
        <br />
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Qг, тыс. м3/сут',
    component: (
      <>
        Qг, <br />
        <span className="relative">
          тыс. м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  dp_dl: {
    title: 'ΔP/ΔL, атм/км',
    component: (
      <>
        ΔP/ΔL,
        <br />
        атм/км
      </>
    ),
  },
  dt_dl: {
    title: 'Δt/ΔL, °С/км',
    component: (
      <>
        Δt/ΔL,
        <br />
        °С/км
      </>
    ),
  },
  fluid_velocity: {
    title: 'υж-max, м/c',
    component: (
      <>
        υ<span className="subscript">ж-max</span>,
        <br /> м/c
      </>
    ),
  },
  gas_velocity: {
    title: 'υг-max, м/c',
    component: (
      <>
        υ<span className="subscript">г-max</span>,
        <br /> м/c
      </>
    ),
  },
};
