import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';

import { selectPipesCatalog } from '../../../../store/projectSlice';
import TableHead from '../../ResultsPanel/components/TableHead';
import TableWrapper from '../../ResultsPanel/components/TableWrapper';
import TableRow from './TableRow';
import { pipesCatalogTableHead as tableHead } from './utils';

const Table: React.FC = () => {
  const catalog = useSelector(selectPipesCatalog);
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    catalog?.forEach(segment => {
      newData.push({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        external_diameter: segment?.external_diameter.mm,
        specific_mass: segment?.specific_mass.kg_per_m,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        wall_thickness: segment?.wall_thickness?.mm,
        roughness: segment?.roughness?.m,
        heat_transfer_coef: segment?.heat_transfer_coef,
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [catalog]);

  return (
    <TableWrapper vertical smoothScrolling>
      <table className={classnames('table', 'pipes-catalog__table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.map((value: any, index: number) => {
            return <TableRow key={index} headKeys={headKeys} el={value} />;
          })}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default Table;
