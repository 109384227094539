import * as React from 'react';

export const templateRowsNumber = 5;

export const wellfieldsAvgTableHead = {
  name: 'Название',
  operation_type: {
    title: 'Способ эксплуатации',
    component: (
      <>
        Способ <br /> эксплуатации
      </>
    ),
  },
  control_type: {
    title: 'Режим работы',
    component: (
      <>
        Режим <br /> работы
      </>
    ),
  },
  oil_flow_rate: {
    title: 'avg Qн, т/сут',
    component: (
      <>
        avg Qн, <br /> т/сут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'avg Qж, т/сут',
    component: (
      <>
        avg Qж, <br /> т/сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'avg Qг, тыс. м3/сут',
    component: (
      <>
        avg Qг, <br />
        <span className="relative">
          тыс. м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  frequency: {
    title: 'avg F, Гц',
    component: <>avg F, Гц</>,
  },
  consumption: {
    title: 'avg Qг/г, м3/сут',
    component: (
      <>
        avg Qг/г, <br />
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  pressure: {
    title: 'avg P, атм',
    component: (
      <>
        avg Р, <br />
        атм
      </>
    ),
  },
  temperature: {
    title: 't, °С',
    component: <>t,°С</>,
  },
};

export const wellfieldsByStepTableHead = {
  name: 'Название',
  operation_type: {
    title: 'Способ эксплуатации',
    component: (
      <>
        Способ <br /> эксплуатации
      </>
    ),
  },
  control_type: {
    title: 'Режим работы',
    component: (
      <>
        Режим <br /> работы
      </>
    ),
  },
  oil_flow_rate: {
    title: 'Qн, т/сут',
    component: <>Qн, т/сут</>,
  },
  fluid_flow_rate: {
    title: 'Qж, т/сут',
    component: <>Qж, т/сут</>,
  },
  gas_flow_rate: {
    title: 'Qг, тыс. м3/сут',
    component: (
      <>
        Qг, <br />
        <span className="relative">
          тыс. м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  frequency: {
    title: 'F, Гц',
    component: <>F, Гц</>,
  },
  consumption: {
    title: 'Qг/г, м3/сут',
    component: (
      <>
        Qг/г, <br />
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  pressure: {
    title: 'P, атм',
    component: <>Р, атм</>,
  },
  temperature: {
    title: 't, °С',
    component: <>t,°С</>,
  },
};
