import React from 'react';

import classnames from 'classnames';

import { twoDigitsFormatter } from 'utils';
import { ITableRowProps } from '../../ResultsPanel/types';
import { isNumber } from '../../ResultsPanel/utils';

const TableRow: React.FC<ITableRowProps> = ({ el, headKeys }) => {
  const formatValue = (key: string, value: string) => {
    return twoDigitsFormatter.format(parseFloat(parseFloat(value).toFixed(2)));
  };
  return (
    <tr className={classnames('table-row')}>
      {headKeys.map((key: string) => (
        <td className={classnames('table-data')} key={key}>
          {isNumber(el[key]) ? formatValue(key, el[key]) : el[key]}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
