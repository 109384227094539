import React from 'react';
import { useDispatch } from 'react-redux';

import { validateProject } from 'services/apiRequests';
import { setIssues } from '../../../store/projectSlice';

const useValidation = (projectUid: string) => {
  const dispatch = useDispatch();

  const fetchValidation = async () => {
    if (projectUid) {
      try {
        const response = await validateProject(projectUid);
        dispatch(setIssues(response.data));
      } catch (e) {
        //
      }
    }
  };

  React.useEffect(() => {
    fetchValidation();
  }, [projectUid]);

  return { fetchValidation };
};

export default useValidation;
