import * as React from 'react';
import { useSelector } from 'react-redux';
import { ValueType } from 'react-select';

import { components } from '../../../../generated/apiTypes';
import { ReactComponent as LinkedIcon } from '../../../../images/Project/linked-icon.svg';
import { selectPhysChemPackages } from '../../../../store/projectSlice';
import ObjectInput from '../components/ObjectInput';
import ObjectSelect, { OptionType } from '../components/ObjectSelect';
import {
  getGasFractionOptions,
  getWaterFractionOptions,
} from '../components/options';

interface ISourceViewAF {
  well: components['schemas']['PNOWell'] | undefined;
  wellFactMeasurement:
    | components['schemas']['PNOWellFactMeasurement']
    | undefined;
  saveNewWellValue(value: number | string, name: string): void;
  saveNewWellFactMeasurementValue(value: number | string, name: string): void;
  waterFractionType: ValueType<OptionType<string>, false>;
  gasFractionType: ValueType<OptionType<string>, false>;
  saveNewWaterFractionType(value: ValueType<OptionType<string>, false>): void;
  saveNewGasFractionType(value: ValueType<OptionType<string>, false>): void;
}

const SourceViewAF: React.FC<ISourceViewAF> = ({
  well,
  wellFactMeasurement,
  saveNewWellValue,
  saveNewWellFactMeasurementValue,
  waterFractionType,
  gasFractionType,
  saveNewWaterFractionType,
  saveNewGasFractionType,
}) => {
  const physChemPackages = useSelector(selectPhysChemPackages);
  const PCPackages: OptionType<string>[] = physChemPackages.map(value => {
    return {
      value: value?.uid as string,
      label: value?.name as string,
    };
  });
  return (
    <>
      <div className="active-object__row">
        <label className="input-label">Дебит жидкости</label>
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper short">
            <div className="active-object__row-input-prefix">факт</div>
            <ObjectInput
              className="input active-object__row-input with-unit with-prefix input-with-prefix"
              label=""
              name="fluid_flow"
              unit="m3_per_day"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              initValue={wellFactMeasurement?.fluid_flow.m3_per_day}
              saveNewValue={saveNewWellFactMeasurementValue}
            />
            <p className="active-object__row-input-unit">
              <span className="relative">
                м<span className="index small">3</span>
              </span>
              &nbsp; /сут
            </p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <label className="input-label">Обводненность</label>
        <div className="d-flex">
          <ObjectSelect
            label=""
            classNamesWrapper=""
            classNames="active-object__row-input short"
            name=""
            options={getWaterFractionOptions()}
            value={waterFractionType}
            saveNewOption={saveNewWaterFractionType}
          />
          <LinkedIcon className="active-object__linked-icon" />
          <div className="d-flex">
            <div className="active-object__row-input-prefix">факт</div>
            <div className="active-object__input-with-unit-wrapper">
              {waterFractionType?.value === 'wor' ? (
                <>
                  <ObjectInput
                    className="input active-object__row-input short with-unit with-prefix input-with-prefix"
                    label=""
                    name="water_fraction"
                    unit="m3_per_m3"
                    subName="wor"
                    initValue={well?.water_fraction?.wor?.m3_per_m3}
                    saveNewValue={saveNewWellValue}
                  />
                  <p className="active-object__row-input-unit">
                    <span className="relative">
                      м<span className="index small">3</span>
                    </span>
                    &nbsp; /
                    <span className="relative">
                      м<span className="index small">3</span>
                    </span>
                    &ensp;
                  </p>{' '}
                </>
              ) : (
                <>
                  <ObjectInput
                    className="input active-object__row-input short with-unit with-prefix input-with-prefix"
                    label=""
                    name="water_fraction"
                    subName="wct"
                    unit="percents"
                    maxValue={99.999}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    initValue={well?.water_fraction?.wct?.percents}
                    saveNewValue={saveNewWellValue}
                  />
                  <p className="active-object__row-input-unit">%</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <label className="input-label">Газовый фактор</label>
        <div className="d-flex">
          <ObjectSelect
            label=""
            classNamesWrapper=""
            classNames="active-object__row-input short"
            options={getGasFractionOptions()}
            name=""
            value={gasFractionType}
            saveNewOption={saveNewGasFractionType}
          />
          <LinkedIcon className="active-object__linked-icon" />
          <div className="d-flex">
            <div className="active-object__row-input-prefix">факт</div>
            <div className="active-object__input-with-unit-wrapper">
              {gasFractionType?.value === 'gor' ? (
                <>
                  <ObjectInput
                    className="input active-object__row-input short with-unit with-prefix input-with-prefix"
                    label=""
                    name="gas_fraction"
                    subName="gor"
                    unit="m3_per_m3"
                    extraSubName="oil_density"
                    extraObject={well?.gas_fraction.oil_density}
                    initValue={well?.gas_fraction?.gor?.m3_per_m3}
                    saveNewValue={saveNewWellValue}
                  />
                  <p className="active-object__row-input-unit">
                    <span className="relative">
                      м<span className="index small">3</span>
                    </span>
                    &nbsp; /
                    <span className="relative">
                      м<span className="index small">3</span>
                    </span>
                    &ensp;
                  </p>
                </>
              ) : (
                <>
                  <ObjectInput
                    className="input active-object__row-input short with-unit with-prefix input-with-prefix"
                    label=""
                    name="gas_fraction"
                    subName="gas_factor"
                    unit="m3_per_t"
                    extraSubName="oil_density"
                    extraObject={well?.gas_fraction.oil_density}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    initValue={well?.gas_fraction?.gas_factor?.m3_per_t}
                    saveNewValue={saveNewWellValue}
                  />
                  <p className="active-object__row-input-unit">
                    <span className="relative">
                      м<span className="index small">3</span>
                    </span>
                    &nbsp; /т
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <label className="input-label">Давление</label>
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper short">
            <div className="active-object__row-input-prefix">факт</div>
            <ObjectInput
              className="input active-object__row-input with-unit with-prefix input-with-prefix"
              label=""
              name="pressure"
              unit="atm"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              initValue={wellFactMeasurement?.pressure.atm}
              saveNewValue={saveNewWellFactMeasurementValue}
            />
            <p className="active-object__row-input-unit">атм</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <label className="input-label">Температура смеси</label>
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper short">
            <div className="active-object__row-input-prefix">факт</div>
            <ObjectInput
              className="input active-object__row-input with-unit with-prefix input-with-prefix"
              label=""
              name="temperature"
              unit="celsius"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              initValue={well?.temperature.celsius}
              saveNewValue={saveNewWellValue}
            />
            <p className="active-object__row-input-unit">˚C</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="active-object__input-with-unit-wrapper">
          <ObjectSelect
            label="Пакет ФХС"
            classNames="active-object__row-input"
            classNamesWrapper="active-object__row-input-wrapper wide"
            name="phys_chem_package_id"
            options={PCPackages}
            value={PCPackages?.find(
              el => el.value === well?.phys_chem_package_id,
            )}
            saveNewValue={saveNewWellValue}
          />
        </div>
      </div>
    </>
  );
};

export default SourceViewAF;
