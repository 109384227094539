import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import classNames from 'classnames';
import { selectExperimentStatus } from 'store/taskSlice';

import { components } from '../../../generated/apiTypes';
import { ReactComponent as CatalogIcon } from '../../../images/Project/icn-details.svg';
import {
  getSegmentsCatalog,
  setExperimentSettings,
} from '../../../services/apiRequests';
import {
  selectShowDetailsPanel,
  setPipesCatalog,
  setShowDetailsPanel,
} from '../../../store/projectSlice';
import useGetExperimentSettings from './hooks/use-get-experiment-settings';
import TaskSettingsInputRow from './TaskSettingInputRow';

const NOSettings: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const settings = useGetExperimentSettings(params.id);
  const status = useSelector(selectExperimentStatus);
  const showCatalog = useSelector(selectShowDetailsPanel);
  const handleToggleCatalog = () => {
    if (showCatalog === 'pipesCatalog') dispatch(setShowDetailsPanel(null));
    else dispatch(setShowDetailsPanel('pipesCatalog'));
  };

  const [settingsState, setSettingsState] = React.useState<
    components['schemas']['ExperimentSettings'] | undefined
  >(undefined);

  const fetchCatalog = async () => {
    try {
      const response = await getSegmentsCatalog(params.id);
      if (response?.data) dispatch(setPipesCatalog(response.data));
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    fetchCatalog().then();
    return () => {
      dispatch(setShowDetailsPanel(null));
    };
  }, []);

  React.useEffect(() => {
    setSettingsState(settings as components['schemas']['ExperimentSettings']);
  }, [settings]);

  const saveNewValue = (value: number, name: string) => {
    setExperimentSettings(params.id, {
      ...(settingsState as components['schemas']['ExperimentSettings']),
      [name]: value,
    });
    setSettingsState({
      ...(settingsState as components['schemas']['ExperimentSettings']),
      [name]: value,
    });
  };

  const saveNewOptimizationValue = (value: number, name: string) => {
    if (settingsState) {
      setExperimentSettings(params.id, {
        ...settingsState,
        optimization_tech_network_settings: {
          ...settingsState.optimization_tech_network_settings,
          [name]: value,
        },
      } as any);
      setSettingsState({
        ...settingsState,
        optimization_tech_network_settings: {
          ...settingsState.optimization_tech_network_settings,
          [name]: value,
        },
      } as any);
    }
  };

  const settingsMO = settingsState?.optimization_tech_network_settings;

  return (
    <>
      <div>
        <div className="project-task-settings__row">
          <TaskSettingsInputRow
            label="Длительность"
            value={settingsState?.period}
            unit="мин"
            saveNewValue={saveNewValue}
            name="period"
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
          <TaskSettingsInputRow
            label="Шаг"
            value={settingsState?.time_step}
            unit="мин"
            saveNewValue={saveNewValue}
            name="time_step"
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
        </div>
        <div className="project-task-settings__row">
          <TaskSettingsInputRow
            label="Точность расчетов"
            value={settingsState?.periodic_nodal_analysis_settings?.accuracy}
            unit="aтм"
            saveNewValue={saveNewValue}
            name="periodic_nodal_analysis_settings"
            subName="accuracy"
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
        </div>
        <div className="project-task-settings__row-wrapper">
          <label className="input-label">Металлоемкость лупингов</label>
          <div className="project-task-settings__row">
            <div className="active-object__row-input-prefix">max</div>
            <div className="project-task-settings__row metal-consumption-row with-prefix">
              <TaskSettingsInputRow
                label=""
                value={settingsMO?.max_metal_consumption?.kg}
                unit="kg"
                saveNewValue={saveNewOptimizationValue}
                name="max_metal_consumption"
                subName="kg"
                className="input-with-prefix"
                disabled={[
                  'DRAFT',
                  'SENT',
                  'PREPARING',
                  'PREPARED',
                  'SOLVING',
                ].includes(status)}
              />
            </div>
            <button
              className={classNames(
                'metal-consumption-row__button',
                showCatalog === 'pipesCatalog' && 'active',
              )}
              type="button"
              onClick={handleToggleCatalog}
            >
              <CatalogIcon className="metal-consumption-row__icon" />
            </button>
          </div>
        </div>
        <div className="project-task-settings__row">
          <TaskSettingsInputRow
            label="t окр. среды"
            value={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              settingsMO?.ambient_temperature.celsius
            }
            unit="˚C"
            saveNewValue={saveNewOptimizationValue}
            name="ambient_temperature"
            subName="celsius"
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
        </div>
      </div>
    </>
  );
};

export default NOSettings;
