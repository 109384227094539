import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import Project from 'Pages/Project';

import Login from './Pages/Login';
import NewProject from './Pages/NewProject';
import Projects from './Pages/Projects';

function App() {
  return (
    <HashRouter>
      <Switch>
        <Switch>
          <Route path="/" component={Projects} exact />
          <Route path="/new-project" component={NewProject} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/project/:id" component={Project} exact />
        </Switch>
      </Switch>
    </HashRouter>
  );
}

export default App;
