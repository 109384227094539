import React from 'react';

import { ReactComponent as CloseIcon } from 'images/Project/close-icon.svg';

import Button from '../Button';

import './style.scss';

interface IWellModelConfirmationWindowProps {
  closeWindow(): void;
  saveChanges(): void;
  discardChanges(): void;
}

const WellModelConfirmationWindow: React.FC<IWellModelConfirmationWindowProps> = ({
  saveChanges,
  discardChanges,
  closeWindow,
}) => {
  const onSaveClick = () => {
    saveChanges();
  };
  const onDiscardClick = () => {
    discardChanges();
  };
  React.useEffect(() => {
    const onKeyDownHandler = (e: React.KeyboardEvent) => {
      const { key } = e;
      if (key === 'Enter') {
        onSaveClick();
      }
    };
    document.addEventListener('keydown', onKeyDownHandler as any);
    return () => {
      document.removeEventListener('keydown', onKeyDownHandler as any);
    };
  }, []);
  return (
    <div className="dialog-window-background">
      <div className="dialog-window-container">
        <div className="dialog-window-wrapper">
          <button
            type="button"
            className="dialog-window-close-button"
            onClick={closeWindow}
          >
            <CloseIcon />
          </button>
          <div className="dialog-window-title">
            Выход из режима редактирования
          </div>
          <div className="dialog-window-message cancel-task-message">
            Вы хотите сохранить изменения, внесенные в модель скважины?
          </div>
          <div className="dialog-window-buttons-wrapper">
            <Button
              type="button"
              className="dialog-window-delete-button"
              onClick={onSaveClick}
            >
              Сохранить
            </Button>
            <Button type="button" mode="secondary" onClick={onDiscardClick}>
              Не сохранять
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WellModelConfirmationWindow;
