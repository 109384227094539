import {
  setGatheringCentersFactPressures,
  setGatheringCentersModels,
  setNodes,
  setPhysChemPackages,
  setProjectId,
  setProjectName,
  setSegments,
  setWells,
  setWellsControlModels,
  setWellsFactMeasurements,
  setWellsHydroModels,
  setWellsModels,
} from 'store/projectSlice';

import { getProject } from 'services/apiRequests';

const fetchProject = async (id: any, dispatch: any, callback?: any) => {
  // let response: any;
  try {
    const response = await getProject(id);
    dispatch(setNodes(response?.data?.pipeline_network?.nodes));
    dispatch(setWells(response?.data?.wells));
    dispatch(setWellsModels(response?.data?.wells_models));
    dispatch(setWellsHydroModels(response?.data?.wells_hydro_models));
    dispatch(setWellsFactMeasurements(response?.data?.well_fact_measurement));
    dispatch(
      setGatheringCentersModels(response?.data?.gathering_centers_models),
    );
    dispatch(
      setGatheringCentersFactPressures(
        response?.data?.gathering_centers_fact_pressures,
      ),
    );
    dispatch(setSegments(response?.data?.pipeline_network?.segments));
    if (response?.data?.wells_control_models)
      dispatch(setWellsControlModels(response?.data?.wells_control_models));
    dispatch(setPhysChemPackages(response?.data?.phys_chem_packages));
    dispatch(setProjectName(response?.data?.name));
    dispatch(setProjectId(id));
    if (callback) callback(response.data);
  } catch (e) {
    //
  }

  // return response.data;
};

export default fetchProject;
