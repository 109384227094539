import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NamedProps } from 'react-select';

import Dropdown from 'Components/Dropdown';
import { setLinesColor } from 'store/mapSlice';

import { components } from '../../../../generated/apiTypes';
import { selectSolution } from '../../../../store/taskSlice';
import { ArrayElement } from '../../types';
import useChartData from '../Segments/UA/hooks/use-segments-data';
import { dropdownOptions } from '../Segments/UA/utils';

import '../style.scss';

type OptionType = ArrayElement<typeof dropdownOptions> | null;

function SegmentsScale(props: any) {
  const dispatch = useDispatch();
  const [option, setOption] = React.useState<OptionType>(dropdownOptions[0]);
  const projectSolution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'];

  const chartData: any = useChartData(projectSolution, option);

  const getMaxOfArray = () => {
    const newMaxarr: any = [];
    for (let i = 0; i < chartData.length; i += 1) {
      newMaxarr.push(chartData[i].data);
    }
    return Math.max.apply(null, newMaxarr);
  };

  const getMinOfArray = () => {
    const newMinarr: any = [];
    for (let i = 0; i < chartData.length; i += 1) {
      newMinarr.push(chartData[i].data);
    }
    return Math.min.apply(null, newMinarr);
  };

  const getBackgroundColor = (arr: any) => {
    const color1 = arr[0];
    const color2 = arr[1];
    const ratio = arr[2];
    const hex = function (x: any) {
      x = x.toString(16);
      return x.length === 1 ? `0${x}` : x;
    };

    const r = Math.ceil(
      parseInt(color1.substring(0, 2), 16) * ratio +
        parseInt(color2.substring(0, 2), 16) * (1 - ratio),
    );
    const g = Math.ceil(
      parseInt(color1.substring(2, 4), 16) * ratio +
        parseInt(color2.substring(2, 4), 16) * (1 - ratio),
    );
    const b = Math.ceil(
      parseInt(color1.substring(4, 6), 16) * ratio +
        parseInt(color2.substring(4, 6), 16) * (1 - ratio),
    );
    return hex(r) + hex(g) + hex(b);
  };

  const prepareColor = (data: number) => {
    const colors = ['#7198FF', '#8AB8FE', '#B477FC', '#FC72DD', '#FF4A4A'];
    const newArr: any = [];
    const isNegativeData = chartData.some((item: any) => item.data < 0);
    const maxDataValue = isNegativeData
      ? Math.abs(getMinOfArray()) + getMaxOfArray()
      : getMaxOfArray();
    const minDataValue = isNegativeData
      ? maxDataValue - Math.abs(data) - getMaxOfArray()
      : data;
    const roundData: any = minDataValue;

    for (let i = 0; i < colors.length; i += 1) {
      if (
        i === 0 &&
        (roundData / maxDataValue) * 100 <= (100 / colors.length) * (i + 1)
      ) {
        newArr.push(colors[i].substring(1));
        newArr.push(colors[i + 1].substring(1));
        const ratio = roundData / maxDataValue;
        newArr.push(ratio);
      } else if (
        i !== 0 &&
        i !== 4 &&
        (roundData / maxDataValue) * 100 >= (100 / colors.length) * i &&
        (roundData / maxDataValue) * 100 <= (100 / colors.length) * (i + 1)
      ) {
        newArr.push(colors[i].substring(1));
        newArr.push(colors[i + 1].substring(1));
        const ratio = roundData / maxDataValue;
        newArr.push(ratio);
      } else if (
        i === 4 &&
        (roundData / maxDataValue) * 100 >= (100 / colors.length) * (i - 1) &&
        (roundData / maxDataValue) * 100 <= (100 / colors.length) * (i + 1)
      ) {
        newArr.push(colors[i].substring(1));
        newArr.push(colors[i - 1].substring(1));
        const ratio = roundData / maxDataValue;
        newArr.push(ratio);
      }
    }

    return getBackgroundColor(newArr);
  };

  const gradientNumberValues = () => {
    const maxOfArray = getMaxOfArray();
    const minOfArray = getMinOfArray();
    const arr: any = [];
    arr.push(maxOfArray < 1 ? minOfArray.toPrecision(2) : minOfArray.toFixed());
    for (
      let i = minOfArray;
      i < maxOfArray;
      i += (maxOfArray - minOfArray) / 9
    ) {
      if (maxOfArray < 1) {
        if (
          i.toPrecision(3) !== minOfArray.toPrecision(3) &&
          i.toPrecision(3) !== maxOfArray.toPrecision(3)
        ) {
          arr.push(i.toPrecision(1));
        }
      } else if (
        i.toFixed(2) !== minOfArray.toFixed(2) &&
        i.toFixed(2) !== maxOfArray.toFixed(2)
      ) {
        arr.push(i.toFixed());
      }
    }
    arr.push(maxOfArray < 1 ? maxOfArray.toPrecision(2) : maxOfArray.toFixed());
    return arr.sort((a: any, b: any) => b - a);
  };

  const renderSegmentsChart = () => {
    return (
      <div className="project-results-panel__pipelines-gradient-values">
        <div
          className="charts__segments-scale_values"
          style={{
            flexDirection: gradientNumberValues().length
              ? 'row-reverse'
              : 'row',
          }}
        >
          {gradientNumberValues().length ? (
            gradientNumberValues().map((item: any, i: any) => (
              <p key={i}>{item}</p>
            ))
          ) : (
            <p>0</p>
          )}
        </div>
      </div>
    );
  };

  const onChangeHandler: NamedProps['onChange'] = value => {
    setOption(value as OptionType);
    dispatch(setLinesColor(null));
  };

  React.useEffect(() => {
    if (chartData && chartData.length) {
      const newLinesColors = chartData.map((item: any) => {
        return {
          uid: item.uid,
          color: prepareColor(item.data),
        };
      });
      dispatch(setLinesColor(newLinesColors));
    }

    return function () {
      dispatch(setLinesColor(null));
    };
  }, [chartData]);

  return (
    <div className="project-results-panel__pipelines-gradient">
      <div
        className="results__single-chart-dropdown"
        style={{ alignSelf: 'flex-start', margin: 0 }}
      >
        <Dropdown
          options={dropdownOptions}
          onChange={onChangeHandler}
          value={option}
          menuWidth="100%"
          menuPlacement="top"
        />
      </div>
      <div className="project-results-panel__pipelines-gradient-scale-values">
        <div
          className="project-results-panel__pipelines-gradient-scale"
          style={{
            background:
              'linear-gradient(90deg, #7198FF 0%, #8AB8FE 22.92%, #B477FC 46.35%, #FC72DD 74.48%, #FF4A4A 100%)',
          }}
        />
        {chartData.length ? renderSegmentsChart() : null}
      </div>
    </div>
  );
}

export default SegmentsScale;
