import React from 'react';

import { components } from '../../../../../../generated/apiTypes';

type PNOPressureDrop = {
  pa_per_m: number;
  atm_per_km: number;
};

type PNOMassFlow = {
  kg_per_second: number;
  t_per_day: number;
};

type PNOVolumeFlow = {
  m3_per_second: number;
  m3_per_day: number;
  thousand_m3_per_day: number;
};

const useChartData: any = (
  projectSolution: components['schemas']['PNOPeriodicNodalAnalysisProblem'],
  option: any,
) => {
  const chartData: { uid: string; data: any }[] = [];
  return React.useMemo(() => {
    if (option) {
      projectSolution?.solution?.segment_avg_table?.segment_states.forEach(
        segment => {
          if (option.value === 'dp_dl') {
            const data = segment.dp_dl as PNOPressureDrop;
            chartData.push({ uid: segment.segment_uid, data: data.atm_per_km });
          }
          if (option.value === 'oil_flow_rate_mass') {
            const data = segment.oil_flow_rate_mass as PNOMassFlow;
            chartData.push({
              uid: segment.segment_uid,
              data: data.t_per_day,
            });
          }
          if (option.value === 'fluid_flow_rate_mass') {
            const data = segment.fluid_flow_rate_mass as PNOMassFlow;
            chartData.push({
              uid: segment.segment_uid,
              data: data.t_per_day,
            });
          }
          if (option.value === 'gas_flow_rate_volume') {
            const data = segment.gas_flow_rate_volume as PNOVolumeFlow;
            chartData.push({
              uid: segment.segment_uid,
              data: data.thousand_m3_per_day,
            });
          }
        },
      );
    }
    return chartData;
  }, [option?.value, projectSolution?.solution?.well_avg_table]);
};

export default useChartData;
