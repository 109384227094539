import { StylesConfig } from 'react-select';

const handleStyles: StylesConfig<any, any> = {
  control: (provided, state) => {
    const border = state.menuIsOpen ? '1px solid #dddddd' : '1px solid #f5f5f5';
    return {
      ...provided,
      border,
      fontWeight: 'normal',
      borderWidth: 0,
      padding: '5px 8px',
      transition: 'all 0.25s ease',
      boxShadow: 'none',
      minHeight: '30px',
      backgroundColor: '#f5f5f5',
      '&:hover': {
        border: state.menuIsOpen ? '1px solid #dddddd' : '1px solid #ededed',
      },
    };
  },
  menuList: provided => {
    return {
      ...provided,
      marginTop: 8,
      marginBottom: 8,
      paddingTop: 0,
      paddingBottom: 0,
    };
  },
  option: (provided, state: { isSelected: boolean }) => {
    const color = state.isSelected ? '#046AD2' : '#0F2E4A';
    return {
      ...provided,
      color,
      height: 36,
      verticalAlign: 'middle',
      backgroundColor: '#fff',
      transition: 'all 0.25s ease',
      fontSize: 11,
      fontWeight: 500,
      lineHeight: '20px',
      cursor: 'pointer',
      padding: '8px 12px',
      '&:hover': {
        backgroundColor: 'rgba(157, 160, 178, 0.08)',
      },
    };
  },
  menu: (provided, state) => {
    const menuWidth = state.selectProps.menuWidth;
    return {
      ...provided,
      width: menuWidth,
      marginTop: 4,
      marginBottom: 0,
      borderRadius: 4,
      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.08)',
      border: '1px solid rgba(157, 160, 178, 0.16)',
      backgroundColor: '#fff',
    };
  },
  indicatorsContainer: provided => {
    return {
      ...provided,
      fontSize: 0,
      height: '20px',
    };
  },
  clearIndicator: provided => {
    return {
      ...provided,
      padding: '0 8px',
    };
  },
  valueContainer: provided => {
    return {
      ...provided,
      padding: 0,
      lineHeight: '20px',
    };
  },
  input: provided => {
    return {
      ...provided,
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
      fontSize: 11,
      fontWeight: 500,
      lineHeight: '20px',
      color: '#333333',
    };
  },
  placeholder: provided => {
    return {
      ...provided,
      fontSize: 11,
      fontWeight: 500,
      lineHeight: '20px',
      marginLeft: 0,
      color: '#9DA0B2',
    };
  },
  singleValue: (provided, state) => {
    const color = state.selectProps.error ? '#f0115e' : '#0F2E4A';
    return {
      ...provided,
      color,
      marginLeft: 0,
      fontSize: 11,
      lineHeight: '20px',
    };
  },
};

export default handleStyles;
