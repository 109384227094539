import React, { useState } from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import { Props as MapProps } from 'react-mapbox-gl/lib/map';

import classNames from 'classnames';

import { IMapContainerProps } from './types';

const MapboxMap = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiaXZhbmxvIiwiYSI6ImNqeTY1cW9qdTBkbmIzY3IyamcxYjkyenUifQ.dltoRzI_jf_sH2IDWZd0jQ',
});

const containerStyle: (height?: string) => React.CSSProperties = height => ({
  height: height ?? '100vh',
  width: '100%',
});

const defaultCenter: MapProps['center'] = [
  55.24747026552241,
  51.59632314294971,
];

function Map(props: IMapContainerProps) {
  const [mapCenter] = useState<MapProps['center']>(defaultCenter);

  return (
    <div className={classNames('map-container', props.containerClassName)}>
      <MapboxMap
        center={mapCenter}
        {...props.mapProps}
        zoom={[14]}
        containerStyle={containerStyle(props.height)}
        // eslint-disable-next-line react/style-prop-object
        style="mapbox://styles/ivanlo/ckrdjx43i2jr417nrfjcmd714"
      >
        <>{props.children}</>
      </MapboxMap>
    </div>
  );
}

export default Map;
