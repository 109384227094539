import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import classNames from 'classnames';
import ToggleList from 'Components/ToggleList';
import { ReactComponent as DropIcon } from 'images/Project/drop.svg';
import { ReactComponent as FolderIcon } from 'images/Project/folder.svg';
import { ReactComponent as DeleteIcon } from 'images/Project/icn-delete-object.svg';
import { ReactComponent as AddIcon } from 'images/Project/icn-package-add.svg';
import { ReactComponent as AllIcon } from 'images/Project/icn-select-all.svg';

import { addPhysChemPackage } from '../../services/apiRequests';
import { setShowDialog } from '../../store/commonSlice';
import { setActiveObject } from '../../store/mapSlice';
import { setPhysChemPackages } from '../../store/projectSlice';
import { RootState } from '../../store/store';
import { IMapSlice } from '../../store/types';
import { shortenTitle } from '../../utils';
import { ObjectType } from './types';

interface Props {
  activeObject: IMapSlice['activeObject'];
  title: string;
  data: any;
  type: ObjectType;
}
const NetworkObjectsToggle: React.FC<Props> = (props: Props) => {
  const { title, data, type, activeObject } = props;
  const params = useParams() as { id: string };
  const dispatch = useDispatch();
  const [activeList, setActiveList] = React.useState<boolean>(false);
  const [allSources, setAllSources] = React.useState<boolean>(false);
  const [allPipelines, setAllPipelines] = React.useState<boolean>(false);
  const maxObjectNameLength = 18;

  React.useEffect(() => {
    if (activeObject.type !== 'ALLSOURCES') setAllSources(false);
    if (activeObject.type !== 'ALLPIPELINES') setAllPipelines(false);
    if (activeObject.type === type) {
      setActiveList(true);
    }

    if (activeObject.type !== type && activeList) {
      setActiveList(false);
    }
  }, [activeObject.type, activeObject.object_uid]);

  const toggleActive = (val: boolean) => {
    if (!val) {
      if (
        activeObject.type === type ||
        ['ALLSOURCES', 'ALLPIPELINES'].includes(activeObject.type as string)
      ) {
        dispatch(
          setActiveObject({
            node_uid: null,
            type: null,
            object_uid: null,
            object_name: null,
          }),
        );
      }
    }
    setActiveList(val);
  };

  const onObjectClick = (
    uid: string | undefined,
    node_uid: string | undefined,
    node_name: string | undefined,
  ) => {
    dispatch(
      setActiveObject({
        ...(node_uid && { node_uid }),
        type,
        object_name: node_name || null,
        object_uid: uid || null,
      }),
    );
  };
  const onDeleteBtnClick = async (
    e: React.MouseEvent,
    objectType: ObjectType,
    objectName: string,
    objectId: string,
  ) => {
    e.stopPropagation();
    dispatch(
      setShowDialog({
        dialogType: 'DELETE_CONFIRM',
        objectType,
        objectName,
        objectId,
      }),
    );
  };
  const onHoverBtnClick = async (e: React.MouseEvent) => {
    if (activeList) {
      e.stopPropagation();
    }
    if (type === 'SOURCE') {
      dispatch(
        setActiveObject({
          node_uid: null,
          type: !allSources ? 'ALLSOURCES' : null,
          object_uid: null,
          object_name: null,
        }),
      );
      setAllSources(!allSources);
    }
    if (type === 'PIPELINE') {
      dispatch(
        setActiveObject({
          node_uid: null,
          type: !allPipelines ? 'ALLPIPELINES' : null,
          object_uid: null,
          object_name: null,
        }),
      );
      setAllPipelines(!allPipelines);
    }
    if (type === 'PACKAGE') {
      try {
        const response = await addPhysChemPackage(params.id, {});
        if (response?.data) {
          dispatch(setPhysChemPackages(response.data));
          const packageObj = response?.data?.find(
            (val, index, array) => index === array.length - 1,
          );
          dispatch(
            setActiveObject({
              node_uid: null,
              type: 'PACKAGE',
              object_uid: packageObj?.uid || null,
              object_name: packageObj?.name || null,
            }),
          );
        }
      } catch (err) {
        //
      }
    }
  };

  return (
    <div className="network-objects__row">
      <ToggleList
        activeList={activeList}
        setActiveList={toggleActive}
        topRow={
          <p
            className={classNames(
              'network-objects__title-wrapper',
              type?.toLowerCase(),
            )}
          >
            <span className="network-objects__title">
              {type === 'PACKAGE' ? (
                <DropIcon className="network-objects__icon" />
              ) : (
                <FolderIcon className="network-objects__icon" />
              )}
              <span>{title}</span>
            </span>
            <button
              type="button"
              className={classNames(
                'network-objects__hover-btn',
                (allSources || allPipelines) && 'active',
              )}
              onClick={onHoverBtnClick}
            >
              {['SOURCE', 'PIPELINE'].includes(type) && (
                <AllIcon
                  className={classNames(
                    'network-objects__icon button-icon',
                    (allSources || allPipelines) && 'active',
                  )}
                />
              )}
              {type === 'PACKAGE' && (
                <AddIcon
                  className={classNames('network-objects__icon button-icon')}
                />
              )}
            </button>
          </p>
        }
        body={
          <div>
            {data?.map((el: any, key: any) => (
              <button
                className={classNames(
                  'network-objects__sub-title',
                  (el?.uid === activeObject.object_uid ||
                    (activeObject.type === 'ALLSOURCES' && type === 'SOURCE') ||
                    (activeObject.type === 'ALLPIPELINES' &&
                      type === 'PIPELINE')) &&
                    'active',
                )}
                key={el?.uid || key}
                onClick={() => onObjectClick(el?.uid, el?.node_uid, el?.name)}
                type="button"
              >
                {shortenTitle(el?.name, maxObjectNameLength)}
                <button
                  type="button"
                  className={classNames(
                    'network-object__hover-btn',
                    ['ALLSOURCES', 'ALLPIPELINES'].includes(
                      activeObject.type as string,
                    ) && 'all-objects',
                  )}
                  onClick={e =>
                    onDeleteBtnClick(
                      e,
                      type,
                      el.name,
                      (el.node_uid || el.uid) as string,
                    )
                  }
                >
                  <DeleteIcon
                    className={classNames('network-object__icon button-icon')}
                  />
                </button>
              </button>
            ))}
          </div>
        }
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeObject: state.map.activeObject,
});
export default connect(mapStateToProps)(NetworkObjectsToggle);
