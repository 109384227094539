import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { components } from 'generated/apiTypes';
import {
  selectGatheringCentersFactPressuresById,
  selectGatheringCentersModelById,
  selectNodeById,
  updateGatheringCentersFactPressures,
  updateGatheringCentersModel,
  updateNodes,
  updateNodesNetwork,
} from 'store/projectSlice';
import { selectExperimentType } from 'store/taskSlice';

import {
  setGatheringCenterFactPressure as saveGatheringCenterFactPressure,
  setGatheringCentersModel as saveGatheringCentersModel,
  setNode as saveNode,
} from 'services/apiRequests';
import { IObject } from '../../types';
import ObjectInput from '../components/ObjectInput';
import ObjectStringInput from '../components/ObjectStringInput';
import SinkView from './SinkView';
import SinkViewAF from './SinkViewAF';

const Sink: React.FC<IObject> = ({ objectUid, projectUid }) => {
  const gatheringCentersModelById = useSelector(
    selectGatheringCentersModelById,
  );
  const nodeById = useSelector(selectNodeById);
  const gatheringCentersFactPressuresById = useSelector(
    selectGatheringCentersFactPressuresById,
  );
  const experimentType = useSelector(selectExperimentType);
  const dispatch = useDispatch();

  const saveNewGatheringCentersModelValue = async (
    value: number | string,
    name: string,
  ) => {
    const newValue = {
      ...gatheringCentersModelById[objectUid as string],
      [name]: value,
    };
    try {
      const response = await saveGatheringCentersModel(
        projectUid,
        objectUid as string,
        newValue,
      );
      if (response.data) dispatch(updateGatheringCentersModel(response.data));
    } catch (e) {
      //
    }
  };

  const saveNewGatheringCenterFactPressureValue = async (
    value: number | string,
    name: string,
  ) => {
    try {
      const response = await saveGatheringCenterFactPressure(
        projectUid,
        objectUid as string,
        {
          [name]: value as number,
        },
      );
      if (response.data) {
        const sink = response.data.find(item => item.gc_uid === objectUid);
        if (sink) dispatch(updateGatheringCentersFactPressures(sink));
      }
    } catch (e) {
      //
    }
  };

  const saveNewNodeValue = async (value: number | string, name: string) => {
    let newValue: components['schemas']['PNOPipelineNode'];
    if (name === 'lat')
      newValue = {
        ...nodeById[objectUid as string],
        coordinates: [
          value as number,
          nodeById[objectUid as string].coordinates?.[1],
        ],
      };
    else if (name === 'lon')
      newValue = {
        ...nodeById[objectUid as string],
        coordinates: [
          nodeById[objectUid as string].coordinates?.[0],
          value as number,
        ],
      };
    else newValue = { ...nodeById[objectUid as string], [name]: value };
    try {
      const response = await saveNode(
        projectUid,
        nodeById[objectUid as string]?.uid as string,
        newValue,
      );
      if (response.data) {
        dispatch(updateNodes(newValue));
        dispatch(updateNodesNetwork(true));
      }
    } catch (e) {
      //
    }
  };

  return (
    <div>
      <div className="active-object__top-row">
        <p className="active-object__title">Центр сбора</p>
      </div>
      <div className="active-object__row">
        <ObjectStringInput
          className="input active-object__row-input long"
          label="Идентификатор"
          name="name"
          initValue={gatheringCentersModelById[objectUid as string]?.name}
          saveNewValue={saveNewNodeValue}
        />
      </div>
      <div className="active-object__row">
        <label className="input-label">Координаты</label>
        <div className="d-flex">
          <div className="active-object__row-input-prefix">lon</div>
          <ObjectInput
            className="input active-object__row-input short with-prefix left"
            label=""
            name="lon"
            initValue={nodeById[objectUid as string]?.coordinates?.[1]}
            saveNewValue={saveNewNodeValue}
          />
          <div className="active-object__row-input-prefix">lat</div>
          <ObjectInput
            className="input active-object__row-input short with-prefix"
            label=""
            name="lat"
            initValue={nodeById[objectUid as string]?.coordinates?.[0]}
            saveNewValue={saveNewNodeValue}
          />
        </div>
      </div>
      {experimentType === 'Адаптация на факт' ? (
        <SinkViewAF
          gatheringCenterFactPressure={
            gatheringCentersFactPressuresById[objectUid as string]
          }
          saveNewGatheringCenterFactPressureValue={
            saveNewGatheringCenterFactPressureValue
          }
        />
      ) : (
        <SinkView
          gatheringCentersModel={gatheringCentersModelById[objectUid as string]}
          saveNewGatheringCentersModelValue={saveNewGatheringCentersModelValue}
        />
      )}
    </div>
  );
};

export default Sink;
