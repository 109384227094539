import * as React from 'react';
import { useDropzone } from 'react-dropzone';

import classNames from 'classnames';

import { IDropzoneProps } from './types';

import './style.scss';

const Dropzone: React.FC<IDropzoneProps> = ({
  onDrop,
  accept,
  multiple,
  error,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={classNames('dropzone', isDragActive && 'active', { error })}
    >
      <input {...getInputProps()} accept={accept} multiple={multiple} />
      <p className={classNames('dropzone__text', isDragActive && 'active')}>
        {!isDragActive ? (
          <>
            Перетащите файл или{' '}
            <span className="dropzone__text_bold">выберите</span>
          </>
        ) : (
          <>Отпустите кнопку мыши</>
        )}
      </p>
    </div>
  );
};

export default Dropzone;
