import React from 'react';

import { formatter, oneDigitsFormatter } from '../../../../../../utils';
import { UseChartData } from '../../Chart/types';
import { seriesColors } from '../../Chart/utils';

const useChartData: UseChartData = wellModel => {
  const { pressures } = React.useMemo(() => {
    if (wellModel?.pressures) {
      return {
        pressures: wellModel?.pressures?.map(pressure => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return oneDigitsFormatter.format(pressure.atm);
        }),
      };
    }
    return {};
  }, [wellModel?.pressures]);

  return React.useMemo(() => {
    if (pressures) {
      const chartData: { name: string; data: any[] }[] = [];
      wellModel?.control_actions?.map((control_action, control_action_ix) => {
        const legendName =
          (control_action?.hertz &&
            `${formatter.format(control_action?.hertz)} Гц`) ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (control_action?.m3_per_day &&
            `${formatter.format(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              control_action?.m3_per_day,
            )} м3/сут`);
        return chartData.push({
          name: legendName,
          data: pressures.map((pressure, pressure_ix) => {
            const bottomHolePressure =
              wellModel?.bottomhole_pressures &&
              wellModel?.bottomhole_pressures[control_action_ix][pressure_ix];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return bottomHolePressure ? bottomHolePressure?.atm : 0;
          }),
        });
      });

      return {
        labels: pressures,
        datasets: Array.from(chartData).map((el, index) => {
          return {
            label: el.name,
            data: el.data,
            fill: false,
            backgroundColor: seriesColors[index % 9],
            borderColor: seriesColors[index % 9],
            color: seriesColors[index % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
          };
        }),
      };
    }
    return { labels: [], datasets: [] };
  }, [pressures, wellModel?.bottomhole_pressures]);
};

export default useChartData;
