import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { selectSolution } from 'store/taskSlice';

import { components } from '../../../../../generated/apiTypes';
import TableWrapper from '../../components/TableWrapper';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { segmentsTableHead, templateRowsNumber } from './utils';

const Table: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPipelineNetworkProblem'];
  const headKeys = Object.keys(segmentsTableHead);
  const [data, setData] = React.useState<any[]>([]);

  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.state?.segment_states.forEach(segment_state => {
      const segment = solution?.task?.network?.segments.find(
        item => item.uid === segment_state.segment_uid,
      );
      newData.push({
        length: segment?.length.m,
        start_node_name: segment?.start_node_name,
        end_node_name: segment?.end_node_name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        diameter: segment?.diameter?.mm,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        p_in: segment_state?.pressure_in?.atm,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: segment_state?.fluid_flow_rate_volume?.m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: segment_state?.oil_flow_rate_mass?.t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gas_flow_rate: segment_state?.gas_flow_rate_volume?.thousand_m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dp_dl: segment_state?.dp_dl?.atm_per_km,
        dt_dl:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          segment_state?.dt_dl?.celsius_per_km,
        fluid_velocity: segment_state?.fluid_velocity?.m_per_second,
        gas_velocity: segment_state?.gas_velocity?.m_per_second,
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__nodes-table')}>
        <TableHead headKeys={headKeys} tableHead={segmentsTableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default Table;
