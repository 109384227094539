import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { selectSolution } from 'store/taskSlice';

import { components } from '../../../../../../generated/apiTypes';
import TableHead from '../../../components/TableHead';
import TableWrapper from '../../../components/TableWrapper';
import { ISourcesTableProps } from '../../../types';
import TemplateTableRow from '../../UA/Table/TemplateTableRow';
import { templateRowsNumber } from '../../UA/Table/utils';
import TableRow from './TableRow';

const SourcesByStepTable: React.FC<ISourcesTableProps> = ({
  tableHead,
  step = 1,
  wellsControlModels,
}) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    solution?.solution?.well_step_table?.wells?.forEach(well => {
      const wellStateByWellId =
        solution?.solution?.well_step_table?.time2states &&
        Object.values(solution?.solution?.well_step_table?.time2states)
          .find((val, index) => index === step - 1)
          ?.find(well_state => well_state.well_id === well.uid);

      newData.push({
        name: well?.name,
        operation_type: well?.operation_type,
        current_state: well?.current_state,
        control_type: wellsControlModels?.find(val => val.well_id === well.uid)
          ?.control_type,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: wellStateByWellId?.fluid_flow_rate?.t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: wellStateByWellId?.oil_flow_rate?.t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gas_flow_rate: wellStateByWellId?.gas_flow_rate?.m3_per_day / 1000,
        frequency:
          well.operation_type === 'ЭЦН' && wellStateByWellId?.control?.hertz,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        temperature: wellStateByWellId?.temperature?.celsius,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pressure: wellStateByWellId?.pressure?.atm,
        consumption:
          well?.operation_type === 'Газлифт' &&
          wellStateByWellId?.control?.m3_per_second,
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, step]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__sources-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return (
                  <TableRow
                    key={index}
                    headKeys={headKeys}
                    el={value}
                    status={value.current_state}
                  />
                );
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SourcesByStepTable;
