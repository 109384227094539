import { formatter } from '../../../../../utils';

export const dashboardItems = ({
  pressures_sko,
  pressures_avg_abs_error,
  pressures_avg_rel_error,
  pressures_max_abs_error,
  pressures_max_rel_error,
}: any = {}) => [
  {
    dem: 'σP',
    value:
      pressures_sko !== undefined
        ? formatter.format(pressures_sko)
        : pressures_sko,
  },
  {
    dem: 'avg ΔP, атм',
    value:
      pressures_avg_abs_error !== undefined
        ? formatter.format(pressures_avg_abs_error)
        : pressures_avg_abs_error,
  },
  {
    dem: 'avg δP, %',
    value:
      pressures_avg_rel_error !== undefined
        ? formatter.format(pressures_avg_rel_error)
        : pressures_avg_rel_error,
  },
  {
    dem: 'max ΔP, атм',
    value:
      pressures_max_abs_error !== undefined
        ? formatter.format(pressures_max_abs_error)
        : pressures_max_abs_error,
  },
  {
    dem: 'max δP, %',
    value:
      pressures_max_rel_error !== undefined
        ? formatter.format(pressures_max_rel_error)
        : pressures_max_rel_error,
  },
];
