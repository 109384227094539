import * as React from 'react';
import { withRouter } from 'react-router-dom';

import Map from 'Components/Map';

import {
  createProject,
  createProjectFromFile,
} from '../../services/apiRequests';
import LeftCol from './LeftCol';
import { NewProjectPropsType } from './types';

import './style.scss';

const NewProject: React.FC<NewProjectPropsType> = ({ history }) => {
  const onCancelClick = () => {
    history.push('/');
  };

  const createProjectfromFile = async (file: File, projectName: string) => {
    const fileToSend = new FormData();
    fileToSend.append('file', file);
    try {
      const { data } = await createProject({
        project_name: projectName,
        file: fileToSend,
      });
      const id = data.project_uid;
      history.push(`/project/${id}`);
    } catch (e) {
      //
    }
  };

  const createProjectfromDetails = async (
    well_model_file: File,
    projectName: string,
    url: string,
  ) => {
    const fileToSend = new FormData();
    fileToSend.append('well_model_file', well_model_file);
    try {
      const { data } = await createProjectFromFile({
        project_name: projectName,
        iskra_project_url: url,
        file: fileToSend,
      });
      const id = data.project_uid;
      history.push(`/project/${id}`);
    } catch (e) {
      //
    }
  };

  return (
    <div className="new-project-container">
      <LeftCol
        onCancelClick={onCancelClick}
        createProjectFromFile={createProjectfromFile}
        createProjectFromDetails={createProjectfromDetails}
      />
      <Map />
    </div>
  );
};

export default withRouter(NewProject);
