import * as React from 'react';

export const templateRowsNumber = 5;

export const nodesAvgTableHead = {
  name: 'Название',
  pressure_avg: {
    title: 'Среднее P, атм',
    component: <>Среднее Р, атм</>,
  },
  temperature_avg: {
    title: 'Средняя t смеси, °С',
    component: <>Средняя °t смеси, °С</>,
  },
  oil_flow_rate: {
    title: 'Средний ΣQн, т/сут',
    component: <>Средний ΣQн, т/сут</>,
  },
  fluid_flow_rate: {
    title: 'Средний ΣQж, м3/сут',
    component: (
      <>
        Средний ΣQж,{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Средний ΣQг, тыс. м3/сут',
    component: (
      <>
        Средний ΣQг,{' тыс. '}
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
};

export const nodesByStepTableHead = {
  name: 'Название',
  pressure: {
    title: 'P, атм',
    component: <>Р, атм</>,
  },
  temperature: {
    title: 't смеси, °С',
    component: <>°t смеси, °С</>,
  },
  oil_flow_rate: {
    title: 'ΣQн, т/сут',
    component: <>ΣQн, т/сут</>,
  },
  fluid_flow_rate: {
    title: 'ΣQж, м3/сут',
    component: (
      <>
        ΣQж,{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'ΣQг, тыс. м3/сут',
    component: (
      <>
        ΣQг,{' тыс. '}
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
};
