import * as React from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { selectSolution } from 'store/taskSlice';

import Skeleton from '../../../../../Components/Skeleton';
import { components } from '../../../../../generated/apiTypes';
import { dashboardItems } from './utils';

const Dashboard: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPipelineNetworkProblem'];
  const [data, setData] = React.useState<any>(null);

  const getData = () => {
    const integralCharacteristic = solution?.solution?.integral_characteristic;
    const newData = {
      fluid_flow_rate:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        integralCharacteristic?.fluid_flow_rate_volume?.m3_per_day,
      oil_flow_rate:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        integralCharacteristic?.oil_flow_rate_mass?.t_per_day,
      gas_flow_rate:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        integralCharacteristic?.gas_flow_rate?.thousand_m3_per_day,
      temperature:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        integralCharacteristic?.temperature?.celsius,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pressure: integralCharacteristic?.pressure?.atm,
    };
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <div className="dashboard">
      {data &&
        dashboardItems(data)?.map((el, key) => {
          return (
            <div key={key} className={classNames('dashboard__item af_item')}>
              <p className="dashboard__item-dem">{el.dem}</p>
              <div className="dashboard__bottom">
                <p className="dashboard__item-value">
                  {el.value === undefined ? (
                    <Skeleton className={classNames('skeleton-dashboard')} />
                  ) : (
                    el.value
                  )}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Dashboard;
