import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { RootState } from './store';
import { IMapSlice } from './types';

const mapInitialState = initialState as IMapSlice;

export const mapSlice = createSlice({
  name: 'map',
  initialState: {
    activeObject: mapInitialState.activeObject,
    drawState: mapInitialState.drawState,
    loopingsState: mapInitialState.loopingsState,
    featureState: mapInitialState.featureState,
    mapContext: mapInitialState.mapContext,
    linesColor: mapInitialState.linesColor,
  },
  reducers: {
    setActiveObject: (
      state,
      action: PayloadAction<IMapSlice['activeObject']>,
    ) => {
      state.activeObject = action.payload;
    },
    updateDrawState: (state, action: PayloadAction<IMapSlice['drawState']>) => {
      state.drawState = {
        ...state.drawState,
        ...action.payload,
      };
    },
    resetMapSliceState: state => {
      state.activeObject = mapInitialState.activeObject;
      state.drawState = mapInitialState.drawState;
      state.featureState = mapInitialState.featureState;
    },
    setLoopingsData: (
      state,
      action: PayloadAction<IMapSlice['loopingsState']['items']>,
    ) => {
      state.loopingsState = {
        items: action.payload,
      };
    },
    updateFeatureState: (
      state,
      action: PayloadAction<IMapSlice['featureState']>,
    ) => {
      state.featureState = { ...action.payload };
    },
    setMapContext: (state, action: PayloadAction<IMapSlice['mapContext']>) => {
      state.mapContext = action.payload;
    },
    setLinesColor: (state, action: PayloadAction<IMapSlice['linesColor']>) => {
      state.linesColor = action.payload;
    },
  },
});

export const {
  setActiveObject,
  updateDrawState,
  resetMapSliceState,
  setMapContext,
  setLinesColor,
  setLoopingsData,
} = mapSlice.actions;

const selectSelf = (state: RootState) => state.map;

export const selectActiveObject = createSelector(
  selectSelf,
  map => map.activeObject,
);

export default mapSlice.reducer;
