import React from 'react';

function DrawButton(props: any) {
  return (
    <button
      type="button"
      className={
        props.drawState.activeElement === props.items.type
          ? 'draw-button__active'
          : 'draw-button'
      }
      onClick={() =>
        props.handleClick(props.items.type, {
          name: props.items.name,
          type: props.items.type,
        })
      }
    >
      {props.items.icon}
      <div
        className={
          props.drawState.activeElement === props.items.type
            ? 'draw-button-text__active'
            : 'draw-button-text'
        }
      >
        {props.items.name}
      </div>
    </button>
  );
}

export default DrawButton;
