import * as React from 'react';

import classNames from 'classnames';

import Input from '../../../../Components/Input';
import { accurateFormatter, coordsFormatter } from '../../../../utils';

interface IObjectInput {
  saveNewValue(value: number, name: string): void;
  label: string;
  name: string;
  subName?: string;
  extraSubName?: string;
  extraObject?: any;
  unit?: string;
  initValue: number | undefined;
  maxValue?: number;
  className: string;
  disabled?: boolean;
  error?: boolean;
}

const ObjectInput: React.FC<IObjectInput> = ({
  label,
  name,
  unit,
  initValue,
  maxValue,
  saveNewValue,
  className,
  disabled,
  subName,
  extraObject,
  extraSubName,
  error,
}) => {
  const [value, setValue] = React.useState<string | undefined>(undefined);

  const formatter = ['lat', 'lon'].includes(name)
    ? coordsFormatter
    : accurateFormatter;

  React.useEffect(() => {
    if (typeof initValue !== 'undefined') setValue(formatter.format(initValue));
  }, [initValue]);

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutSpaces = e.target.value.replace(/\s/g, '');
    const digitOnly = new RegExp('^[-]?([0-9]*)*[.,]?([0-9]{0,8})?$', 'g');
    if (valueWithoutSpaces.match(digitOnly)) {
      setValue(e.target.value);
    }
  };

  const onBlur = () => {
    const commaToDotValue = value?.replace(',', '.');
    const valueWithoutSpaces = commaToDotValue?.replace(/\s/g, '');
    if (valueWithoutSpaces) {
      let newValue: any = parseFloat(valueWithoutSpaces);
      if (maxValue !== undefined && newValue > maxValue) newValue = maxValue;
      if (newValue) {
        setValue(formatter.format(newValue));
        if (subName && unit && extraSubName && extraObject) {
          newValue = {
            [subName]: { [unit]: newValue },
            [extraSubName]: extraObject,
          };
        } else if (subName && unit) {
          newValue = {
            [subName]: { [unit]: newValue },
          };
        } else if (unit) {
          newValue = { [unit]: newValue };
        }
        saveNewValue(newValue, name);
      }
    }
  };

  return (
    <Input
      className={classNames(className, disabled && 'non-editable', { error })}
      label={label}
      value={value}
      onChange={changeValue}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

export default ObjectInput;
