import { components } from 'generated/apiTypes';
import update from 'immutability-helper';

import { oneDigitsFormatter } from '../../../../../utils';
import { ChartType } from '../types';

export const getTableHeaderByType = (
  type: components['schemas']['PNOWellOperationType'],
) => {
  if (type === 'Газлифт') {
    return 'Расход газлифтного газа, м3/сут';
  }
  return 'Частота, Гц';
};

export const getTableValueByType = (
  type: ChartType,
  wellModel: components['schemas']['PNOWellModel'],
  pressure_index: number,
  control_action_index: number,
) => {
  switch (type) {
    case 'FlowRates': {
      const value =
        wellModel?.flow_rates?.[control_action_index][pressure_index];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (value?.m3_per_day !== undefined)
        return oneDigitsFormatter.format(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value?.m3_per_day,
        );
      return null;
    }
    case 'Power': {
      const value =
        wellModel?.electric_power_values?.[control_action_index][
          pressure_index
        ];
      if (value?.joules !== undefined)
        return oneDigitsFormatter.format(value?.joules);
      return null;
    }
    case 'BottomHolePressures': {
      const value =
        wellModel?.bottomhole_pressures?.[control_action_index][pressure_index];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (value?.atm !== undefined)
        return oneDigitsFormatter.format(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value?.atm,
        );
      return null;
    }
    case 'Amperage': {
      const value = wellModel?.amperage?.[control_action_index][pressure_index];
      if (value?.amperes !== undefined)
        return oneDigitsFormatter.format(value?.amperes);
      return null;
    }
    case 'Loading': {
      const value = wellModel?.loading?.[control_action_index][pressure_index];
      if (value?.amper_per_amper !== undefined)
        return oneDigitsFormatter.format(value?.amper_per_amper);
      return null;
    }
    default:
      return '';
  }
};

export const getTableCaptionByType = (type: ChartType) => {
  switch (type) {
    case 'FlowRates':
      return 'Qж, м3/сут';
    case 'Power':
      return 'Мощность, Дж';
    case 'BottomHolePressures':
      return 'P заб, атм';
    case 'Amperage':
      return 'Сила тока, А';
    case 'Loading':
      return 'Загрузка, A/A';
    default:
      return '';
  }
};

export const getParameterByType = (type: ChartType) => {
  switch (type) {
    case 'FlowRates':
      return 'flow_rates';
    case 'Power':
      return 'electric_power_values';
    case 'BottomHolePressures':
      return 'bottomhole_pressures';
    case 'Amperage':
      return 'amperage';
    case 'Loading':
      return 'loading';
    default:
      return '';
  }
};

export const getUnitByType = (type: ChartType) => {
  switch (type) {
    case 'FlowRates':
      return 'm3_per_day';
    case 'Power':
      return 'joules';
    case 'BottomHolePressures':
      return 'atm';
    case 'Amperage':
      return 'amperes';
    case 'Loading':
      return 'amper_per_amper';
    default:
      return '';
  }
};

export const isModelExistByType = (
  type: ChartType,
  wellModel: components['schemas']['PNOWellModel'],
) => {
  switch (type) {
    case 'FlowRates': {
      return (wellModel?.flow_rates?.length as number) > 0;
    }
    case 'Power': {
      return (wellModel?.electric_power_values?.length as number) > 0;
    }
    case 'BottomHolePressures': {
      return (wellModel?.bottomhole_pressures?.length as number) > 0;
    }
    case 'Amperage': {
      return (wellModel?.amperage?.length as number) > 0;
    }
    case 'Loading': {
      return (wellModel?.loading?.length as number) > 0;
    }
    default:
      return false;
  }
};

export const getInitTableByType = (
  type: ChartType,
  wellModel: components['schemas']['PNOWellModel'],
): {
  model: components['schemas']['PNOWellModel'] | null;
  modified: boolean;
} => {
  switch (type) {
    case 'FlowRates': {
      if (wellModel?.flow_rates?.length > 0)
        return { model: wellModel, modified: false };
      const initTable: { m3_per_day: number }[][] = [];
      wellModel?.control_actions.forEach(
        (control_action, control_action_ix) => {
          initTable[control_action_ix] = [];
          wellModel?.pressures.forEach((pressure, pressure_ix) => {
            initTable[control_action_ix][pressure_ix] = { m3_per_day: 0 };
          });
        },
      );
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        model: update(wellModel, { flow_rates: { $set: initTable } }),
        modified: true,
      };
    }
    case 'Power': {
      if ((wellModel?.electric_power_values?.length as number) > 0)
        return { model: wellModel, modified: false };
      const initTable: { joules: number }[][] = [];
      wellModel?.control_actions.forEach(
        (control_action, control_action_ix) => {
          initTable[control_action_ix] = [];
          wellModel?.pressures.forEach((pressure, pressure_ix) => {
            initTable[control_action_ix][pressure_ix] = { joules: 0 };
          });
        },
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return {
        model: update(wellModel, {
          electric_power_values: { $set: initTable },
        }),
        modified: true,
      };
    }
    case 'BottomHolePressures': {
      if ((wellModel?.bottomhole_pressures?.length as number) > 0)
        return { model: wellModel, modified: false };
      const initTable: { atm: number }[][] = [];
      wellModel?.control_actions.forEach(
        (control_action, control_action_ix) => {
          initTable[control_action_ix] = [];
          wellModel?.pressures.forEach((pressure, pressure_ix) => {
            initTable[control_action_ix][pressure_ix] = { atm: 0 };
          });
        },
      );
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        model: update(wellModel, { bottomhole_pressures: { $set: initTable } }),
        modified: true,
      };
    }
    case 'Amperage': {
      if ((wellModel?.amperage?.length as number) > 0)
        return { model: wellModel, modified: false };
      const initTable: { amperes: number }[][] = [];
      wellModel?.control_actions.forEach(
        (control_action, control_action_ix) => {
          initTable[control_action_ix] = [];
          wellModel?.pressures.forEach((pressure, pressure_ix) => {
            initTable[control_action_ix][pressure_ix] = { amperes: 0 };
          });
        },
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return {
        model: update(wellModel, { amperage: { $set: initTable } }),
        modified: true,
      };
    }
    case 'Loading': {
      if ((wellModel?.loading?.length as number) > 0)
        return { model: wellModel, modified: false };
      const initTable: { amper_per_amper: number }[][] = [];
      wellModel?.control_actions.forEach(
        (control_action, control_action_ix) => {
          initTable[control_action_ix] = [];
          wellModel?.pressures.forEach((pressure, pressure_ix) => {
            initTable[control_action_ix][pressure_ix] = { amper_per_amper: 0 };
          });
        },
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return {
        model: update(wellModel, { loading: { $set: initTable } }),
        modified: true,
      };
    }
    default:
      return { model: null, modified: false };
  }
};
