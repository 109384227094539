import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectActiveObject } from '../../store/mapSlice';
import ActiveObject from './ActiveObject';
import TaskSettings from './TaskSettings/TaskSettings';

interface IRightCol {
  calcTask: () => Promise<void>;
  projectUid: string;
}

const RightCol: React.FC<IRightCol> = ({ calcTask, projectUid }) => {
  const activeObject = useSelector(selectActiveObject);
  if (activeObject.type) {
    return <ActiveObject projectUid={projectUid} />;
  }

  return <TaskSettings calcTask={calcTask} />;
};

export default RightCol;
