import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  selectNodes,
  selectPhysChemPackages,
  selectSegments,
  selectWells,
} from '../../store/projectSlice';
import NetworkObjectsToggle from './NetworkObjectsToggle';
import { Node } from './types';

const isUidStringGuard = (args: {
  end_uid?: string;
  start_uid?: string;
}): args is { end_uid: string; start_uid: string } =>
  !!args.end_uid && !!args.start_uid;

const NetworkObjects: React.FC = () => {
  const physChemPackages = useSelector(selectPhysChemPackages);
  const nodes = useSelector(selectNodes);
  const segments = useSelector(selectSegments);
  const wells = useSelector(selectWells);

  const nodesById = React.useMemo(() => {
    return nodes?.items.reduce<{ [key: string]: Node }>((acc, cur) => {
      if (cur.uid) {
        acc[cur.uid] = cur;
      }
      return acc;
    }, {});
  }, [nodes]);

  return (
    <div className="network-objects">
      <NetworkObjectsToggle
        title="Скважины"
        type="SOURCE"
        // data={nodes?.items?.filter(({ type }) => type === 'SOURCE')}
        data={wells?.items}
      />
      <NetworkObjectsToggle
        title="Соединения"
        type="JUNCTION"
        data={nodes?.items?.filter(({ type }) => type === 'JUNCTION')}
      />
      <NetworkObjectsToggle
        title="Центры сбора"
        type="SINK"
        data={nodes?.items?.filter(({ type }) => type === 'SINK')}
      />
      <NetworkObjectsToggle
        title="Трубопроводы"
        type="PIPELINE"
        data={segments?.items?.filter(isUidStringGuard).map(segment => ({
          uid: segment.uid,
          name: `${nodesById?.[segment.start_uid as string]?.name} - ${
            nodesById?.[segment.end_uid as string]?.name
          }`,
        }))}
      />
      <NetworkObjectsToggle
        title="Пакеты ФХС"
        type="PACKAGE"
        data={physChemPackages?.map(item => ({
          uid: item.uid,
          name: item.name || '',
        }))}
      />
    </div>
  );
};

export default NetworkObjects;
