export const createName = (mapContext: any, nameType: string) => {
  const sources: any = mapContext.getStyle().sources;

  let newName = '';
  if (nameType === 'junction') newName = 'Новый узел';
  if (nameType === 'source') newName = 'Новая скважина';
  if (nameType === 'sink') newName = 'Новый ЦС';
  if (nameType === 'segment') newName = 'Новая труба';

  const namesNumbersArr: any = [];

  if (sources && Object.keys(sources).length) {
    Object.keys(sources).forEach((item: any) => {
      if ([nameType === 'segment' ? 'lines' : nameType].includes(item)) {
        if (sources[item].data.features.length) {
          sources[item].data.features.forEach((feature: any) => {
            if (feature.properties.name.includes(newName)) {
              const nameNumber =
                typeof parseInt(
                  feature.properties.name.slice(newName.length + 1),
                  10,
                ) === 'number'
                  ? parseInt(
                      feature.properties.name.slice(newName.length + 1),
                      10,
                    )
                  : null;
              if (nameNumber)
                namesNumbersArr.push(
                  feature.properties.name.slice(newName.length + 1),
                );
            }
          });
        }
      }
    });
  }
  if (namesNumbersArr.length) {
    const sortNamesNumbersArr = namesNumbersArr.sort((a: any, b: any) => a - b);
    if (newName) {
      const lastNumber = parseInt(
        sortNamesNumbersArr[sortNamesNumbersArr.length - 1],
        10,
      );
      newName += ` ${lastNumber + 1}`;
    }
  } else {
    newName += ' 1';
  }

  return newName;
};
