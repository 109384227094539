import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from 'images/Project/close-icon.svg';

import { selectShowDialog } from '../../store/commonSlice';
import Button from '../Button';

import './style.scss';

interface IDeleteConfirmationWindowProps {
  closeWindow(): void;
  deleteObject(): void;
}

const DeleteConfirmationWindow: React.FC<IDeleteConfirmationWindowProps> = ({
  deleteObject,
  closeWindow,
}) => {
  const showDialog = useSelector(selectShowDialog);

  const onDeleteClick = () => {
    deleteObject();
  };

  React.useEffect(() => {
    const onKeyDownHandler = (e: React.KeyboardEvent) => {
      const { key } = e;
      if (key === 'Enter') {
        onDeleteClick();
      }
    };
    document.addEventListener('keydown', onKeyDownHandler as any);
    return () => {
      document.removeEventListener('keydown', onKeyDownHandler as any);
    };
  }, []);

  const titleByObjectType = () => {
    switch (showDialog?.objectType) {
      case 'SINK':
        return 'центра сбора';
      case 'SOURCE':
        return 'скважины';
      case 'JUNCTION':
        return 'узла';
      case 'PIPELINE':
        return 'трубопровода';
      case 'PROJECT':
        return 'проекта';
      default:
        return '';
    }
  };
  const messageByObjectType = () => {
    switch (showDialog?.objectType) {
      case 'SINK':
        return 'ЦС';
      case 'SOURCE':
        return 'скважину';
      case 'JUNCTION':
        return 'узел';
      case 'PIPELINE':
        return 'трубопровод';
      case 'PROJECT':
        return 'проект';
      default:
        return '';
    }
  };
  return (
    <div className="dialog-window-background">
      <div className="dialog-window-container">
        <div className="dialog-window-wrapper">
          <button
            type="button"
            className="dialog-window-close-button"
            onClick={closeWindow}
          >
            <CloseIcon />
          </button>
          <div className="dialog-window-title">
            Удаление {titleByObjectType()}
          </div>
          <div className="dialog-window-message">
            Вы действительно хотите удалить {messageByObjectType()}{' '}
            <span className="object-name">{showDialog?.objectName}</span> ?
          </div>
          {showDialog?.objectType === 'PROJECT' && (
            <div className="dialog-window-message message-details">
              Схема сети и все связанные с ним расчеты будут безвозвратно
              удалены.
            </div>
          )}
          {showDialog?.objectType === 'SOURCE' && (
            <div className="dialog-window-message message-details">
              При удалении скважины, все связанные с ней трубопроводы будут
              удалены.
            </div>
          )}
          {showDialog?.objectType === 'JUNCTION' && (
            <div className="dialog-window-message message-details">
              При удалении узла, все связанные с ним трубопроводы будут удалены.
            </div>
          )}
          {showDialog?.objectType === 'SINK' && (
            <div className="dialog-window-message message-details">
              При удалении ЦС, все связанные с ним трубопроводы будут удалены.
            </div>
          )}
          <div className="dialog-window-buttons-wrapper">
            <Button
              type="button"
              className="dialog-window-delete-button"
              onClick={onDeleteClick}
            >
              Удалить
            </Button>
            <Button type="button" mode="secondary" onClick={closeWindow}>
              Отмена
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationWindow;
