import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { setPackageForAllWells } from '../../../services/apiRequests';
import { selectPhysChemPackages, setWells } from '../../../store/projectSlice';
import { RootState } from '../../../store/store';
import { IProjectSlice } from '../../../store/types';
import ObjectSelect, { OptionType } from './components/ObjectSelect';

interface Props {
  projectUid: string;
  wells: IProjectSlice['wells'];
}

const AllSources: React.FC<Props> = props => {
  const { projectUid, wells } = props;
  const physChemPackages = useSelector(selectPhysChemPackages);
  const [samePackageId, setSamePackageId] = React.useState<string>('');
  const dispatch = useDispatch();

  React.useEffect(() => {
    const packageId: string = wells.items
      .map(el => el.phys_chem_package_id)
      ?.reduce((previousValue, currentValue, index) => {
        if (index === 0) return currentValue;
        if (previousValue === currentValue) return currentValue;
        return '';
      });
    setSamePackageId(packageId);
  }, []);

  const PCPackages: OptionType<string>[] = physChemPackages.map(value => {
    return {
      value: value?.uid as string,
      label: value?.name as string,
    };
  });

  const saveNewPackage = async (value: number | string) => {
    try {
      const response = await setPackageForAllWells({
        project_id: projectUid,
        phys_chem_id: value as string,
      });
      if (response?.data) {
        dispatch(setWells(response.data));
        setSamePackageId(value as string);
      }
    } catch (e) {
      //
    }
  };

  return (
    <div>
      <div className="active-object__top-row">
        <p className="active-object__title">Все скважины</p>
      </div>
      <div>
        <div className="active-object__row">
          <div className="active-object__input-with-unit-wrapper">
            <ObjectSelect
              label="Пакет ФХС"
              classNames="active-object__row-input"
              classNamesWrapper="active-object__row-input-wrapper full-size"
              name="phys_chem_package_id"
              options={PCPackages}
              value={PCPackages?.find(el => el.value === samePackageId)}
              saveNewValue={saveNewPackage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  wells: state.project.wells,
});

export default connect(mapStateToProps)(AllSources);
