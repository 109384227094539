import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { selectExperimentStatus } from 'store/taskSlice';

import Checkbox from '../../../Components/Checkbox';
import { components } from '../../../generated/apiTypes';
import { setExperimentSettings } from '../../../services/apiRequests';
import useGetExperimentSettings from './hooks/use-get-experiment-settings';
import TaskSettingsInputRow from './TaskSettingInputRow';

const MOSettings: React.FC = () => {
  const params = useParams() as { id: string };
  const settings = useGetExperimentSettings(params.id);
  const status = useSelector(selectExperimentStatus);

  const [settingsState, setSettingsState] = React.useState<
    components['schemas']['ExperimentSettings'] | undefined
  >(undefined);

  React.useEffect(() => {
    setSettingsState(settings as components['schemas']['ExperimentSettings']);
  }, [settings]);

  const saveNewValue = (value: number, name: string) => {
    setExperimentSettings(params.id, {
      ...(settingsState as components['schemas']['ExperimentSettings']),
      [name]: value,
    });
    setSettingsState({
      ...(settingsState as components['schemas']['ExperimentSettings']),
      [name]: value,
    });
  };

  const saveNewOptimizationValue = (value: boolean, name: string) => {
    if (settingsState) {
      setExperimentSettings(params.id, {
        ...settingsState,
        optimization_experiment_settings: {
          ...settingsState.optimization_experiment_settings,
          [name]: value,
        },
      } as any);
      setSettingsState({
        ...settingsState,
        optimization_experiment_settings: {
          ...settingsState.optimization_experiment_settings,
          [name]: value,
        },
      } as any);
    }
  };

  return (
    <>
      <div>
        <div className="project-task-settings__row">
          <TaskSettingsInputRow
            label="Длительность"
            value={settingsState?.period}
            unit="мин"
            saveNewValue={saveNewValue}
            name="period"
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
          <TaskSettingsInputRow
            label="Шаг"
            value={settingsState?.time_step}
            unit="мин"
            saveNewValue={saveNewValue}
            name="time_step"
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
        </div>
        <div className="project-task-settings__row">
          <TaskSettingsInputRow
            label="Точность расчетов"
            value={settingsState?.periodic_nodal_analysis_settings?.accuracy}
            unit="aтм"
            saveNewValue={saveNewValue}
            name="periodic_nodal_analysis_settings"
            subName="accuracy"
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
        </div>
        <div className="project-task-settings__options">
          <Checkbox
            label="Варьировать отступы по времени"
            checkedValue={
              settingsState?.optimization_experiment_settings
                ?.allow_to_vary_offset_time as boolean
            }
            id="allow_to_vary_offset_time"
            setCheckedValue={saveNewOptimizationValue}
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
          <Checkbox
            label="Варьировать частоты ПДФ"
            checkedValue={
              settingsState?.optimization_experiment_settings
                ?.allow_to_vary_control_action as boolean
            }
            id="allow_to_vary_control_action"
            setCheckedValue={saveNewOptimizationValue}
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
          <Checkbox
            label="Варьировать статусы скважин"
            checkedValue={
              settingsState?.optimization_experiment_settings
                ?.allow_to_vary_current_state as boolean
            }
            id="allow_to_vary_current_state"
            setCheckedValue={saveNewOptimizationValue}
            disabled={[
              'DRAFT',
              'SENT',
              'PREPARING',
              'PREPARED',
              'SOLVING',
            ].includes(status)}
          />
        </div>
      </div>
    </>
  );
};

export default MOSettings;
