import * as React from 'react';

import LegendButton from './LegendButton';

import './style.scss';

interface IGantChart {
  chartData: any;
}

const GantChart: React.FC<IGantChart> = ({ chartData }) => {
  const [selectedLabels, setSelectedLabels] = React.useState<string[]>([]);

  const toggleLegendButton = (uid: string) => {
    const check = selectedLabels.find((item: any) => item === uid);
    if (!check) {
      const newArr = [...selectedLabels];
      newArr.push(uid);
      setSelectedLabels([...newArr]);
    } else {
      const newArr = selectedLabels.filter((item: any) => item !== uid);
      setSelectedLabels([...newArr]);
    }
  };

  const getBackgroundColor = (arr: any) => {
    const color1 = arr[0];
    const color2 = arr[1];
    const ratio = arr[2];
    const hex = function (x: any) {
      x = x.toString(16);
      return x.length === 1 ? `0${x}` : x;
    };

    const r = Math.ceil(
      parseInt(color1.substring(0, 2), 16) * ratio +
        parseInt(color2.substring(0, 2), 16) * (1 - ratio),
    );
    const g = Math.ceil(
      parseInt(color1.substring(2, 4), 16) * ratio +
        parseInt(color2.substring(2, 4), 16) * (1 - ratio),
    );
    const b = Math.ceil(
      parseInt(color1.substring(4, 6), 16) * ratio +
        parseInt(color2.substring(4, 6), 16) * (1 - ratio),
    );
    return hex(r) + hex(g) + hex(b);
  };

  const getMaxOfArrays = () => {
    const newMaxarr: any = [];
    for (let i = 0; i < chartData.datasets.length; i += 1) {
      newMaxarr.push(Math.max.apply(null, chartData.datasets[i].data));
    }
    return Math.max.apply(null, newMaxarr);
  };

  const gradientNumberValues = () => {
    const maxValue: any = Math.round(getMaxOfArrays());
    const arr: any = [];
    for (let i = 0; i <= maxValue; i += maxValue / 10) {
      arr.push(maxValue <= 10 ? (Math.round(i) * 10) / 10 : Math.round(i));
    }

    return arr.sort((a: any, b: any) => b - a);
  };

  const prepareChartStyle = (data: number) => {
    const roundData = Math.round(data);
    const length = chartData.datasets[0].data.length;
    const width = 100 / length;
    const colors = [
      '#AE72FC',
      '#6590FD',
      '#3DD0FF',
      '#63EE82',
      '#FAF000',
      '#F69A1',
      '#FF4A4A',
    ];
    const newArr: any = [];
    const maxDataValue = getMaxOfArrays();
    for (let i = 0; i < colors.length; i += 1) {
      if (
        roundData &&
        i === 0 &&
        (roundData / maxDataValue) * 100 <= (100 / colors.length) * (i + 1)
      ) {
        newArr.push(colors[i].substring(1));
        newArr.push(colors[i + 1].substring(1));
        const ratio =
          ((roundData / maxDataValue) * 100) /
          ((100 / colors.length) * (i + 1));
        newArr.push(ratio);
      } else if (
        roundData &&
        i !== 0 &&
        i !== 6 &&
        (roundData / maxDataValue) * 100 >= (100 / colors.length) * i &&
        (roundData / maxDataValue) * 100 <= (100 / colors.length) * (i + 1)
      ) {
        newArr.push(colors[i].substring(1));
        newArr.push(colors[i + 1].substring(1));
        const ratio =
          ((roundData / maxDataValue) * 100) /
          ((100 / colors.length) * (i + 1));
        newArr.push(ratio);
      } else if (
        roundData &&
        i === 6 &&
        (roundData / maxDataValue) * 100 >= (100 / colors.length) * (i - 1) &&
        (roundData / maxDataValue) * 100 <= (100 / colors.length) * (i + 1)
      ) {
        newArr.push(colors[i].substring(1));
        newArr.push(colors[i - 1].substring(1));
        const ratio =
          ((roundData / maxDataValue) * 100) /
          ((100 / colors.length) * (i + 1));
        newArr.push(ratio);
      }
    }
    return {
      width: `${width.toString()}%`,
      backgroundColor: newArr.length && `#${getBackgroundColor(newArr)}`,
    };
  };

  const onLabelsScroll = (e: any) => {
    const lines = document.querySelector('.charts__gant-chart__lines');
    if (lines) {
      lines.scrollTop = e.target.scrollTop;
    }
  };

  const onLinesScroll = (e: any) => {
    const labels = document.querySelector('.charts__gant-chart__labels');
    const scale = document.querySelector('.charts__gant-chart__scale-scroll');
    if (labels) {
      labels.scrollTop = e.target.scrollTop;
    }

    if (scale) {
      scale.scrollLeft = e.target.scrollLeft;
    }
  };

  const onScaleScroll = (e: any) => {
    const lines = document.querySelector('.charts__gant-chart__lines');
    if (lines) {
      lines.scrollLeft = e.target.scrollLeft;
    }
  };

  const renderLabels = () => {
    return selectedLabels.length ? (
      <div className="charts__gant-chart__labels">
        {chartData.datasets
          .filter((item: any) => selectedLabels.includes(item.uid))
          .map((item: any, i: any) => (
            <div
              key={i}
              style={{
                marginBottom: '20px',
              }}
            >
              <p className="charts__gant-chart__labels-text">{item.label}</p>
            </div>
          ))}
      </div>
    ) : (
      <div className="charts__gant-chart__labels">
        {chartData.datasets?.map((item: any, i: any) => (
          <div
            key={i}
            style={{
              marginBottom: '20px',
            }}
          >
            <p className="charts__gant-chart__labels-text">{item.label}</p>
          </div>
        ))}
      </div>
    );
  };

  const renderLines = () => {
    return selectedLabels.length ? (
      <div className="charts__gant-chart__lines">
        {chartData.datasets
          .filter((item: any) => selectedLabels.includes(item.uid))
          .map((item: any, a: any) => (
            <div key={a} className="charts__gant-chart__lines-line">
              {item.data.map((data: any, i: any) => (
                <div
                  key={i}
                  className="charts__gant-chart__lines-line__block"
                  style={prepareChartStyle(data)}
                />
              ))}
            </div>
          ))}
      </div>
    ) : (
      <div className="charts__gant-chart__lines">
        {chartData.datasets.map((item: any, a: any) => (
          <div key={a} className="charts__gant-chart__lines-line">
            {item.data.map((data: any, i: any) => (
              <div
                key={i}
                className="charts__gant-chart__lines-line__block"
                style={prepareChartStyle(data)}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  const renderHorizontalScale = () => {
    // const filteredLabels = chartData.labels;

    const filteredLabels = chartData.labels.filter(
      (item: any) => parseInt(item, 10) % 10 === 0,
    );

    // if (filteredLabels.length >= 80) {
    //   filteredLabels = filteredLabels.filter(
    //     (item: any, i: any) => i % Math.round(filteredLabels.length / 40),
    //   );
    // }

    return filteredLabels.map((item: any, i: any) => (
      <div key={i}>
        <p>{Math.round(parseInt(item, 10))}</p>
      </div>
    ));
  };

  const handleScroll = (e: any) => {
    const className = e.target.className;

    if (className === 'charts__gant-chart__labels') {
      onLabelsScroll(e);
    } else if (className === 'charts__gant-chart__lines') {
      onLinesScroll(e);
    } else if (className === 'charts__gant-chart__scale-scroll') {
      onScaleScroll(e);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
  }, []);

  return (
    <div className="charts__gant">
      <div className="charts__gant-chart__labels-lines-gradient">
        {renderLabels()}
        {renderLines()}
        <div className="charts__gant-chart__gradient">
          <div
            className="charts__gant-chart__gradient-scale"
            style={{
              background:
                'linear-gradient(180deg, #FF4A4A 0%, #F69A10 18.13%, #FAF000 35.32%, #63EE82 51.98%, #3DD0FF 67.61%, #6590FD 83.76%, #AE72FC 100%)',
            }}
          />
          <div className="charts__gant-scale_values">
            {gradientNumberValues().map((item: any, i: any) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
      <div style={{ width: '100%', marginTop: '10px' }}>
        <div className="charts__gant-chart__scale-scroll">
          <div className="charts__gant-chart__scale">
            {renderHorizontalScale()}
            {chartData.labels[chartData.labels.length - 1] % 10 !== 0 && (
              <p>
                {Math.round(
                  parseInt(chartData.labels[chartData.labels.length - 1], 10),
                )}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="charts__gant-chart__legend">
        {chartData.datasets?.map((item: any) => (
          <LegendButton
            key={item.uid}
            uid={item.uid}
            label={item.label}
            active={selectedLabels.includes(item.uid)}
            toggleLegendButton={toggleLegendButton}
          />
        ))}
      </div>
    </div>
  );
};

export default GantChart;
