import * as React from 'react';

export const templateRowsNumber = 5;

export const segmentsTableHead = {
  start_node_name: {
    title: 'V0',
    component: (
      <>
        V<span className="subscript">0</span>
      </>
    ),
  },
  end_node_name: {
    title: 'V1',
    component: (
      <>
        V<span className="subscript">1</span>
      </>
    ),
  },
  length: 'L, м',
  diameter: 'D, мм',
  p_in: {
    title: 'P0, атм',
    component: (
      <>
        Р<span className="subscript">0</span>,
        <br /> атм
      </>
    ),
  },
  oil_flow_rate: {
    title: 'Qн0, т/сут',
    component: (
      <>
        Qн<span className="subscript">0</span>,
        <br />
        т/cут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Qж0, м3/сут',
    component: (
      <>
        Qж<span className="subscript">0</span>,
        <br />
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Qг0, тыс. м3/сут',
    component: (
      <>
        Qг<span className="subscript">0</span>, <br />
        <span className="relative">
          тыс. м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  dp_dl: {
    title: 'ΔP/ΔL, атм/км',
    component: (
      <>
        ΔP/ΔL,
        <br />
        атм/км
      </>
    ),
  },
  dt_dl: {
    title: 'Δt/ΔL, °С/км',
    component: (
      <>
        Δt/ΔL,
        <br />
        °С/км
      </>
    ),
  },
  fluid_velocity: {
    title: 'υж-max, м/c',
    component: (
      <>
        υ<span className="subscript">ж-max </span>,<br />
        м/c
      </>
    ),
  },
  gas_velocity: {
    title: 'υг-max, м/c',
    component: (
      <>
        υ<span className="subscript">г-max </span>,<br />
        м/c
      </>
    ),
  },
};
