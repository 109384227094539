import * as React from 'react';
import { useSelector } from 'react-redux';

import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { selectProject } from 'store/projectSlice';
import { selectSolution } from 'store/taskSlice';

import { ReactComponent as ExcelIcon } from '../../../../images/NewProject/excel-file-icon.svg';
import { ReactComponent as ProgressIcon } from '../../../../images/Project/progress-icon.svg';
import {
  getExcelAFSolution,
  getExcelGRSolution,
  getExcelUASolution,
} from '../../../../services/apiRequests';

interface IExportButton {
  taskId: string | null;
}

const ExportButton: React.FC<IExportButton> = ({ taskId }) => {
  const solutionType = useSelector(selectSolution)?.type;
  const project = useSelector(selectProject);
  const [exporting, setExporting] = React.useState<boolean>(false);

  const experimentShortNameByType = () => {
    switch (solutionType) {
      case 'Узловой анализ':
        return 'NA';
      case 'Гидравлический расчет':
        return 'HYDRO';
      case 'Адаптация на факт':
        return 'FA';
      case 'Оптимизация режима':
        return 'MO';
      case 'Оптимизация сети':
        return 'NO';
      default:
        return '';
    }
  };
  const downloadExcelResults = async () => {
    if (taskId)
      try {
        setExporting(true);
        let response: AxiosResponse<Blob> | undefined;
        switch (solutionType) {
          case 'Узловой анализ':
            response = await getExcelUASolution(taskId);
            break;
          case 'Гидравлический расчет':
            response = await getExcelGRSolution(taskId);
            break;
          case 'Адаптация на факт':
            response = await getExcelAFSolution(taskId);
            break;
          case 'Оптимизация режима':
            break;
          case 'Оптимизация сети':
            break;
          default:
            break;
        }
        if (response) {
          const url = window.URL.createObjectURL(
            new Blob([response?.data as Blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${project?.name}-${experimentShortNameByType()}.xlsx`,
          );
          document.body.appendChild(link);
          link.click();
        }
      } catch (e) {
        //
      }
    setExporting(false);
  };

  return (
    <button
      type="button"
      className={classNames('project-results-panel__export-btn', {
        exporting,
      })}
      onClick={downloadExcelResults}
      disabled={
        exporting ||
        ['Оптимизация режима', 'Оптимизация сети'].includes(
          solutionType as string,
        )
      }
    >
      {exporting ? (
        <>
          <ProgressIcon className="project-results-panel__progress-icon export-progress-icon" />
          <p>Подготовка...</p>
        </>
      ) : (
        <>
          <ExcelIcon className="project-results-panel__export-icon" />
          <p> Экспорт в Excel</p>
        </>
      )}
    </button>
  );
};

export default ExportButton;
