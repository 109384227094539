import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Amperage from './Amperage';
import BottomholePressures from './BottomHolePressures';
import FlowRates from './FlowRates';
import Loading from './Loading';
import Power from './Power';

const WellModel: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList className="results__tab-list underline">
        <Tab className="results__tab">Дебит жидкости</Tab>
        <Tab className="results__tab">Загрузка</Tab>
        <Tab className="results__tab">Сила тока</Tab>
        <Tab className="results__tab">Мощность</Tab>
        <Tab className="results__tab">Забойные давления</Tab>
      </TabList>
      <div className="results-tables__container">
        <TabPanel>
          <FlowRates />
        </TabPanel>
        <TabPanel>
          <Loading />
        </TabPanel>
        <TabPanel>
          <Amperage />
        </TabPanel>
        <TabPanel>
          <Power />
        </TabPanel>
        <TabPanel>
          <BottomholePressures />
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default WellModel;
