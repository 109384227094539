import React from 'react';

import classnames from 'classnames';
import Skeleton from 'Components/Skeleton';

interface ITemplateTableRowProps {
  headKeys: any;
}

const TemplateTableRow: React.FC<ITemplateTableRowProps> = ({ headKeys }) => {
  return (
    <tr className={classnames('table-row')}>
      {headKeys.map((key: string) => (
        <td className={classnames('table-data')} key={key}>
          <Skeleton
            className={classnames(
              'skeleton-table',
              ['start_node_name', 'end_node_name'].includes(key) && 'long left',
            )}
          />
        </td>
      ))}
    </tr>
  );
};

export default TemplateTableRow;
