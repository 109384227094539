import React, { useContext, useEffect, useRef } from 'react';
import { MapContext } from 'react-mapbox-gl';
import { connect, useDispatch } from 'react-redux';

import { setNodes, updateNodesNetwork } from 'store/projectSlice';
import { v4 as uuidv4 } from 'uuid';

import { setActiveObject, updateDrawState } from '../../../store/mapSlice';
import { RootState } from '../../../store/store';
import { IMapSlice, IProjectSlice } from '../../../store/types';
import { createName } from '../hooks/createName';
import { IProjectMap } from '../types';
import Features from './Features';
import ZoomButtons from './ZoomButtons';

interface Props {
  drawState: IProjectMap['drawState'];
  activeObject: IMapSlice['activeObject'];
  nodes: IProjectSlice['nodes'];
  wells: IProjectSlice['wells'];
  gatheringCenterModel: IProjectSlice['gatheringCentersModels'];
  center: any;
}

function ProjectMap(props: Props) {
  const { drawState, nodes, activeObject } = props;
  const dispatch = useDispatch();
  const mapContext = useContext(MapContext);
  const refDrawState = useRef<any>(null);
  const [drawClickIsExist, setDrawClickExist] = React.useState(false);

  const addNodes = async (
    uid: string,
    newName: string,
    coordinates: [number, number],
  ) => {
    if (drawState.activeElement) {
      const newNodesArr = JSON.parse(JSON.stringify(nodes));
      newNodesArr.items.push({
        uid,
        name: newName,
        type: drawState.activeElement.toUpperCase(),
        coordinates,
      });
      dispatch(setNodes(newNodesArr.items));
      dispatch(
        setActiveObject({
          node_uid: uid,
          type: drawState.activeElement.toUpperCase(),
          object_uid: uid,
          object_name: newName,
        }),
      );
      dispatch(updateNodesNetwork(true));
      setDrawClickExist(false);
    }
  };

  const handleDrawClick = () => {
    setDrawClickExist(true);
    if (
      drawState.isActive &&
      mapContext &&
      drawState.activeElement !== 'segment'
    ) {
      mapContext.getCanvasContainer().style.cursor = 'crosshair';
      refDrawState.current = drawState;
      mapContext?.once('click', async (e: any) => {
        if (drawState.activeElement !== refDrawState.current.activeElement)
          return;
        dispatch(
          updateDrawState({
            isActive: false,
            activeElement: null,
            params: null,
          }),
        );

        mapContext.getCanvasContainer().style.cursor = 'grab';
        if (drawState.activeElement) {
          const uid: string = uuidv4();
          const newName = createName(mapContext, drawState.activeElement);
          const newCoordinates: [number, number] = [e.lngLat.lng, e.lngLat.lat];
          addNodes(uid, newName, newCoordinates);
          // socketSendData(uid, newName, newCoordinates);
          mapContext.getCanvasContainer().style.cursor = 'grab';
        }
      });
    }
  };

  useEffect(() => {
    if (
      !drawClickIsExist &&
      drawState.isActive &&
      mapContext &&
      drawState.activeElement !== 'segment'
    ) {
      handleDrawClick();
    }
  }, [drawState.isActive, drawState.activeElement, mapContext, activeObject]);

  return (
    <>
      <ZoomButtons {...props} />
      <Features />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  drawState: state.map.drawState,
  activeObject: state.map.activeObject,
  nodes: state.project.nodes,
  wells: state.project.wells,
  gatheringCenterModel: state.project.gatheringCentersModels,
});

export default connect(mapStateToProps)(ProjectMap);
