import { setSocketClear, setSocketState } from '../store/socketSlice';
import RequestSocketActions from './appDB/RequestSocketActions';
import Socket from './Socket';

const requestActions = new RequestSocketActions();

const socketMiddleware: any = (store: any) => {
  const dispatch: any = store.dispatch;

  // HANDLE FORUM COMMENTS

  const receiveData = (response: any) => {
    const responseData = JSON.parse(response.data).request;
    console.log('responseData', responseData);
    dispatch({
      type: 'SOCKET_UPDATE',
      payload: { receiveData: responseData, isLoading: false },
    });
    requestActions.deleteData(responseData);
  };

  const setSocketConnectionStatus = (value: any) => {
    dispatch(setSocketState(value));
  };

  const updateSocketStatus = (value: any) => {
    dispatch(setSocketState(value));
  };

  const clearSocket = () => {
    dispatch(setSocketClear());
  };

  const socket = new Socket(receiveData, setSocketConnectionStatus);

  return (next: any) => async (action: any) => {
    switch (action.type) {
      case 'SOCKET_CONNECT':
        socket.connect(action.payload);
        break;
      case 'SOCKET_UPDATE':
        updateSocketStatus(action.payload);
        break;
      case 'SOCKET_DISCONNECT':
        socket.disconnect();
        clearSocket();
        break;
      case 'SOCKET_SEND_DATA':
        socket.sendData(JSON.stringify(action.payload));
        requestActions.addData(action.payload);
        break;
      default:
        break;
    }

    return next(action);
  };
};

export default socketMiddleware;
