import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { selectExperimentStatus } from 'store/taskSlice';

import { components } from '../../../generated/apiTypes';
import { setExperimentSettings } from '../../../services/apiRequests';
import useGetExperimentSettings from './hooks/use-get-experiment-settings';
import TaskSettingsInputRow from './TaskSettingInputRow';

type SettingsType = components['schemas']['ExperimentSettings'];

const PNASettings: React.FC = () => {
  const params = useParams() as { id: string };
  const settings = useGetExperimentSettings(params.id);
  const status = useSelector(selectExperimentStatus);
  const [settingsState, setSettingsState] = React.useState<SettingsType>();

  React.useEffect(() => {
    setSettingsState(settings);
  }, [settings]);

  const saveNewValue = (value: number, name: string) => {
    setExperimentSettings(params.id, {
      ...settingsState,
      [name]: value,
    });
    setSettingsState({
      ...(settingsState as SettingsType),
      [name]: value,
    });
  };

  return (
    <>
      <div className="project-task-settings__row">
        <TaskSettingsInputRow
          label="Длительность"
          value={settingsState?.period}
          unit="мин"
          saveNewValue={saveNewValue}
          name="period"
          disabled={[
            'DRAFT',
            'SENT',
            'PREPARING',
            'PREPARED',
            'SOLVING',
          ].includes(status)}
        />
        <TaskSettingsInputRow
          label="Шаг"
          value={settingsState?.time_step}
          unit="мин"
          saveNewValue={saveNewValue}
          name="time_step"
          disabled={[
            'DRAFT',
            'SENT',
            'PREPARING',
            'PREPARED',
            'SOLVING',
          ].includes(status)}
        />
      </div>
      <div className="project-task-settings__row">
        <TaskSettingsInputRow
          label="Точность расчетов"
          value={settingsState?.periodic_nodal_analysis_settings?.accuracy}
          unit="aтм"
          saveNewValue={saveNewValue}
          name="periodic_nodal_analysis_settings"
          subName="accuracy"
          disabled={[
            'DRAFT',
            'SENT',
            'PREPARING',
            'PREPARED',
            'SOLVING',
          ].includes(status)}
        />
      </div>
    </>
  );
};

export default PNASettings;
