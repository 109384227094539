import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';

import { components } from '../generated/apiTypes';
import { initialState } from './initialState';
import { RootState } from './store';
import { ITaskSlice } from './types';

const taskInitialState = initialState as ITaskSlice;

export const taskSlice = createSlice({
  name: 'task',
  initialState: {
    taskId: taskInitialState.taskId,
    experiment: taskInitialState.experiment,
    showResults: taskInitialState.showResults,
    solution: taskInitialState.solution,
  },
  reducers: {
    setExperimentType: (
      state,
      action: PayloadAction<ITaskSlice['experiment']['type']>,
    ) => {
      state.experiment.type = action.payload;
    },
    setExperimentMessage: (
      state,
      action: PayloadAction<ITaskSlice['experiment']['message']>,
    ) => {
      state.experiment.message = action.payload;
    },
    setExperimentStatus: (
      state,
      action: PayloadAction<ITaskSlice['experiment']['status']>,
    ) => {
      state.experiment.status = action.payload;
    },
    setShowResults: (
      state,
      action: PayloadAction<ITaskSlice['showResults']>,
    ) => {
      state.showResults = action.payload;
    },
    setSolution: (state, action: PayloadAction<ITaskSlice['solution']>) => {
      state.solution = action.payload;
    },
    setTaskId: (state, action: PayloadAction<ITaskSlice['taskId']>) => {
      state.taskId = action.payload;
    },
    setIntergalCharacteristics: (
      state,
      action: PayloadAction<
        components['schemas']['PNOPeriodicNodalAnalysisSolutionIntegralCharacteristic']
      >,
    ) => {
      if (state.solution.solution) {
        if (state.solution.solution?.solution?.integral_characteristic) {
          state.solution.solution = update(state.solution.solution, {
            solution: { integral_characteristic: { $set: action.payload } },
          });
        } else if (state.solution.solution.solution) {
          state.solution.solution.solution = update(
            state.solution.solution.solution,
            { integral_characteristic: { $set: action.payload } },
          );
        }
      } else {
        state.solution.solution = update(state.solution.solution as any, {
          $set: { solution: { integral_characteristic: action.payload } },
        });
      }
    },
    setAvgWellsTable: (
      state,
      action: PayloadAction<
        components['schemas']['PNOPeriodicNodalAnalysisWellAvgTable']
      >,
    ) => {
      if (state.solution.solution) {
        if (
          (state.solution.solution
            .solution as components['schemas']['PNOPeriodicNodalAnalysisSolution'])
            ?.well_avg_table
        ) {
          state.solution.solution = update(state.solution.solution, {
            solution: { well_avg_table: { $set: action.payload } },
          });
        } else if (state.solution.solution.solution) {
          state.solution.solution.solution = update(
            state.solution.solution.solution,
            { well_avg_table: { $set: action.payload } },
          );
        }
      } else {
        state.solution.solution = update(state.solution.solution as any, {
          $set: { solution: { well_avg_table: action.payload } },
        });
      }
    },
    resetTaskSliceState: state => {
      state.taskId = taskInitialState.taskId;
      state.experiment.status = taskInitialState.experiment.status;
      state.experiment.message = taskInitialState.experiment.message;
      state.showResults = taskInitialState.showResults;
      state.solution = taskInitialState.solution;
    },
  },
});

export const {
  setExperimentType,
  setExperimentMessage,
  setExperimentStatus,
  setShowResults,
  setSolution,
  setTaskId,
  setIntergalCharacteristics,
  setAvgWellsTable,
  resetTaskSliceState,
} = taskSlice.actions;

const selectSelf = (state: RootState) => state.task;

export const selectShowResults = createSelector(
  selectSelf,
  task => task.showResults,
);

export const selectExperimentType = createSelector(
  selectSelf,
  task => task.experiment.type,
);

export const selectExperimentStatus = createSelector(
  selectSelf,
  task => task.experiment.status,
);

export const selectExperimentMessage = createSelector(
  selectSelf,
  task => task.experiment.message,
);

export const selectTaskId = createSelector(selectSelf, task => task.taskId);

export const selectSolution = createSelector(selectSelf, task => task.solution);

export const selectAvgWellsTableUA = createSelector(
  selectSelf,
  task =>
    (task.solution
      ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'])
      ?.solution?.well_avg_table,
);

export const selectIntegralCharacteristics = createSelector(
  selectSelf,
  task => task.solution.solution?.solution.integral_characteristic,
);

export default taskSlice.reducer;
