import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import classNames from 'classnames';
import { selectSegments, setNodes, setSegments } from 'store/projectSlice';
import { selectSolution } from 'store/taskSlice';

import { components } from '../../../../generated/apiTypes';
import { ReactComponent as AspoIcon } from '../../../../images/Project/icn-aspo.svg';
import { ReactComponent as AppliedIcon } from '../../../../images/Project/icn-aspo-applied.svg';
import { ReactComponent as ProgressIcon } from '../../../../images/Project/progress-icon.svg';
import { applyAspo } from '../../../../services/apiRequests';

interface IAspoButton {
  taskId: string | null;
}

const AspoButton: React.FC<IAspoButton> = ({ taskId }) => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const segments = useSelector(selectSegments);
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNONetworkFactAdaptationProblem'];
  const [applyingAspo, setApplyingAspo] = React.useState<boolean>(false);
  const [aspoApplied, setAspoApplied] = React.useState<boolean>(false);
  const [differentAspos, setDifferentAspos] = React.useState<boolean>(false);

  React.useEffect(() => {
    const difference: boolean = segments.items.some(item => {
      return (
        item.aspo?.m !==
        solution?.solution.segment_table?.integral_segments_states.find(
          el => el.segment_uid === item.uid,
        )?.aspo?.m
      );
    });
    setDifferentAspos(difference);
  }, [segments.isFetching, solution]);

  const onApplyAspoClick = async () => {
    if (taskId) {
      try {
        setApplyingAspo(true);
        const response = await applyAspo(params.id, taskId);
        dispatch(setNodes(response?.data?.nodes));
        dispatch(setSegments(response?.data?.segments));
        setAspoApplied(true);
      } catch (e) {
        //
      }
      setApplyingAspo(false);
    }
  };
  const buttonByState = () => {
    if (applyingAspo)
      return (
        <>
          <ProgressIcon className="project-results-panel__progress-icon" />
          <p>Применение...</p>
        </>
      );
    if (aspoApplied)
      return (
        <>
          <AppliedIcon className="project-results-panel__applied-icon" />
          <p>АСПО применены</p>
        </>
      );
    return (
      <>
        <AspoIcon className="project-results-panel__aspo-icon" />
        <p>Применить АСПО</p>
      </>
    );
  };
  return (
    <button
      type="button"
      className={classNames('project-results-panel__aspo-btn', {
        applyingAspo,
      })}
      onClick={onApplyAspoClick}
      disabled={applyingAspo || !differentAspos || aspoApplied}
    >
      {buttonByState()}
    </button>
  );
};

export default AspoButton;
