import React from 'react';
import { useSelector } from 'react-redux';

import { selectSolution } from 'store/taskSlice';

import MOResults from './MO';
import NOResults from './NO';
import UAResults from './UA';

const SinkResults: React.FC = () => {
  const experimentType = useSelector(selectSolution)?.type;
  if (experimentType === 'Узловой анализ') return <UAResults />;
  if (experimentType === 'Оптимизация режима') return <MOResults />;
  if (experimentType === 'Оптимизация сети') return <NOResults />;
  return <></>;
};

export default SinkResults;
