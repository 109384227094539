import * as React from 'react';

import { components } from 'generated/apiTypes';
import { ReactComponent as ErrorIcon } from 'images/Project/error.svg';
import { ReactComponent as SuccessIcon } from 'images/Project/success.svg';
import { ReactComponent as WarningIcon } from 'images/Project/warning.svg';

interface IIssuesIcon {
  type?: components['schemas']['PNOProjectIssueCriticality'] | 'Success';
}

const IssuesIcon: React.FC<IIssuesIcon> = ({ type }) => {
  if (type === 'Error') {
    return <ErrorIcon />;
  }
  if (type === 'Warning') {
    return <WarningIcon />;
  }
  if (type === 'Success') {
    return <SuccessIcon />;
  }

  return null;
};

export default IssuesIcon;
