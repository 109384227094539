import * as React from 'react';

import classNames from 'classnames';

import Input from '../../../../Components/Input';

interface IStepInput {
  tabIndex: number;
  value: string;
  initStep: string;
  max: string;
  setStep(val: string): void;
}

const StepInput: React.FC<IStepInput> = ({
  tabIndex,
  value,
  setStep,
  initStep,
  max,
}) => {
  const [stateValue, setStateValue] = React.useState<string>(value);
  const onChangeStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateValue(e.target.value);
  };

  const saveValue = () => {
    const digitOnly = new RegExp('^([0-9]*)$', 'g');
    if (
      stateValue !== '0' &&
      stateValue.match(digitOnly) &&
      parseInt(stateValue, 10) <= parseInt(max, 10)
    ) {
      setStep(stateValue);
    } else {
      setStateValue(initStep);
      setStep(initStep);
    }
  };

  const onBlurHandler = () => {
    saveValue();
  };

  return (
    <div
      className={classNames(
        'results_tabs-step-row',
        tabIndex === 1 && 'visible',
      )}
    >
      <Input
        className={classNames('input results_tabs-step-input')}
        value={stateValue}
        onChange={onChangeStep}
        onBlur={onBlurHandler}
      />
      <p className="results_tabs-step-input-unit">шаг из {max}</p>
    </div>
  );
};

export default StepInput;
