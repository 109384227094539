import * as React from 'react';

import classnames from 'classnames';

import './style.scss';

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: 'primary' | 'secondary';
  type: 'button' | 'submit';
}

const Button: React.FC<IButton> = ({
  mode = 'primary',
  type = 'button',
  className,
  ...props
}) => {
  return (
    <button
      {...props}
      type={type === 'button' ? 'button' : 'submit'}
      className={classnames('main-button', className, mode)}
    >
      {props.children}
    </button>
  );
};

export default Button;
