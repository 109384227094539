import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectActiveObject } from 'store/mapSlice';
import { selectWellModelById } from 'store/projectSlice';

import Button from '../../../../../Components/Button';
import { components } from '../../../../../generated/apiTypes';
import { oneDigitsFormatter } from '../../../../../utils';
import { IViewModel } from './types';
import { getTableHeaderByType, getTableValueByType } from './utils';

const ViewModel: React.FC<IViewModel> = ({
  tableType,
  wellOperationType,
  editModel,
}) => {
  const wellsModelsById = useSelector(selectWellModelById);
  const activeObject = useSelector(selectActiveObject);
  const [wellModel, setWellModel] = React.useState<
    components['schemas']['PNOWellModel']
  >();

  React.useEffect(() => {
    setWellModel(wellsModelsById[activeObject.object_uid as string]);
  }, [activeObject.object_uid]);

  return (
    <>
      <div className="well-model-panel__tables-wrapper">
        <table className="well-model-panel__non-scrollable-table">
          <thead>
            <tr>
              <th
                rowSpan={2}
                colSpan={2}
                className="well-model-panel__table-cell well-model-panel__table-header-cell"
              >
                {' '}
              </th>
              <th
                colSpan={wellModel?.pressures.length}
                className="well-model-panel__table-cell well-model-panel__table-header-cell caption-cell"
              >
                P лин, атм
              </th>
            </tr>
            <tr>
              {wellModel?.pressures?.map(() => (
                <th className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell right-aligned">
                  {' '}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {wellModel?.control_actions?.map((item, control_action_index) => (
              <tr>
                {control_action_index === 0 && (
                  <td
                    rowSpan={wellModel.control_actions?.length}
                    className="well-model-panel__table-cell well-model-panel__table-header-cell vertical-text"
                  >
                    {getTableHeaderByType(wellOperationType)}
                  </td>
                )}
                <td className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell control-action-cell right-aligned">
                  {wellOperationType === 'Газлифт'
                    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      item?.m3_per_day &&
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      oneDigitsFormatter.format(item?.m3_per_day)
                    : item?.hertz && oneDigitsFormatter.format(item?.hertz)}
                </td>
                {wellModel.pressures.map(() => (
                  <td> </td>
                ))}
              </tr>
            ))}
            <tr>
              <td className="well-model-panel__table-bottom-header-cell"> </td>
              <td className="well-model-panel__table-bottom-header-cell"> </td>
              {wellModel?.pressures.map(() => (
                <td className="well-model-panel__table-bottom-cell"> </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="well-model-panel__scrollable-table-wrapper">
          <table className="well-model-panel__scrollable-table">
            <thead>
              <tr>
                {wellModel?.pressures?.map(item => (
                  <th className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell pressure-cell right-aligned">
                    {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      item?.atm && oneDigitsFormatter.format(item?.atm)
                    }
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {wellModel?.control_actions?.map((item, control_action_index) => (
                <tr>
                  {wellModel.pressures.map((val, pressure_index) => (
                    <td className="well-model-panel__table-cell value-cell pressure-cell">
                      {getTableValueByType(
                        tableType,
                        wellModel,
                        pressure_index,
                        control_action_index,
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="well-model-panel__buttons">
        <Button type="button" mode="secondary" onClick={editModel}>
          Редактировать
        </Button>
      </div>
    </>
  );
};

export default ViewModel;
