import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { selectSolution } from 'store/taskSlice';

import { components } from '../../../../../generated/apiTypes';
import TableHead from '../../components/TableHead';
import TableWrapper from '../../components/TableWrapper';
import { ISegmentsTableProps } from '../../types';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

const SegmentsAvgTable: React.FC<ISegmentsTableProps> = ({ tableHead }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechNetworkOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    solution?.solution?.segment_avg_table?.segments
      ?.filter(
        (item, index, arr) => arr.findIndex(e => item.uid === e.uid) === index,
      )
      .forEach(segment => {
        const stateById = solution?.solution?.segment_avg_table?.segment_states.find(
          val => val.segment_uid === segment.uid,
        );
        newData.push({
          looping: solution.solution.looping_segments.some(
            val => val.uid === segment.uid,
          ),
          start_node_name: segment?.start_node_name,
          end_node_name: segment?.end_node_name,
          length: segment?.length?.m,
          diameter: segment?.diameter?.m,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          p_in: stateById?.pressure_in?.atm,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dp_dl: stateById?.dp_dl?.atm_per_km,
          dt_dl:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            stateById?.dt_dl?.celsius_per_km,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          oil_flow_rate: stateById?.oil_flow_rate_mass?.t_per_day,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fluid_flow_rate: stateById?.fluid_flow_rate_volume?.m3_per_day,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          gas_flow_rate: stateById?.gas_flow_rate_volume?.thousand_m3_per_day,
          fluid_velocity: stateById?.fluid_velocity?.m_per_second,
          gas_velocity: stateById?.gas_velocity?.m_per_second,
        });
      });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__segments-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return (
                  <TableRow
                    key={index}
                    headKeys={headKeys}
                    el={value}
                    className={value?.looping && 'looping'}
                  />
                );
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SegmentsAvgTable;
