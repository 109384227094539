import { components } from 'generated/apiTypes';

import { SelectWellById } from '../../store/types';
import { ExperimentStatus, ExperimentType, Node } from './types';

export const composeObjectsById = <
  T extends {
    uid?: string;
    well_id?: string;
    node_uid?: string;
    node_id?: string;
    gc_uid?: string;
  }
>(
  acc: { [key: string]: T },
  cur: T,
) => {
  if (cur.uid) {
    acc[cur.uid] = cur;
  }
  if (cur.well_id) {
    acc[cur.well_id] = cur;
  }
  if (cur.node_uid) {
    acc[cur.node_uid] = cur;
  }
  if (cur.node_id) {
    acc[cur.node_id] = cur;
  }
  if (cur.gc_uid) {
    acc[cur.gc_uid] = cur;
  }
  return acc;
};

export const isNodeGuard = (
  node: Node | undefined,
): node is Node & { lat: number; lon: number } =>
  typeof node?.coordinates?.[0] === 'number' &&
  typeof node?.coordinates?.[1] === 'number';

/**
 * Отфилтровать кустовые площадки
 * @param  {Node} node
 * @returns sources
 */
export const isSourceGuard = (
  node: Node,
): node is Omit<Node, 'type'> & { key: 'SOURCE' } => node.type === 'SOURCE';

/**
 * Отфилтровать соединения
 * @param  {Node} node
 * @returns junctions
 */
export const isJunctionGuard = (
  node: Node,
): node is Omit<Node, 'type'> & { key: 'JUNCTION' } => node.type === 'JUNCTION';

/**
 * Отфилтровать центры сбора
 * @param  {Node} node
 * @returns sinks
 */
export const isSinkGuard = (
  node: Node,
): node is Omit<Node, 'type'> & { key: 'SINK' } => node.type === 'SINK';

type WellState = components['schemas']['PNOWellCurrentState'];
/**
 * Отфильтровать остановленные скважины
 * @param  {SelectWellById} wellsById
 * @param wells
 * @returns nodes
 */
export const isStoppedGuard = (
  wellsById: SelectWellById,
  wells: components['schemas']['PNOWell'][],
) => (state: WellState) => (node: Node) =>
  node.uid &&
  wellsById[wells.find(item => item.node_uid === node.uid)?.uid as string]
    ?.current_state === state;

export const setGeojsonLine = (
  lineProps: {
    coords: number[][];
    props: { [key: string]: string | undefined };
  }[],
) => {
  const features = lineProps.map(({ coords, props }, i: any) => ({
    id: i,
    type: 'Feature',
    properties: props,
    geometry: {
      type: 'LineString',
      coordinates: coords,
    },
  }));

  return {
    type: 'FeatureCollection',
    features,
  };
};

export const messageByStatus = (value: ExperimentStatus) => {
  switch (value) {
    case 'DRAFT':
      return 'В очереди';
    case 'SENT':
      return 'Отправлена на расчёт';
    case 'PREPARING':
      return 'Подготовка';
    case 'PREPARED':
      return 'Подготовка завершена';
    case 'SOLVING':
      return 'Идёт расчёт';
    case 'INTERRUPTING':
      return 'Остановка расчёта';
    case 'FAILED' && 'FAILED_VIEWED':
      return 'Ошибка';
    case 'VALIDATION_ERROR' && 'VALIDATION_ERROR_VIEWED':
      return 'Ошибка валидации';
    default:
      return '';
  }
};

export const taskByExperimentType = (value: ExperimentType) => {
  switch (value) {
    case 'Узловой анализ':
      return 'NODAL_ANALYSIS';
    case 'Гидравлический расчет':
      return 'HYDRO';
    case 'Адаптация на факт':
      return 'NETWORK_FACT_ADAPTATION';
    case 'Оптимизация режима':
      return 'TECH_MODE_OPTIMIZATION';
    case 'Оптимизация сети':
      return 'TECH_NETWORK_OPTIMIZATION';
    default:
      return '';
  }
};
