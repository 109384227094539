import * as React from 'react';

import classNames from 'classnames';

import Input from '../../../../Components/Input';

interface IObjectInput {
  saveNewValue(value: string, name: string): void;
  label: string;
  name: string;
  initValue: string | undefined;
  className: string;
  disabled?: boolean;
}

const ObjectStringInput: React.FC<IObjectInput> = ({
  label,
  name,
  initValue,
  saveNewValue,
  className,
  disabled,
}) => {
  const [value, setValue] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (typeof initValue !== 'undefined') setValue(initValue);
  }, [initValue]);

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    saveNewValue(value as string, name);
  };

  return (
    <Input
      className={classNames(className, disabled && 'non-editable')}
      label={label}
      value={value}
      onChange={changeValue}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

export default ObjectStringInput;
