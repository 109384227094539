import * as React from 'react';

export const templateRowsNumber = 5;

export const nodesTableHead = {
  name: 'Узел',
  pressure_fact: 'P, атм',
  temperature: 't смеси, ˚C',
  oil_flow_rate: {
    title: 'Qн, т/сут',
    component: <>Qн, т/сут</>,
  },
  fluid_flow_rate: {
    title: 'Qж, м3/сут',
    component: (
      <>
        Qж,{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Qг, тыс. м3/сут',
    component: (
      <>
        Qг, тыс.
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
};
