import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { selectExperimentStatus } from 'store/taskSlice';

import { ReactComponent as CancelTaskIcon } from '../../../images/Project/icn-cancel-task.svg';
import { ReactComponent as ErrorIcon } from '../../../images/Project/icn-error.svg';
import { ReactComponent as ProgressIcon } from '../../../images/Project/progress-icon.svg';
import { setShowDialog } from '../../../store/commonSlice';
import { messageByStatus } from '../utils';

interface ITaskStatus {
  className?: string;
}

const TaskStatus: React.FC<ITaskStatus> = ({ className }) => {
  const dispatch = useDispatch();
  const status = useSelector(selectExperimentStatus);

  const onCancelClick = () => {
    dispatch(
      setShowDialog({
        dialogType: 'CANCEL_TASK_CONFIRM',
      }),
    );
  };

  return (
    <div
      className={classNames(
        'control-panel__progress-details',
        className,
        status === 'INTERRUPTING' && 'without-stop-btn',
      )}
    >
      <div className="control-panel__progress-details-message">
        {messageByStatus(status)}
      </div>
      {[
        'FAILED',
        'FAILED_VIEWED',
        'VALIDATION_ERROR',
        'VALIDATION_ERROR_VIEWED',
      ].includes(status) ? (
        <ErrorIcon className="control-panel__details-button-error-icon" />
      ) : (
        <>
          <ProgressIcon className="control-panel__details-button-progress-icon" />
          {status !== 'INTERRUPTING' && (
            <CancelTaskIcon
              className="control-panel__details-button-cancel-icon"
              onClick={onCancelClick}
            />
          )}
        </>
      )}
    </div>
  );
};
export default TaskStatus;
