import * as React from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { selectExperimentMessage } from 'store/taskSlice';

import { ReactComponent as ArrowIcon } from '../../../../images/Project/icn-extend-arrow.svg';

const ErrorDetails: React.FC = () => {
  const experimentMessage = useSelector(selectExperimentMessage);
  const [extended, setExtended] = React.useState<boolean>(false);

  return (
    <>
      <button
        className={classNames('project-results-panel__message-details', {
          extended,
        })}
        type="button"
        onClick={() => setExtended(!extended)}
      >
        <ArrowIcon className="project-results-panel__arrow-icon" />
        Дополнительная информация
      </button>
      {extended && (
        <div className="project-results-panel__message-details-text">
          {experimentMessage}
        </div>
      )}
    </>
  );
};

export default ErrorDetails;
