import * as React from 'react';

import classNames from 'classnames';

import { components } from '../../../../generated/apiTypes';
import ObjectInput from '../components/ObjectInput';

interface ISinkView {
  gatheringCentersModel:
    | components['schemas']['PNOGatheringCenterModel']
    | undefined;
  saveNewGatheringCentersModelValue(value: number | string, name: string): void;
}

const SinkView: React.FC<ISinkView> = ({
  gatheringCentersModel,
  saveNewGatheringCentersModelValue,
}) => {
  return (
    <div className="active-object__row">
      <label className="input-label">Давление на входе</label>
      <div className="d-flex">
        <div className="active-object__input-with-unit-wrapper short">
          <ObjectInput
            className={classNames(
              'input active-object__row-input short with-unit',
            )}
            label=""
            name="const_pressure"
            unit="atm"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            initValue={gatheringCentersModel?.const_pressure.atm}
            saveNewValue={saveNewGatheringCentersModelValue}
          />
          <p className="active-object__row-input-unit">атм</p>
        </div>
      </div>
    </div>
  );
};

export default SinkView;
