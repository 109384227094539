import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectExperimentStatus, selectExperimentType } from 'store/taskSlice';

import Button from '../../../Components/Button';
import { setShowDialog } from '../../../store/commonSlice';
import { ITaskSettings } from '../types';
import AFSettings from './AFSettings';
import GRSettings from './GRSettings';
import MOSettings from './MOSettings';
import NOSettings from './NOSettings';
import PNASettings from './PNASettings';

const TaskSettings: React.FC<ITaskSettings> = ({ calcTask }) => {
  const dispatch = useDispatch();
  const type = useSelector(selectExperimentType);
  const status = useSelector(selectExperimentStatus);

  const onCancelTaskClick = () => {
    dispatch(
      setShowDialog({
        dialogType: 'CANCEL_TASK_CONFIRM',
      }),
    );
  };
  return (
    <>
      <div>
        <p className="project-task-settings__title">Настройки эксперимента</p>
        {type === 'Узловой анализ' && <PNASettings />}
        {type === 'Адаптация на факт' && <AFSettings />}
        {type === 'Гидравлический расчет' && <GRSettings />}
        {type === 'Оптимизация режима' && <MOSettings />}
        {type === 'Оптимизация сети' && <NOSettings />}
      </div>
      <div>
        <Button
          type="submit"
          onClick={() => calcTask()}
          disabled={[
            'DRAFT',
            'SENT',
            'PREPARING',
            'PREPARED',
            'SOLVING',
            'INTERRUPTING',
          ].includes(status)}
        >
          {[
            'DRAFT',
            'SENT',
            'PREPARING',
            'PREPARED',
            'SOLVING',
            'INTERRUPTING',
          ].includes(status)
            ? 'Идет расчёт…'
            : 'Запустить расчёт'}
        </Button>
        {['DRAFT', 'SENT', 'PREPARING', 'PREPARED', 'SOLVING'].includes(
          status,
        ) && (
          <Button
            type="button"
            mode="secondary"
            onClick={onCancelTaskClick}
            className="project-task-settings__cancel-btn"
          >
            Остановить расчёт
          </Button>
        )}
      </div>
    </>
  );
};

export default TaskSettings;
