import React, { useContext } from 'react';
import { MapContext } from 'react-mapbox-gl';

import { ReactComponent as Pan } from 'images/Project/moveToSelection.svg';
import { ReactComponent as ZoomIn } from 'images/Project/zoom_in.svg';
import { ReactComponent as ZoomOut } from 'images/Project/zoom_out.svg';

import './style.scss';

function ZoomButtons(props: any) {
  const mapContext = useContext(MapContext);

  const onZoomHandler = (type: any) => {
    const zoom = mapContext?.getZoom();
    const center = mapContext?.getCenter();

    if (zoom === undefined || center === undefined) return;

    const newZoom = type === 'zoomIn' ? zoom + 1 : zoom - 1;
    mapContext?.flyTo({ center, zoom: newZoom });
  };

  const flyTo = () =>
    mapContext?.flyTo({ center: props.center, zoom: 14, essential: true });

  return (
    <div className="zoom_buttons_container">
      <div className="zoom_buttons_block">
        <button
          type="button"
          className="zoomIn_button"
          onClick={() => onZoomHandler('zoomIn')}
        >
          <ZoomIn />
        </button>
        <div className="zoom_bottons_divider" />
        <button
          type="button"
          className="zoomOut_button"
          onClick={() => onZoomHandler('zoomOut')}
        >
          <ZoomOut />
        </button>
      </div>
      <div className="pan_button_block">
        <button type="button" className="pan_button" onClick={flyTo}>
          <Pan />
        </button>
      </div>
    </div>
  );
}

export default ZoomButtons;
