import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import classNames from 'classnames';

import { ReactComponent as DeleteIcon } from '../../images/Project/icn-delete-project.svg';
import { ReactComponent as PointsIcon } from '../../images/Projects/icn-points.svg';
import { setShowDialog } from '../../store/commonSlice';
import { shortenTitle } from '../../utils';
import MenuList from '../Project/ProjectHeader/menuList';
import { ProjectRowPropsType } from './types';

import './style.scss';

const maxProjectNameLength = 50;

const ProjectRow: React.FC<ProjectRowPropsType> = ({
  history,
  id,
  name,
  date,
}) => {
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const controlRef = React.useRef<HTMLButtonElement>(null);
  const onRowClick = () => {
    history.push(`/project/${id}`);
  };
  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };
  const closeMenu = () => {
    setActiveMenu(false);
  };
  const onMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleMenu();
  };
  const onDeleteProjectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      setShowDialog({
        dialogType: 'DELETE_CONFIRM',
        objectType: 'PROJECT',
        objectName: shortenTitle(name, maxProjectNameLength),
        objectId: id,
      }),
    );
    closeMenu();
  };

  return (
    <button className="project-row" type="button" onClick={onRowClick}>
      <div className="project-row-name">
        {shortenTitle(name, maxProjectNameLength)}
      </div>
      <div className="project-row-date">Изменен {date}</div>
      <button
        className={classNames(
          'project-row__menu-button',
          activeMenu && 'active',
        )}
        type="button"
        ref={controlRef}
        onClick={onMenuClick}
      >
        <PointsIcon className="project-row__points-icon" />
      </button>
      {activeMenu && (
        <MenuList
          closeMenu={closeMenu}
          controlRef={controlRef}
          className="project-row__menu-list"
        >
          <button
            type="button"
            className="project-header__menu-list-item"
            onClick={onDeleteProjectClick}
          >
            <div className="project-header__menu-list-item-delete">
              <DeleteIcon className="project-header__icon-delete" />
              Удалить проект
            </div>
          </button>
        </MenuList>
      )}
    </button>
  );
};

export default withRouter(ProjectRow);
