import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { components } from 'generated/apiTypes';
import update from 'immutability-helper';
import { RootState } from 'store/store';

import { composeObjectsById } from '../Pages/Project/utils';
import { initialState } from './initialState';
import {
  IProjectSlice,
  SelectGatheringCentersFactPressuresById,
  SelectGatheringCentersModelById,
  SelectNodeById,
  SelectPhysChemPackageById,
  SelectSegmentById,
  SelectWellById,
  SelectWellControlModelById,
  SelectWellFactMeasurementById,
  SelectWellHydroModelById,
  SelectWellModelById,
} from './types';

const projectInitialState = initialState as IProjectSlice;

export const projectSlice = createSlice({
  name: 'project',
  initialState: {
    id: projectInitialState.id,
    name: projectInitialState.name,
    issuesState: projectInitialState.issuesState,
    showDetailsPanel: projectInitialState.showDetailsPanel,
    nodes: projectInitialState.nodes,
    wells: projectInitialState.wells,
    wellsModels: projectInitialState.wellsModels,
    wellsControlModels: projectInitialState.wellsControlModels,
    wellsHydroModels: projectInitialState.wellsHydroModels,
    wellsFactMeasurements: projectInitialState.wellsFactMeasurements,
    gatheringCentersModels: projectInitialState.gatheringCentersModels,
    gatheringCentersFactPressures:
      projectInitialState.gatheringCentersFactPressures,
    segments: projectInitialState.segments,
    physChemPackages: projectInitialState.physChemPackages,
    pipesCatalog: projectInitialState.pipesCatalog,
  },
  reducers: {
    toggleIssues: (
      state,
      action: PayloadAction<IProjectSlice['issuesState']['show']>,
    ) => {
      if (typeof action.payload === 'boolean') {
        state.issuesState.show = action.payload;
      } else {
        state.issuesState.show = !state.issuesState.show;
      }
    },
    setIssues: (
      state,
      action: PayloadAction<IProjectSlice['issuesState']['issues']>,
    ) => {
      state.issuesState.issues = action.payload;
    },
    setShowDetailsPanel: (
      state,
      action: PayloadAction<IProjectSlice['showDetailsPanel']>,
    ) => {
      state.showDetailsPanel = action.payload;
    },
    setNodes: (
      state,
      action: PayloadAction<IProjectSlice['nodes']['items']>,
    ) => {
      state.nodes = {
        items: [...action.payload],
        isFetching: true,
        isUpdate: false,
      };
    },
    updateNodes: (
      state,
      action: PayloadAction<components['schemas']['PNOPipelineNode']>,
    ) => {
      const index = state.nodes.items.findIndex(
        ({ uid }) => action.payload.uid === uid,
      );
      state.nodes.items = update(state.nodes.items, {
        [index]: { $set: action.payload },
      });
    },
    updateNodesNetwork: (
      state,
      action: PayloadAction<IProjectSlice['nodes']['isUpdate']>,
    ) => {
      state.nodes = {
        ...state.nodes,
        isUpdate: action.payload,
      };
    },
    setWells: (
      state,
      action: PayloadAction<IProjectSlice['wells']['items']>,
    ) => {
      state.wells = {
        items: action.payload,
        isFetching: true,
      };
    },
    updateWell: (
      state,
      action: PayloadAction<components['schemas']['PNOWell']>,
    ) => {
      const index = state.wells.items.findIndex(
        ({ uid }) => action.payload.node_uid === uid,
      );
      state.wells.items = update(state.wells.items, {
        [index]: { $set: action.payload },
      });
    },
    setWellsModels: (
      state,
      action: PayloadAction<IProjectSlice['wellsModels']['items']>,
    ) => {
      state.wellsModels.items = action.payload;
    },
    updateWellModal: (
      state,
      action: PayloadAction<components['schemas']['PNOWellModel']>,
    ) => {
      const index = state.wellsModels.items.findIndex(
        ({ well_id }) => action.payload.well_id === well_id,
      );
      state.wellsModels.items = update(state.wellsModels.items, {
        [index]: { $set: action.payload },
      });
    },
    addUpdatedModel: (state, action: PayloadAction<string>) => {
      state.wellsModels.updatedModels = update(
        state.wellsModels.updatedModels,
        { $push: [action.payload] },
      );
    },
    removeUpdatedModel: (state, action: PayloadAction<string>) => {
      const index = state.wellsModels.updatedModels.findIndex(
        item => action.payload === item,
      );
      state.wellsModels.updatedModels = update(
        state.wellsModels.updatedModels,
        { $splice: [[index, 1]] },
      );
    },
    setWellsControlModels: (
      state,
      action: PayloadAction<IProjectSlice['wellsControlModels']>,
    ) => {
      state.wellsControlModels = action.payload;
    },
    updateWellsControlModel: (
      state,
      action: PayloadAction<components['schemas']['PNOWellControlModel']>,
    ) => {
      const index = state.wellsControlModels.findIndex(
        ({ well_id }) => action.payload.well_id === well_id,
      );
      state.wellsControlModels = update(state.wellsControlModels, {
        [index]: { $set: action.payload },
      });
    },
    setWellsHydroModels: (
      state,
      action: PayloadAction<IProjectSlice['wellsHydroModels']>,
    ) => {
      state.wellsHydroModels = action.payload;
    },
    updateWellsHydroModel: (
      state,
      action: PayloadAction<components['schemas']['PNOWellHydroModel']>,
    ) => {
      const index = state.wellsHydroModels.findIndex(
        ({ well_id }) => action.payload.well_id === well_id,
      );
      state.wellsHydroModels = update(state.wellsHydroModels, {
        [index]: { $set: action.payload },
      });
    },
    setWellsFactMeasurements: (
      state,
      action: PayloadAction<IProjectSlice['wellsFactMeasurements']>,
    ) => {
      state.wellsFactMeasurements = action.payload;
    },
    updateWellsFactMeasurement: (
      state,
      action: PayloadAction<components['schemas']['PNOWellFactMeasurement']>,
    ) => {
      const index = state.wellsFactMeasurements.findIndex(
        ({ well_id }) => action.payload.well_id === well_id,
      );
      state.wellsFactMeasurements = update(state.wellsFactMeasurements, {
        [index]: { $set: action.payload },
      });
    },
    setGatheringCentersModels: (
      state,
      action: PayloadAction<IProjectSlice['gatheringCentersModels']>,
    ) => {
      state.gatheringCentersModels = action.payload;
    },
    updateGatheringCentersModel: (
      state,
      action: PayloadAction<components['schemas']['PNOGatheringCenterModel']>,
    ) => {
      const index = state.gatheringCentersModels.findIndex(
        ({ node_id }) => action.payload.node_id === node_id,
      );
      state.gatheringCentersModels = update(state.gatheringCentersModels, {
        [index]: { $set: action.payload },
      });
    },
    setSegments: (
      state,
      action: PayloadAction<IProjectSlice['segments']['items']>,
    ) => {
      state.segments = {
        items: [...action.payload],
        isFetching: true,
        isUpdate: false,
      };
    },
    updateSegments: (
      state,
      action: PayloadAction<components['schemas']['PNOPipelineSegment']>,
    ) => {
      const index = state.segments.items.findIndex(
        ({ uid }) => action.payload.uid === uid,
      );
      state.segments.items = update(state.segments.items, {
        [index]: { $set: action.payload },
      });
    },
    updateSegmentsNetwork: (
      state,
      action: PayloadAction<IProjectSlice['segments']['isUpdate']>,
    ) => {
      state.segments = {
        ...state.segments,
        isUpdate: action.payload,
      };
    },
    setPhysChemPackages: (
      state,
      action: PayloadAction<IProjectSlice['physChemPackages']>,
    ) => {
      state.physChemPackages = action.payload;
    },
    updatePhysChemPackages: (
      state,
      action: PayloadAction<components['schemas']['PNOPhysChemPackage']>,
    ) => {
      const index = state.physChemPackages.findIndex(
        ({ uid }) => action.payload.uid === uid,
      );
      state.physChemPackages = update(state.physChemPackages, {
        [index]: { $set: action.payload },
      });
    },
    setGatheringCentersFactPressures: (
      state,
      action: PayloadAction<IProjectSlice['gatheringCentersFactPressures']>,
    ) => {
      state.gatheringCentersFactPressures = action.payload;
    },
    updateGatheringCentersFactPressures: (
      state,
      action: PayloadAction<
        components['schemas']['PNOGatheringCenterFactPressure']
      >,
    ) => {
      const index = state.gatheringCentersFactPressures.findIndex(
        ({ gc_uid }) => action.payload.gc_uid === gc_uid,
      );
      state.gatheringCentersFactPressures = update(
        state.gatheringCentersFactPressures,
        {
          [index]: { $set: action.payload },
        },
      );
    },
    setProjectId: (state, action: PayloadAction<IProjectSlice['id']>) => {
      state.id = action.payload;
    },
    setProjectName: (state, action: PayloadAction<IProjectSlice['name']>) => {
      state.name = action.payload;
    },
    setPipesCatalog: (
      state,
      action: PayloadAction<IProjectSlice['pipesCatalog']>,
    ) => {
      state.pipesCatalog = action.payload;
    },
    resetProjectSliceState: state => {
      state.name = projectInitialState.name;
      state.issuesState = projectInitialState.issuesState;
      state.showDetailsPanel = projectInitialState.showDetailsPanel;
      state.nodes = projectInitialState.nodes;
      state.wells = projectInitialState.wells;
      state.wellsModels = projectInitialState.wellsModels;
      state.wellsControlModels = projectInitialState.wellsControlModels;
      state.wellsHydroModels = projectInitialState.wellsHydroModels;
      state.wellsFactMeasurements = projectInitialState.wellsFactMeasurements;
      state.gatheringCentersModels = projectInitialState.gatheringCentersModels;
      state.gatheringCentersFactPressures =
        projectInitialState.gatheringCentersFactPressures;
      state.segments = projectInitialState.segments;
      state.physChemPackages = projectInitialState.physChemPackages;
      state.pipesCatalog = projectInitialState.pipesCatalog;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNodes,
  setWells,
  setWellsModels,
  setWellsControlModels,
  setWellsHydroModels,
  setWellsFactMeasurements,
  setGatheringCentersModels,
  setSegments,
  toggleIssues,
  setIssues,
  setPhysChemPackages,
  setGatheringCentersFactPressures,
  setProjectName,
  setProjectId,
  updateGatheringCentersModel,
  updateNodes,
  updateWell,
  updateWellModal,
  updateWellsControlModel,
  updateWellsHydroModel,
  updateWellsFactMeasurement,
  updateSegments,
  updatePhysChemPackages,
  updateGatheringCentersFactPressures,
  resetProjectSliceState,
  setShowDetailsPanel,
  setPipesCatalog,
  updateNodesNetwork,
  updateSegmentsNetwork,
  addUpdatedModel,
  removeUpdatedModel,
} = projectSlice.actions;

const selectSelf = (state: RootState) => state.project;

export const selectShowErrors = createSelector(
  selectSelf,
  project => project.issuesState.show,
);

export const selectErrors = createSelector(
  selectSelf,
  project => project.issuesState.issues,
);

export const selectShowDetailsPanel = createSelector(
  selectSelf,
  project => project.showDetailsPanel,
);

export const selectProject = createSelector(selectSelf, project => project);

export const selectProjectName = createSelector(
  selectSelf,
  project => project.name,
);

export const selectProjectId = createSelector(
  selectSelf,
  project => project.id,
);

export const selectSegments = createSelector(
  selectSelf,
  project => project.segments,
);

export const selectSegmentById = createSelector(selectSelf, project =>
  project.segments.items.reduce<SelectSegmentById>(composeObjectsById, {}),
);
export const selectNodes = createSelector(selectSelf, project => project.nodes);

export const selectNodeById = createSelector(selectSelf, project =>
  project.nodes.items.reduce<SelectNodeById>(composeObjectsById, {}),
);

export const selectWellById = createSelector(selectSelf, project =>
  project.wells.items.reduce<SelectWellById>(composeObjectsById, {}),
);

export const selectWellControlModelById = createSelector(selectSelf, project =>
  project.wellsControlModels.reduce<SelectWellControlModelById>(
    composeObjectsById,
    {},
  ),
);

export const selectWellHydroModelById = createSelector(selectSelf, project =>
  project.wellsHydroModels.reduce<SelectWellHydroModelById>(
    composeObjectsById,
    {},
  ),
);

export const selectWellFactMeasurementById = createSelector(
  selectSelf,
  project =>
    project.wellsFactMeasurements.reduce<SelectWellFactMeasurementById>(
      composeObjectsById,
      {},
    ),
);

export const selectWells = createSelector(selectSelf, project => project.wells);

export const selectWellModelById = createSelector(selectSelf, project =>
  project.wellsModels.items.reduce<SelectWellModelById>(composeObjectsById, {}),
);

export const selectUpdatedModel = createSelector(
  selectSelf,
  project => project.wellsModels.updatedModels,
);

export const selectPhysChemPackages = createSelector(
  selectSelf,
  project => project.physChemPackages,
);

export const selectPhysChemPackagesById = createSelector(selectSelf, project =>
  project.physChemPackages.reduce<SelectPhysChemPackageById>(
    composeObjectsById,
    {},
  ),
);

export const selectGatheringCentersModelById = createSelector(
  selectSelf,
  project =>
    project.gatheringCentersModels.reduce<SelectGatheringCentersModelById>(
      composeObjectsById,
      {},
    ),
);

export const selectGatheringCentersFactPressuresById = createSelector(
  selectSelf,
  project =>
    project.gatheringCentersFactPressures.reduce<SelectGatheringCentersFactPressuresById>(
      composeObjectsById,
      {},
    ),
);

export const selectPipesCatalog = createSelector(
  selectSelf,
  project => project.pipesCatalog,
);

export default projectSlice.reducer;
