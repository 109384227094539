import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { selectSolution } from 'store/taskSlice';

import { components } from '../../../../../../generated/apiTypes';
import TableWrapper from '../../../components/TableWrapper';
import { INodesTableProps } from '../../../types';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

const NodesByStepTable: React.FC<INodesTableProps> = ({
  tableHead,
  step = 1,
}) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOPeriodicNodalAnalysisProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.node_step_table?.nodes.forEach(node => {
      const nodeStateByNodeId =
        solution?.solution?.node_step_table?.time2states &&
        Object.values(solution?.solution?.node_step_table?.time2states)
          .find((val, index) => index === step - 1)
          ?.find(node_state => node_state.node_uid === node.uid);
      newData.push({
        name: node.name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: nodeStateByNodeId?.fluid_flow_rate_volume?.m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: nodeStateByNodeId?.oil_flow_rate_mass?.t_per_day,
        gas_flow_rate:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          nodeStateByNodeId?.gas_flow_rate_volume?.thousand_m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        temperature: nodeStateByNodeId?.temperature?.celsius,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pressure: nodeStateByNodeId?.pressure?.atm,
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, step]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__nodes-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default NodesByStepTable;
