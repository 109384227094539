import React from 'react';
import ScrollArea from 'react-scrollbar/dist/no-css';

import classnames from 'classnames';

import { ITableWrapperProps } from '../types';

const TableWrapper: React.FC<ITableWrapperProps> = ({
  vertical,
  horizontal,
  smoothScrolling,
  className,
  contentClassName,
  children,
}) => {
  const [verticalScroll, setVerticalScroll] = React.useState<boolean>(false);
  const [verticallyScrolled, setVerticallyScrolled] = React.useState<boolean>(
    false,
  );
  const onScroll = (value: any) => {
    if (verticalScroll !== value?.realHeight > value?.containerHeight)
      setVerticalScroll(value?.realHeight > value?.containerHeight);
    if (verticallyScrolled !== value.topPosition > 0)
      setVerticallyScrolled(value.topPosition > 0);
  };
  return (
    <ScrollArea
      vertical={vertical}
      horizontal={horizontal}
      smoothScrolling={smoothScrolling}
      className={classnames(
        className,
        verticalScroll && 'vertical-scroll',
        verticallyScrolled && 'scrolled',
      )}
      onScroll={onScroll}
      contentClassName={contentClassName}
    >
      {children}
    </ScrollArea>
  );
};

export default TableWrapper;
