import * as React from 'react';

import classNames from 'classnames';
import { components } from 'generated/apiTypes';

type StateType = components['schemas']['PNOWellCurrentState'];

interface IWellState {
  saveNewValue(value: number | string, name: string): void;
  state: StateType | undefined;
}

const SourceState: React.FC<IWellState> = ({ saveNewValue, state }) => {
  const [value, setValue] = React.useState<StateType>();

  React.useEffect(() => {
    if (state) setValue(state);
  }, [state]);

  const changeValue = () => {
    if (value === 'Работает') {
      setValue('Остановлена');
      saveNewValue('Остановлена', 'current_state');
    } else {
      setValue('Работает');
      saveNewValue('Работает', 'current_state');
    }
  };

  return (
    <div className="source-state__container">
      <p className="active-object__title">Скважина</p>
      <button
        className={classNames(
          'active-object__status',
          value === 'Работает' && 'progress',
        )}
        type="button"
        onClick={changeValue}
      >
        {value === 'Работает' ? 'Работает' : 'Остановлена'}
      </button>
    </div>
  );
};

export default SourceState;
