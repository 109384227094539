import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';

import { selectAvgWellsTableUA } from '../../../../../../store/taskSlice';
import TableHead from '../../../components/TableHead';
import TableWrapper from '../../../components/TableWrapper';
import { ISourcesTableProps } from '../../../types';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

const SourcesAvgTable: React.FC<ISourcesTableProps> = ({
  tableHead,
  wellsControlModels,
}) => {
  const avgWellsTable = useSelector(selectAvgWellsTableUA);
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    avgWellsTable?.wells.forEach(well => {
      const stateById = avgWellsTable?.well_states.find(
        val => val.well_id === well.uid,
      );
      newData.push({
        name: well?.name,
        operation_type: well?.operation_type,
        current_state: well?.current_state,
        control_type: wellsControlModels?.find(val => val.well_id === well.uid)
          ?.control_type,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: stateById?.fluid_flow_rate?.t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: stateById?.oil_flow_rate?.t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gas_flow_rate: stateById?.gas_flow_rate?.thousand_m3_per_day,
        frequency: well?.operation_type === 'ЭЦН' && stateById?.control?.hertz,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        temperature: stateById?.temperature?.celsius,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pressure: stateById?.pressure?.atm,
        consumption:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          well?.operation_type === 'Газлифт' && stateById?.control?.m3_per_day,
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [avgWellsTable]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__sources-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return (
                  <TableRow
                    key={index}
                    headKeys={headKeys}
                    el={value}
                    status={value.current_state}
                  />
                );
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SourcesAvgTable;
