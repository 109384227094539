import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import {
  selectExperimentStatus,
  selectExperimentType,
  setExperimentStatus,
  setExperimentType as saveExperimentType,
  setSolution,
} from 'store/taskSlice';

import { ReactComponent as DropdownArrow } from '../../../images/Project/dropdown-arrow.svg';
import { ExperimentType } from '../types';
import MenuList from './menuList';

const ExperimentSelect: React.FC = () => {
  const dispatch = useDispatch();
  const type = useSelector(selectExperimentType);
  const status = useSelector(selectExperimentStatus);
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const experimentTypes: ExperimentType[] = [
    'Гидравлический расчет',
    'Адаптация на факт',
    'Узловой анализ',
    'Оптимизация режима',
    'Оптимизация сети',
  ];
  const controlRef = React.useRef<HTMLButtonElement>(null);
  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };
  const closeMenu = () => {
    setActiveMenu(false);
  };
  const setExperimentType = (val: ExperimentType) => {
    if (type !== val) {
      dispatch(setExperimentStatus('INITIAL'));
      dispatch(saveExperimentType(val));
      dispatch(setSolution({ type: 'Узловой анализ', solution: null }));
    }
    toggleMenu();
  };

  return (
    <div className="project-header__experiment-select-wrapper">
      <button
        className={classNames(
          'project-header__experiment-select-btn',
          activeMenu && 'active',
        )}
        type="button"
        ref={controlRef}
        onClick={toggleMenu}
      >
        {type}
        <DropdownArrow
          className={classNames(
            'project-header__experiment-select-btn-arrow-icon',
            ['DRAFT', 'SENT', 'PREPARING', 'PREPARED', 'SOLVING'].includes(
              status,
            ) && 'disabled',
          )}
        />
      </button>
      {activeMenu && (
        <MenuList
          className="experiments-list"
          closeMenu={closeMenu}
          controlRef={controlRef}
        >
          {experimentTypes.map((value, index) => (
            <button
              key={index}
              className="project-header__menu-list-item"
              type="button"
              onClick={() => setExperimentType(value)}
            >
              {value}
            </button>
          ))}
        </MenuList>
      )}
    </div>
  );
};

export default ExperimentSelect;
