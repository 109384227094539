import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { AxiosResponse } from 'axios';
import Map from 'Components/Map';
import { resetCommonSliceState } from 'store/commonSlice';
import { resetMapSliceState } from 'store/mapSlice';
import { resetProjectSliceState, selectNodes } from 'store/projectSlice';
import {
  resetTaskSliceState,
  selectExperimentStatus,
  selectExperimentType,
  selectShowResults,
  selectSolution,
  selectTaskId,
  setExperimentMessage,
  setExperimentStatus,
  setSolution,
  setTaskId,
} from 'store/taskSlice';

import DialogWindow from '../../Components/DialogWindow';
import { components } from '../../generated/apiTypes';
import {
  calcFactAdaptationTask,
  calcHydroTask,
  calcModeOptimizationTask,
  calcNetworkOptimizationTask,
  calcNodalAnalysisTask,
  getTasks,
  getTraces,
} from '../../services/apiRequests';
import PipesCatalog from './DetailsPanel';
import fetchProject from './hooks/use-get-project';
import useGetSolution from './hooks/use-get-solution';
import Issues from './Issues';
import LeftCol from './LeftCol';
import LeftColBottom from './LeftColBottom';
import NetworkObjects from './NetworkObjects';
import ProjectHeader from './ProjectHeader/ProjectHeader';
import ProjectMap from './ProjectMap';
import RightCol from './RightCol';
import { taskByExperimentType } from './utils';

import './style.scss';

const Project: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const taskType = useSelector(selectExperimentType);
  const storeSolution = useSelector(selectSolution)?.solution;
  const status = useSelector(selectExperimentStatus);
  const showRes = useSelector(selectShowResults);
  const nodes = useSelector(selectNodes);
  const taskId = useSelector(selectTaskId);
  const timerId = React.useRef<ReturnType<typeof setTimeout>>();
  useGetSolution(taskId, status);

  const resetStore = () => {
    dispatch(resetTaskSliceState());
    dispatch(resetProjectSliceState());
    dispatch(resetMapSliceState());
    dispatch(resetCommonSliceState());
  };

  React.useEffect(() => {
    resetStore();
    fetchProject(params.id, dispatch);
    return () => resetStore();
  }, [params.id]);

  const getTaskTraces = (id: string) => {
    timerId.current = setInterval(async () => {
      const traces = await getTraces(id);
      const currentStatus = traces?.data?.status;
      if (currentStatus) {
        dispatch(setExperimentStatus(currentStatus));
      }
      if (
        ['SOLVED', 'FAILED', 'VALIDATION_ERROR', 'INTERRUPTED'].includes(
          currentStatus,
        )
      ) {
        if (timerId.current) clearInterval(timerId.current);
      }
      if (['FAILED', 'VALIDATION_ERROR'].includes(currentStatus)) {
        const trace = traces.data?.traces.find(
          item => item.status === currentStatus,
        );
        dispatch(setExperimentMessage(trace?.message));
      }
    }, 1000);
  };

  const fetchTasks = async () => {
    if (timerId.current) clearInterval(timerId.current);
    try {
      const response = await getTasks(params.id);
      if (response?.data?.length > 0) {
        const task = response?.data?.find(
          item => item.task_type === taskByExperimentType(taskType),
        );
        if (task?.task_uid) {
          dispatch(
            setSolution({
              type: taskType,
              solution: storeSolution,
            }),
          );
          dispatch(setTaskId(task.task_uid));
          const traces = await getTraces(task.task_uid);
          const currentStatus = traces?.data?.status;
          if (currentStatus) {
            if (currentStatus === 'SOLVED')
              dispatch(setExperimentStatus('SOLVED_VIEWED'));
            else if (currentStatus === 'INTERRUPTED')
              dispatch(setExperimentStatus('INTERRUPTED'));
            else if (currentStatus === 'FAILED') {
              const trace = traces.data?.traces.find(
                item => item.status === currentStatus,
              );
              dispatch(setExperimentStatus('FAILED_VIEWED'));
              dispatch(setExperimentMessage(trace?.message));
            } else if (currentStatus === 'VALIDATION_ERROR') {
              const trace = traces.data?.traces.find(
                item => item.status === currentStatus,
              );
              dispatch(setExperimentStatus('VALIDATION_ERROR_VIEWED'));
              dispatch(setExperimentMessage(trace?.message));
            } else {
              dispatch(setExperimentStatus(currentStatus));
              getTaskTraces(task.task_uid);
            }
          } else {
            dispatch(setSolution({ type: 'Узловой анализ', solution: null }));
            dispatch(setExperimentStatus('NONE'));
          }
        } else {
          dispatch(setSolution({ type: 'Узловой анализ', solution: null }));
          dispatch(setExperimentStatus('NONE'));
        }
      } else {
        dispatch(setSolution({ type: 'Узловой анализ', solution: null }));
        dispatch(setExperimentStatus('NONE'));
      }
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    fetchTasks();
    return () => {
      if (timerId.current) clearInterval(timerId.current);
    };
  }, [taskType, params.id]);

  const clearTaskId = () => {
    dispatch(setTaskId(''));
  };

  const calcTask = async () => {
    dispatch(setSolution({ type: taskType, solution: null }));
    clearTaskId();
    try {
      let response:
        | AxiosResponse<components['schemas']['TaskStatusTracer']>
        | undefined;
      switch (taskType) {
        case 'Узловой анализ':
          response = await calcNodalAnalysisTask({ project_id: params.id });
          break;
        case 'Гидравлический расчет':
          response = await calcHydroTask({ project_id: params.id });
          break;
        case 'Адаптация на факт':
          response = await calcFactAdaptationTask({ project_id: params.id });
          break;
        case 'Оптимизация режима':
          response = await calcModeOptimizationTask({ project_id: params.id });
          break;
        case 'Оптимизация сети':
          response = await calcNetworkOptimizationTask({
            project_id: params.id,
          });
          break;
        default:
          break;
      }
      if (response?.data?.status)
        dispatch(setExperimentStatus(response.data.status));
      if (response?.data?.task_uid) {
        getTaskTraces(response?.data?.task_uid);
      }
      dispatch(setTaskId(response?.data?.task_uid as string));
    } catch (e) {
      //
    }
  };

  const mapCenter = [
    nodes.items[0]?.coordinates?.[0] || 55.24747026552241,
    nodes.items[0]?.coordinates?.[1] || 51.59632314294971,
  ] as [number, number];

  const mapComponent = React.useMemo(
    () => (
      <Map mapProps={{ center: mapCenter }} height="calc(100vh - 56px)">
        <ProjectMap center={mapCenter} />
      </Map>
    ),
    [nodes.items[0]?.coordinates?.[0], nodes.items[0]?.coordinates?.[1]],
  );

  return (
    <div className="project-container">
      <DialogWindow />
      <ProjectHeader />
      <div className="d-flex">
        <LeftCol showResultPanel={showRes} taskId={taskId}>
          <NetworkObjects />
          {!!params.id && <LeftColBottom taskId={params.id} />}
        </LeftCol>
        {mapComponent}
        {!showRes && (
          <>
            <Issues />
            <PipesCatalog />
            <div className="project-task-settings__right-col">
              <RightCol calcTask={calcTask} projectUid={params.id} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Project;
