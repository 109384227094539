import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Nodes from '../Nodes';
import Segments from '../Segments';
import Sink from '../Sink';
import Sources from '../Sources';

const NOResults: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(1);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList className="results__tab-list underline">
        <Tab className="results__tab">Центр сбора</Tab>
        <Tab className="results__tab">Скважины</Tab>
        <Tab className="results__tab">Узлы</Tab>
        <Tab className="results__tab">Трубы</Tab>
      </TabList>
      <div className="results-tables__container">
        <TabPanel>
          <Sink />
        </TabPanel>
        <TabPanel>
          <Sources />
        </TabPanel>
        <TabPanel>
          <Nodes />
        </TabPanel>
        <TabPanel>
          <Segments />
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default NOResults;
