import React from 'react';

import { UseChartData } from '../types';
import { seriesColors } from '../utils';

const useChartData: UseChartData = (projectSolution, option) => {
  const { time } = React.useMemo(() => {
    const statesByTime =
      projectSolution?.solution?.well_step_table?.time2states;
    if (statesByTime) {
      return {
        time: Object.keys(statesByTime),
      };
    }
    return {};
  }, [projectSolution?.solution]);

  return React.useMemo(() => {
    if (time && option) {
      const chartData: { name: string; data: any[] }[] = [];
      projectSolution?.solution?.well_step_table?.wells?.map(well => {
        return chartData.push({
          name: well.name,
          data: projectSolution?.solution?.well_step_table?.time2states
            ? Object.values(
                projectSolution?.solution?.well_step_table?.time2states,
              ).map(state => {
                const wellStateByWellId = state.find(
                  val => val.well_id === well.uid,
                );
                if (option.value === 'pressure') {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  return wellStateByWellId?.[option.value]?.atm || 0;
                }
                if (option.value === 'temperature') {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  return wellStateByWellId?.[option.value]?.celsius || 0;
                }
                if (option.value === 'gas_flow_rate') {
                  return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    wellStateByWellId?.[option.value]?.thousand_m3_per_day || 0
                  );
                }
                if (option.value === 'control') {
                  return (
                    wellStateByWellId?.[option.value]?.hertz ||
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    wellStateByWellId?.[option.value]?.m3_per_day ||
                    0
                  );
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return wellStateByWellId?.[option.value]?.t_per_day || 0;
              })
            : [],
        });
      });

      return {
        labels: time,
        datasets: Array.from(chartData).map((el, index) => {
          return {
            label: el.name,
            data: el.data,
            fill: false,
            backgroundColor: seriesColors[index % 9],
            borderColor: seriesColors[index % 9],
            color: seriesColors[index % 9],
            borderWidth: 1,
            tension: 0.1,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
            hidden: index !== 0,
          };
        }),
      };
    }
    return { labels: [], datasets: [] };
  }, [time, option?.value]);
};

export default useChartData;
