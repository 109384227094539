import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectExperimentType,
  selectSolution,
  setAvgWellsTable,
  setIntergalCharacteristics,
  setSolution,
} from 'store/taskSlice';

import {
  getAvgWellsTableMO,
  getAvgWellsTableNO,
  getAvgWellsTableUA,
  getIntegralCharacteristics,
  getSolution,
} from 'services/apiRequests';
import { components } from '../../../generated/apiTypes';
import { ExperimentStatus, ExperimentType } from '../types';

const useGetSolution = (task_id: string | null, status: ExperimentStatus) => {
  const dispatch = useDispatch();
  const storeSolutionType = useSelector(selectSolution)?.type;
  const experimentType = useSelector(selectExperimentType);
  const [prevStatus, setPrevStatus] = React.useState<ExperimentStatus>();

  React.useEffect(() => {
    const getData = async () => {
      if (task_id) {
        try {
          switch (experimentType) {
            case 'Узловой анализ':
              {
                const integralCharacteristics = await getIntegralCharacteristics(
                  task_id,
                );
                dispatch(
                  setIntergalCharacteristics(
                    integralCharacteristics.data as components['schemas']['PNOPeriodicNodalAnalysisSolutionIntegralCharacteristic'],
                  ),
                );
                const avgWellsTable = await getAvgWellsTableUA(task_id);
                dispatch(setAvgWellsTable(avgWellsTable.data));
                const solution: any = await getSolution(task_id);
                dispatch(
                  setSolution({
                    type: storeSolutionType as ExperimentType,
                    solution: solution.data,
                  }),
                );
              }
              break;
            case 'Оптимизация режима': {
              const integralCharacteristics = await getIntegralCharacteristics(
                task_id,
              );
              dispatch(
                setIntergalCharacteristics(
                  integralCharacteristics.data as components['schemas']['PNOPeriodicNodalAnalysisSolutionIntegralCharacteristic'],
                ),
              );
              const avgWellsTable = await getAvgWellsTableMO(task_id);
              dispatch(setAvgWellsTable(avgWellsTable.data));
              const response: any = await getSolution(task_id);
              dispatch(
                setSolution({
                  type: storeSolutionType as ExperimentType,
                  solution: response.data,
                }),
              );
              break;
            }
            case 'Оптимизация сети': {
              const integralCharacteristics = await getIntegralCharacteristics(
                task_id,
              );
              dispatch(
                setIntergalCharacteristics(
                  integralCharacteristics.data as components['schemas']['PNOPeriodicNodalAnalysisSolutionIntegralCharacteristic'],
                ),
              );
              const avgWellsTable = await getAvgWellsTableNO(task_id);
              dispatch(setAvgWellsTable(avgWellsTable.data));
              const response: any = await getSolution(task_id);
              dispatch(
                setSolution({
                  type: storeSolutionType as ExperimentType,
                  solution: response.data,
                }),
              );
              break;
            }
            default: {
              const response: any = await getSolution(task_id);
              dispatch(
                setSolution({
                  type: storeSolutionType as ExperimentType,
                  solution: response.data,
                }),
              );
              break;
            }
          }
        } catch (e) {
          //
        }
      }
    };
    if (status === 'SOLVED') getData();
    else if (prevStatus !== 'SOLVED' && status === 'SOLVED_VIEWED') getData();
    setPrevStatus(status);
  }, [status]);
};

export default useGetSolution;
