import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';

import classNames from 'classnames';
import {
  selectNodeById,
  selectSegmentById,
  selectShowDetailsPanel,
  setShowDetailsPanel,
  updateNodes,
  updateSegments,
} from 'store/projectSlice';

import { components } from '../../../generated/apiTypes';
import { ReactComponent as DetailsIcon } from '../../../images/Project/icn-details.svg';
import { ReactComponent as LinkedIcon } from '../../../images/Project/linked-icon.svg';
import {
  setNode as saveNode,
  setSegment as saveSegment,
} from '../../../services/apiRequests';
import { IObject } from '../types';
import ObjectInput from './components/ObjectInput';
import ObjectSelect, { OptionType } from './components/ObjectSelect';
import ObjectStringInput from './components/ObjectStringInput';
import {
  getHeatTransferCoefficientOptions,
  getSegmentLengthOptions,
} from './components/options';

const Segment: React.FC<IObject> = ({ objectUid, projectUid }) => {
  const segmentById = useSelector(selectSegmentById);
  const nodeById = useSelector(selectNodeById);
  const showDetailsPanel = useSelector(selectShowDetailsPanel);
  const dispatch = useDispatch();

  const [selectValue, setSelectValue] = React.useState<
    ValueType<OptionType<number>, false> | undefined
  >(undefined);

  const [lengthType, setLengthType] = React.useState<
    ValueType<OptionType<string>, false> | undefined
  >(undefined);

  React.useEffect(() => {
    const value = getHeatTransferCoefficientOptions()?.find(
      el => el.value === segmentById[objectUid as string]?.heat_transfer_coef,
    );
    setSelectValue(
      value ||
        getHeatTransferCoefficientOptions()[
          getHeatTransferCoefficientOptions().length - 1
        ],
    );
    setLengthType(getSegmentLengthOptions()[0]);
  }, [objectUid]);

  const saveNewSegment = async (value: number | string, name: string) => {
    try {
      const response = await saveSegment(projectUid, objectUid as string, {
        ...segmentById[objectUid as string],
        [name]: value,
      });
      if (response.data) dispatch(updateSegments(response.data));
    } catch (e) {
      //
    }
  };

  const saveNewNodeValue = async (value: string, name: string) => {
    let newValue: components['schemas']['PNOPipelineNode'] | null = null;
    let nodeId = '';
    if (name === 'start_node_name') {
      nodeId = segmentById[objectUid as string]?.start_uid as string;
    } else if (name === 'end_node_name') {
      nodeId = segmentById[objectUid as string]?.end_uid as string;
    }
    if (nodeId) {
      newValue = {
        ...nodeById[nodeId],
        name: value,
      };
      try {
        const response = await saveNode(projectUid, nodeId, newValue);
        if (response.data) dispatch(updateNodes(newValue));
      } catch (e) {
        //
      }
    }
  };

  const saveSelectValue = (value: ValueType<OptionType<number>, false>) => {
    setSelectValue(value);
  };

  const saveLengthType = (value: ValueType<OptionType<string>, false>) => {
    setLengthType(value);
  };

  const handleToggleDetails = () => {
    if (showDetailsPanel === 'heightProfile')
      dispatch(setShowDetailsPanel(null));
    else dispatch(setShowDetailsPanel('heightProfile'));
  };

  return (
    <div>
      <div className="active-object__top-row">
        <p className="active-object__title">Трубопровод</p>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div>
            <label className="input-label">Начальный куст</label>
            <div className="d-flex">
              <div className="active-object__row-input-prefix start" />
              <ObjectStringInput
                label=""
                className="input active-object__row-input with-prefix left"
                initValue={
                  nodeById?.[
                    segmentById[objectUid as string]?.start_uid as string
                  ]?.name
                }
                name="start_node_name"
                saveNewValue={saveNewNodeValue}
              />
            </div>
          </div>
          <div>
            <label className="input-label">Конечный куст</label>
            <div className="d-flex">
              <div className="active-object__row-input-prefix end" />
              <ObjectStringInput
                label=""
                className="input active-object__row-input with-prefix"
                initValue={
                  nodeById?.[
                    segmentById[objectUid as string]?.end_uid as string
                  ]?.name
                }
                name="end_node_name"
                saveNewValue={saveNewNodeValue}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Внешний диаметр"
              name="diameter"
              unit="mm"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              initValue={segmentById[objectUid as string]?.diameter?.mm}
              saveNewValue={saveNewSegment}
            />
            <p className="active-object__row-input-unit">мм</p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Толщина стенки"
              unit="mm"
              name="wall_thickness"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              initValue={segmentById[objectUid as string]?.wall_thickness?.mm}
              saveNewValue={saveNewSegment}
            />
            <p className="active-object__row-input-unit">мм</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex">
          <div className="active-object__input-with-unit-wrapper left">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Толщина АСПО"
              name="aspo"
              unit="mm"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              initValue={segmentById[objectUid as string]?.aspo?.mm}
              saveNewValue={saveNewSegment}
            />
            <p className="active-object__row-input-unit">мм</p>
          </div>
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label="Шероховатость"
              name="roughness"
              unit="mm"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              initValue={segmentById[objectUid as string]?.roughness?.mm}
              saveNewValue={saveNewSegment}
            />
            <p className="active-object__row-input-unit">мм</p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="active-object__input-with-unit-wrapper short">
          <ObjectInput
            className="input active-object__row-input with-unit"
            label="˚t окр. среды"
            name="temperature_out"
            unit="celsius"
            initValue={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              segmentById[objectUid as string]?.temperature_out.celsius
            }
            saveNewValue={saveNewSegment}
          />
          <p className="active-object__row-input-unit">˚С</p>
        </div>
      </div>
      <div className="active-object__row">
        <label className="input-label">Коэффициент теплопроводности</label>
        <div className="d-flex">
          <ObjectSelect
            label=""
            classNamesWrapper=""
            classNames="active-object__row-input short"
            name="heat_transfer_coef"
            options={getHeatTransferCoefficientOptions()}
            value={selectValue}
            saveNewValue={saveNewSegment}
            saveNewOption={saveSelectValue}
          />
          <LinkedIcon className="active-object__linked-icon" />
          <div className="active-object__input-with-unit-wrapper">
            <ObjectInput
              className="input active-object__row-input short with-unit"
              label=""
              name="heat_transfer_coef"
              disabled={selectValue?.label !== 'Пользовательский'}
              initValue={selectValue?.value}
              saveNewValue={saveNewSegment}
            />
            <p className="active-object__row-input-unit">
              Вт/
              <span className="relative">
                м<span className="index small">2</span>
              </span>
              &nbsp; /K
            </p>
          </div>
        </div>
      </div>
      <div className="active-object__row">
        <div className="d-flex flex-end">
          <ObjectSelect
            label="Способ задания длины"
            classNamesWrapper=""
            classNames="active-object__row-input short"
            name=""
            options={getSegmentLengthOptions()}
            value={lengthType}
            saveNewOption={saveLengthType}
          />
          <LinkedIcon className="active-object__linked-icon" />
          <div className="active-object__input-with-unit-wrapper">
            <div
              className={classNames(
                'active-object__input-with-unit-wrapper',
                lengthType?.label === 'Высотный проф' && 'extra-short',
              )}
            >
              <ObjectInput
                className={classNames(
                  'input active-object__row-input with-unit',
                  lengthType?.label === 'Высотный проф' && 'extra-short',
                )}
                label="Длина"
                name="length"
                unit="m"
                initValue={segmentById[objectUid as string]?.length.m}
                saveNewValue={saveNewSegment}
                disabled={lengthType?.label === 'Высотный проф'}
              />
              <p className="active-object__row-input-unit">м</p>
            </div>
          </div>
          {lengthType?.label === 'Высотный проф' && (
            <button
              className={classNames(
                'active-object__well-model-button',
                showDetailsPanel === 'heightProfile' && 'active',
              )}
              type="button"
              onClick={handleToggleDetails}
            >
              <DetailsIcon className="well-model-button-icon" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Segment;
