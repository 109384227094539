import * as React from 'react';

import classNames from 'classnames';

import './style.scss';

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  onEnter?(): void;
}

const Input: React.FC<IInput> = ({ label, error, onEnter, ...props }) => {
  const ref = React.useRef<HTMLInputElement | null>(null);
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (['Enter', 'Escape'].includes(e.key)) {
      ref?.current?.blur();
      if (['Enter'].includes(e.key) && onEnter) {
        onEnter();
      }
    }
    return false;
  };
  return (
    <label>
      {label && <span className="input-label">{label}</span>}
      <input
        ref={ref}
        className={classNames('input', { error })}
        {...props}
        onKeyDown={onKeyDownHandler}
      />
    </label>
  );
};

export default Input;
