import Dexie from 'dexie';

interface IHistoryActions {
  uid: string;
  name: string;
}

interface IRequestHttpsActions {
  url: string;
  method: string;
  data: string;
}

interface IRequestSocketActions {
  domain: string;
  command: string;
  params: any;
  objectUid?: string;
}

export default class AppIndexedDB extends Dexie {
  historyActions: Dexie.Table<IHistoryActions>;

  requestHttpsActions: Dexie.Table<IRequestHttpsActions>;

  requestSocketActions: Dexie.Table<IRequestSocketActions>;

  constructor() {
    super('AppIndexedDB');
    this.version(1).stores({
      historyActions: '++id, uid, name',
      requestHttpsActions: '++id, url, method, data',
      requestSocketActions: '++id, domain, command, params, object_uid',
    });

    this.historyActions = this.table('historyActions');
    this.requestHttpsActions = this.table('requestHttpsActions');
    this.requestSocketActions = this.table('requestSocketActions');
    this.clear();
  }

  deleteTables = () => {
    Dexie.delete('historyActions');
    Dexie.delete('requestHttpsActions');
    Dexie.delete('requestSocketActions');
  };

  clear = async () => {
    // this.deleteTables();
    const historyActions = await this.historyActions.toArray();
    console.log('AppIndexedDB -> clear -> historyActions', historyActions);
    const requestHttpsActions = await this.requestHttpsActions.toArray();
    console.log(
      'AppIndexedDB -> clear -> requestHttpsActions',
      requestHttpsActions,
    );
    const requestSocketActions = await this.requestSocketActions.toArray();
    console.log(
      'AppIndexedDB -> clear -> requestSocketActions',
      requestSocketActions,
    );
    if (historyActions.length) await this.historyActions.clear();
    if (requestHttpsActions.length) await this.requestHttpsActions.clear();
    if (requestSocketActions.length) await this.requestSocketActions.clear();
  };
}
