import { AxiosResponse } from 'axios';
import { components } from 'generated/apiTypes';

import { api } from '../api';

type CreateProjectDto = {
  file: FormData;
  project_name: string;
};

type CreateProjectFromFilesDto = {
  files: FormData;
  project_name: string;
  iskra_project_url: string;
};

type CreateProjectFromFileDto = {
  file: FormData;
  project_name: string;
  iskra_project_url: string;
};

type CalcTaskDto = {
  project_id: string;
};

type CancelTaskDto = {
  task_id: string;
};

type SetPackageDto = {
  project_id: string;
  phys_chem_id: string;
};

export const login = (formData: any): Promise<AxiosResponse> => {
  return api.post(`/api/token`, formData);
};

export const getProjects = (): Promise<
  AxiosResponse<components['schemas']['PNOProjectData'][]>
> => {
  return api.get('/api/projects');
};

export const createProject = ({ file, project_name }: CreateProjectDto) => {
  return api.post('/api/projects', file, { params: { project_name } });
};

export const createProjectFromFiles = ({
  files,
  project_name,
  iskra_project_url,
}: CreateProjectFromFilesDto) => {
  return api.post('/api/projects/files', files, {
    params: { project_name, iskra_project_url },
  });
};

export const createProjectFromFile = ({
  file,
  project_name,
  iskra_project_url,
}: CreateProjectFromFileDto) => {
  return api.post('/api/projects/file', file, {
    params: { project_name, iskra_project_url },
  });
};

export const getProject = (
  id: string,
): Promise<AxiosResponse<components['schemas']['PNOProject']>> => {
  return api.get(`/api/projects/${id}`);
};

export const getTasks = (
  project_uid: string,
): Promise<AxiosResponse<components['schemas']['TaskData'][]>> => {
  return api.get(`/api/tasks`, {
    params: {
      project_uid,
    },
  });
};

export const calcNodalAnalysisTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(
    `/api/projects/${project_id}/calc/periodic-nodal-analysis-task`,
  );
};

export const calcHydroTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(`/api/projects/${project_id}/calc/pipeline-network-task`);
};

export const calcFactAdaptationTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(
    `/api/projects/${project_id}/calc/network-fact-adaptation-task`,
  );
};

export const calcModeOptimizationTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(
    `/api/projects/${project_id}/calc/tech-mode-optimization-task`,
  );
};

export const calcNetworkOptimizationTask = ({
  project_id,
}: CalcTaskDto): Promise<
  AxiosResponse<components['schemas']['TaskStatusTracer']>
> => {
  return api.post(
    `/api/projects/${project_id}/calc/tech-network-optimization-task`,
  );
};

export const cancelTask = ({ task_id }: CancelTaskDto): Promise<null> => {
  return api.post(`/api/tasks/${task_id}/cancel`);
};

export const getSolution = (
  task_id: string,
): Promise<
  AxiosResponse<
    | components['schemas']['PNOPeriodicNodalAnalysisProblem']
    | components['schemas']['PNOPipelineNetworkProblem']
    | components['schemas']['PNONetworkFactAdaptationProblem']
  >
> => {
  return api.get(`/api/get_solution`, {
    params: {
      task_id,
    },
  });
};

export const getIntegralCharacteristics = (
  task_id: string,
): Promise<
  AxiosResponse<
    | components['schemas']['PNOPeriodicNodalAnalysisSolutionIntegralCharacteristic']
    | components['schemas']['PNOPipelineNetworkSolutionIntegralCharacteristic']
    | components['schemas']['PNOFactAdaptationSolutionIntegralCharacteristic']
  >
> => {
  return api.get(`/api/solutions/${task_id}/short`);
};

export const getExperimentSettings = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['ExperimentSettings']>> => {
  return api.get(`/api/projects/${project_id}/experiment-settings`);
};

export const getTraces = (
  task_id: string,
): Promise<AxiosResponse<components['schemas']['TaskStatusTracer']>> => {
  return api.get(`/api/traces`, { params: { task_id } });
};

export const setExperimentSettings = (
  project_id: string,
  settings: components['schemas']['ExperimentSettings'],
) => {
  return api.put(`/api/projects/${project_id}/experiment-settings`, settings);
};

export const validateProject = (project_uid: string) =>
  api.post<components['schemas']['PNOProjectValidationResult']>(
    `/api/projects/${project_uid}/validate`,
  );

export const setWell = (
  project_id: string,
  well_id: string,
  well: components['schemas']['PNOWell'],
) => {
  return api.put(`/api/projects/${project_id}/wells/${well_id}`, well);
};

export const getWellModel = (project_id: string, well_id: string) => {
  return api.get(`/api/projects/${project_id}/well-models/${well_id}`);
};

export const setWellModel = (
  project_id: string,
  well_id: string,
  well_model: components['schemas']['PNOWellModel'],
) => {
  return api.put(
    `/api/projects/${project_id}/well-models/${well_id}`,
    well_model,
  );
};

export const setWellHydroModel = (
  project_id: string,
  well_id: string,
  well_hydro_model: components['schemas']['PNOWellHydroModel'],
) => {
  return api.put(
    `/api/projects/${project_id}/well-hydro-models/${well_id}`,
    well_hydro_model,
  );
};

export const setWellControlModel = (
  project_id: string,
  well_id: string,
  well_control_model: components['schemas']['PNOWellControlModel'],
) => {
  return api.put(
    `/api/projects/${project_id}/well-control-models/${well_id}`,
    well_control_model,
  );
};

export const setWellFactMeasurement = (
  project_id: string,
  well_id: string,
  measurement: components['schemas']['PNOWellFactMeasurement'],
) => {
  return api.put(
    `/api/projects/${project_id}/well-fact-measurement-models/${well_id}`,
    measurement,
  );
};

export const setGatheringCentersModel = (
  project_id: string,
  node_id: string,
  gathering_center_model: components['schemas']['PNOGatheringCenterModel'],
) => {
  return api.put(
    `/api/projects/${project_id}/gathering-center-models/${node_id}`,
    gathering_center_model,
  );
};

export const setGatheringCenterFactPressure = (
  project_id: string,
  node_id: string,
  data: { [x: string]: number },
): Promise<
  AxiosResponse<components['schemas']['PNOGatheringCenterFactPressure'][]>
> => {
  return api.put(
    `/api/projects/${project_id}/gathering-center-fact-pressure/${node_id}`,
    data,
  );
};

export const setSegment = (
  project_id: string,
  segment_id: string,
  segment: components['schemas']['PNOPipelineSegment'],
) => {
  return api.put(`/api/projects/${project_id}/segments/${segment_id}`, segment);
};

export const getExcelProjectFile = (id: string) => {
  return api.get(`/api/projects/${id}/excel`, {
    responseType: 'blob',
  });
};

export const getJSONProjectFile = (id: string) => {
  return api.get(`/api/projects/${id}/json`, {
    responseType: 'blob',
  });
};

export const getExcelUASolution = (task_uid: string) => {
  return api.get(
    `/api/periodic-nodal-analysis-tasks/${task_uid}/solution/excel`,
    {
      responseType: 'blob',
    },
  );
};

export const getExcelAFSolution = (task_uid: string) => {
  return api.get(
    `/api/network-fact-adaptation-tasks/${task_uid}/solution/excel`,
    {
      responseType: 'blob',
    },
  );
};

export const getExcelGRSolution = (task_uid: string) => {
  return api.get(`/api/pipeline-network-tasks/${task_uid}/solution/excel`, {
    responseType: 'blob',
  });
};

export const setPhysChemPackage = (
  project_id: string,
  package_id: string,
  pack: components['schemas']['PNOPhysChemPackage'],
) => {
  return api.put(
    `/api/projects/${project_id}/phys-chem-packages/${package_id}`,
    pack,
  );
};

export const addPhysChemPackage = (
  project_id: string,
  pack: components['schemas']['PNOPhysChemPackage'],
): Promise<AxiosResponse<components['schemas']['PNOPhysChemPackage'][]>> => {
  return api.post(`/api/projects/${project_id}/phys-chem-packages`, pack);
};

export const deleteProject = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['PNOProjectData'][]>> => {
  return api.delete(`/api/projects/${project_id}`);
};

export const deletePhysChemPackage = (
  project_id: string,
  package_id: string,
): Promise<AxiosResponse<components['schemas']['PNOPhysChemPackage'][]>> => {
  return api.delete(
    `/api/projects/${project_id}/phys-chem-packages/${package_id}`,
  );
};

export const deleteNode = (
  project_id: string,
  node_id: string,
): Promise<AxiosResponse<components['schemas']['PNOPipelineNetwork']>> => {
  return api.delete(`/api/projects/${project_id}/nodes/${node_id}`);
};

export const deleteSegment = (
  project_id: string,
  segment_id: string,
): Promise<AxiosResponse<components['schemas']['PNOPipelineNetwork']>> => {
  return api.delete(`/api/projects/${project_id}/segments/${segment_id}`);
};

export const deleteWell = (
  project_id: string,
  well_id: string,
): Promise<AxiosResponse<components['schemas']['WellResponse']>> => {
  return api.delete(`/api/projects/${project_id}/wells/${well_id}`);
};

export const deleteSink = (
  project_id: string,
  node_id: string,
): Promise<AxiosResponse<components['schemas']['GatheringCenterResponse']>> => {
  return api.delete(
    `/api/projects/${project_id}/gathering-center-models/${node_id}`,
  );
};

export const setNode = (
  project_id: string,
  node_id: string,
  node: components['schemas']['PNOPipelineNode'],
) => {
  return api.put(`/api/projects/${project_id}/nodes/${node_id}`, node);
};

export const getAvgWellsTableUA = (
  task_id: string,
): Promise<
  AxiosResponse<components['schemas']['PNOPeriodicNodalAnalysisWellAvgTable']>
> => {
  return api.get(
    `/api/periodic-nodal-analysis-tasks/${task_id}/solution/avg-well-table`,
  );
};

export const getAvgWellsTableMO = (
  task_id: string,
): Promise<
  AxiosResponse<components['schemas']['PNOPeriodicNodalAnalysisWellAvgTable']>
> => {
  return api.get(
    `/api/tech-mode-optimization-tasks/${task_id}/solution/avg-well-table`,
  );
};

export const getAvgWellsTableNO = (
  task_id: string,
): Promise<
  AxiosResponse<components['schemas']['PNOPeriodicNodalAnalysisWellAvgTable']>
> => {
  return api.get(
    `/api/tech-network-optimization-tasks/${task_id}/solution/avg-well-table`,
  );
};

export const getAvgSegmentsTableUA = (
  task_id: string,
): Promise<
  AxiosResponse<
    components['schemas']['PNOPeriodicNodalAnalysisSegmentAvgTable']
  >
> => {
  return api.get(
    `/api/periodic-nodal-analysis-tasks/${task_id}/solution/avg-segment-table`,
    {
      params: {
        task_id,
      },
    },
  );
};

export const getAvgNodesTableUA = (
  task_id: string,
): Promise<
  AxiosResponse<components['schemas']['PNOPeriodicNodalAnalysisNodeAvgTable']>
> => {
  return api.get(
    `/api/periodic-nodal-analysis-tasks/${task_id}/solution/avg-node-table`,
    {
      params: {
        task_id,
      },
    },
  );
};

export const setPackageForAllWells = ({
  project_id,
  phys_chem_id,
}: SetPackageDto): Promise<
  AxiosResponse<components['schemas']['PNOWell'][]>
> => {
  return api.post(
    `/api/projects/${project_id}/phys-chem-packages/${phys_chem_id}/set-for-all-well-models`,
  );
};

interface AddNode {
  uid: string;
  project_id: string;
  name: string;
  type: 'SOURCE' | 'JUNCTION' | 'SINK';
  coordinates: [number, number];
}

interface AddWell {
  project_id: string;
  name: string;
  coordinates: [number, number];
}

interface AddGatheringCenter {
  project_id: string;
  name: string;
  pressure: number;
  coordinates: [number, number];
}

export interface AddSegment {
  project_id: string;
  data: components['schemas']['PNOPipelineSegment'];
}

// Обновление сети
interface UpdateNetwork {
  project_id: string;
  nodes: components['schemas']['PNOPipelineNetwork']['nodes'];
  segments: components['schemas']['PNOPipelineNetwork']['segments'];
}

export const updateNetwork = async ({
  project_id,
  nodes,
  segments,
}: UpdateNetwork) => {
  return api.put(`/api/projects/${project_id}/pipeline-network`, {
    nodes,
    segments,
  });
};

// Добавление Узла
export const addNode = async ({
  project_id,
  uid,
  name,
  type,
  coordinates,
}: AddNode) => {
  return api.post(`/api/projects/${project_id}/nodes`, {
    uid,
    name,
    type,
    coordinates,
  });
};

// Добавление Скважины
export const addWell = async ({ project_id, name, coordinates }: AddWell) => {
  return api.post(`/api/projects/${project_id}/wells`, {
    name,
    coordinates,
  });
};

// Добавление ЦС
export const addGatheringCenter = async ({
  project_id,
  name,
  pressure,
  coordinates,
}: AddGatheringCenter) => {
  return api.post(`/api/projects/${project_id}/gathering-center`, {
    name,
    pressure,
    coordinates,
  });
};

// Добавление Трубы
export const addSegment = async ({ project_id, data }: AddSegment) => {
  return api.post(`/api/projects/${project_id}/segments`, data);
};

export const applyAspo = (
  project_id: string,
  task_id: string,
): Promise<AxiosResponse<components['schemas']['PNOPipelineNetwork']>> => {
  return api.post(`/api/projects/${project_id}/aspo/${task_id}`);
};

export const resetAspo = (
  project_id: string,
): Promise<AxiosResponse<components['schemas']['PNOPipelineNetwork']>> => {
  return api.post(`/api/projects/${project_id}/zero-aspo`);
};

export const getSegmentsCatalog = (
  project_id: string,
): Promise<
  AxiosResponse<components['schemas']['PNOSegmentTypeCharacteristic'][]>
> => {
  return api.get(`/api/projects/${project_id}/segment-catalog`);
};
