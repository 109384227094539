import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectSolution } from 'store/taskSlice';

import AFResults from './AF';
import GRResults from './GR';
import MOResults from './MO';
import NOResults from './NO';
import UAResults from './UA';

const Segments: React.FC = () => {
  const experimentType = useSelector(selectSolution)?.type;
  if (experimentType === 'Узловой анализ') return <UAResults />;
  if (experimentType === 'Адаптация на факт') return <AFResults />;
  if (experimentType === 'Гидравлический расчет') return <GRResults />;
  if (experimentType === 'Оптимизация режима') return <MOResults />;
  if (experimentType === 'Оптимизация сети') return <NOResults />;
  return <></>;
};

export default Segments;
