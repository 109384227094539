import { updateDrawState } from 'store/mapSlice';
import { v4 as uuidv4 } from 'uuid';

import store from '../../../../store/store';
import { IMapSlice } from '../../../../store/types';

type CursorStyles = 'grabbing' | 'pointer' | 'grab' | null;
class MapDraw {
  map: any;

  hoveredStateId: any;

  activeIconId: any;

  dragFeature: any;

  nodeMoveCoords: any;

  startCoords: any;

  cursor: CursorStyles;

  drawState: IMapSlice['drawState'];

  mouseClickEvents: boolean;

  prevCoords: any;

  dispatch: any;

  constructor(public handleNodesOnUp: any) {
    this.dispatch = store.dispatch;
    this.handleNodesOnUp = handleNodesOnUp;
    this.map = null;
    const initStore: any = store.getState();
    this.cursor = null;
    this.drawState = initStore.map.drawState;
    this.hoveredStateId = null;
    this.activeIconId = null;
    this.dragFeature = null;
    this.nodeMoveCoords = null;
    this.startCoords = null;
    this.mouseClickEvents = false;
    this.prevCoords = null;
  }

  init = (mapContext: any) => {
    this.map = mapContext;
  };

  setCursorType = (type: CursorStyles) => {
    this.map.getCanvasContainer().style.cursor = type;
    this.cursor = type;
  };

  getSources = (sourceId?: any) => {
    const sources: any = sourceId
      ? this.map?.getSource(sourceId)
      : this.map?.getStyle().sources;

    return sources;
  };

  addPrepareFeatures = (sourceId: string, features: any) => {
    const currentSource: any = this.getSources(sourceId);
    if (currentSource) {
      const newObj = {
        ...currentSource._data,
        features,
      };
      currentSource.setData(newObj);
    }
  };

  updateFeatures = (
    featureType: string,
    properties: any,
    coordinates: [number, number],
  ) => {
    const isSelected = store.getState().map.activeObject.object_uid;
    if (isSelected) {
      const selectedSourceId = `${properties?.type.toLowerCase()}_selected`;
      const currentSelectedSource: any = this.getSources(selectedSourceId);
      if (currentSelectedSource) {
        const featuresArr = currentSelectedSource._data.features.filter(
          (item: any) => item.properties.uid !== properties.uid,
        );
        const newFeature = this.prepareFeature(
          selectedSourceId,
          featureType,
          properties,
          coordinates,
        );

        featuresArr.push(newFeature);
        const newObj = {
          ...currentSelectedSource._data,
          features: featuresArr,
        };

        currentSelectedSource.setData(newObj);
      }
    }
    const sourceId = properties?.type.toLowerCase();
    const currentSource: any = this.getSources(sourceId);
    const featuresArr = currentSource._data.features.filter(
      (item: any) => item.properties.uid !== properties.uid,
    );
    const newFeature = this.prepareFeature(
      sourceId,
      featureType,
      properties,
      coordinates,
    );

    featuresArr.push(newFeature);
    const newObj = {
      ...currentSource._data,
      features: featuresArr,
    };

    currentSource.setData(newObj);
    this.moveSegments(properties.uid, coordinates);
  };

  deleteFeature = (uid: string | null | undefined) => {
    const sources = this.getSources();

    if (sources && Object.keys(sources).length) {
      Object.keys(sources).forEach((item: any) => {
        if (
          item.includes('lines') ||
          item.includes('junction') ||
          item.includes('sink') ||
          item.includes('source') ||
          item.includes('sourceStopped')
        ) {
          if (sources[item].data.features.length) {
            const sourceData: any = this.getSources(item);
            let features = sourceData._data.features.filter(
              (feature: any) => feature.properties.uid === uid,
            );

            if (features.length) {
              features = sourceData._data.features.filter(
                (feature: any) => feature.properties.uid !== uid,
              );
              sourceData.setData({
                type: 'FeatureCollection',
                features,
              });
            }
          }
        }
      });
    }
  };

  deleteAllFeatures = (sourceId: string) => {
    const source = this.getSources(sourceId);

    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features: [],
      });
    }
  };

  prepareFeature = (
    sourceId: any,
    geometryType: string,
    properties?: any,
    coordinates?: any,
  ) => {
    const sources: any = this.getSources(sourceId);
    const featuresIds = sources._data.features.length
      ? sources._data.features.map((item: any) => {
          return item.id;
        })
      : [];

    const id = featuresIds.length ? featuresIds[featuresIds.length - 1] + 1 : 1;

    const feature = {
      type: 'Feature',
      id: Number.isNaN(id) ? 1 : id,
      properties: properties || {},
      geometry: {
        type: geometryType,
        coordinates: coordinates || [],
      },
    };

    return feature;
  };

  disableElementSelected = () => {
    const sources: any = this.getSources();
    if (sources && Object.keys(sources).length) {
      Object.keys(sources).forEach((item: any) => {
        if (
          item.includes('selected') ||
          item.includes('hovered') ||
          item.includes('edit') ||
          item.includes('draw')
        ) {
          if (sources[item].data.features.length) {
            const sourceData: any = this.getSources(item);
            sourceData.setData({
              type: 'FeatureCollection',
              features: [],
            });
          }
        }
      });
    }
  };

  setElementSelected = (activeObject: IMapSlice['activeObject']) => {
    if (activeObject.type !== 'PACKAGE') {
      let sourceId: any = activeObject.type?.toLowerCase();
      if (sourceId === 'pipeline') sourceId = 'lines';
      if (sourceId === 'sourcestopped') sourceId = 'sourceStopped';
      const currentSource: any = this.getSources(sourceId);
      const coordinates = currentSource._data.features.find(
        (item: any) =>
          item.properties?.uid ===
          (sourceId !== 'source'
            ? activeObject.object_uid
            : activeObject.node_uid),
      )?.geometry?.coordinates;

      const feature = currentSource._data.features.filter(
        (item: any) =>
          item.properties?.uid ===
          (sourceId !== 'source'
            ? activeObject.object_uid
            : activeObject.node_uid),
      );
      const obj = {
        ...currentSource._data,
        features: feature,
      };

      const selectedSource: any = this.map?.getSource(`${sourceId}_selected`);

      selectedSource.setData(obj);

      if (coordinates) {
        this.map?.flyTo({
          center: Array.isArray(coordinates[0]) ? coordinates[0] : coordinates,
          maxDuration: 500,
          essential: true,
        });
      }
    }
  };

  handleMouseOnClick = async (node: any, callback?: any) => {
    const { layer, properties, geometry } = node.features[0];
    const featureUid = properties.uid;
    const featureType = layer.id;
    const coordinates = geometry.coordinates;
    const drawState = store.getState().map.drawState;
    if (featureUid && featureType && !drawState.isActive) {
      this.disableElementSelected();
      this.setCursorType('grab');
    }
    if (
      drawState.isActive &&
      drawState.activeElement === 'segment' &&
      !drawState.params.start_uid
    ) {
      const sourceId = `${layer.source}_edit`;
      const currentSource: any = this.getSources(sourceId);

      if (currentSource) {
        const hoverElements = [
          {
            type: 'Feature',
            id: 1,
            properties,
            geometry: {
              type: 'Point',
              coordinates,
            },
          },
        ];

        const hoverData = {
          type: 'FeatureCollection',
          features: hoverElements,
        };

        currentSource.setData(hoverData);
        this.startCoords = coordinates;
        this.map?.on('mousemove', this.onMove);
        this.dispatch(
          updateDrawState({
            params: {
              ...drawState.params,
              start_uid: featureUid,
              coordinates,
            },
          }),
        );
      }
    } else if (
      drawState.isActive &&
      drawState.activeElement === 'segment' &&
      drawState.params.start_uid &&
      featureUid !== drawState.params.start_uid
    ) {
      const uid: string = uuidv4();
      // const newName = createName(this.map, 'segment');
      const newCoordinates: any = [drawState.params.coordinates, coordinates];

      // TODO написать функцию очистки состояния подсвечиваемых элементов
      const sourceId = 'lines';
      const newSource: any = this.getSources(sourceId);
      const hoverElements = this.prepareFeature(
        sourceId,
        'LineString',
        { uid },
        newCoordinates,
      );
      const features = newSource._data.features;

      features.push(hoverElements);
      const hoverData = {
        type: 'FeatureCollection',
        features,
      };

      newSource.setData(hoverData);

      this.map?.flyTo({
        center: newCoordinates[0],
        maxDuration: 500,
        essential: true,
      });

      // console.log('SEND_OBJ', sendObj);
      this.map?.off('mousemove', this.onMove);
      // handleElem(e, true);
      this.setCursorType('grab');
      callback({
        uid,
        // name: newName,
        coordinates: newCoordinates,
        type: 'PIPELINE',
        start_uid: drawState.params.start_uid,
        end_uid: featureUid,
      });
    }
  };

  moveSegments = (nodeUid: string, coordinates: [number, number]) => {
    const moveSegmentsIds = ['lines', 'lines_loopings', 'lines_color'];

    moveSegmentsIds.forEach((segmentId: any) => {
      const segmentsSource = this.getSources(segmentId);
      const segmentsSourceFeatures = segmentsSource._data.features.filter(
        (item: any) =>
          item.properties.start_node_uid === nodeUid ||
          item.properties.end_node_uid === nodeUid,
      );
      if (segmentsSourceFeatures.length) {
        const newSegmentsSourceFeatures = segmentsSource._data.features.map(
          (item: any) => {
            if (item.properties.start_node_uid === nodeUid) {
              let newCoordinates = item.geometry.coordinates.slice(1);
              newCoordinates = [coordinates, ...newCoordinates];
              item.geometry.coordinates = newCoordinates;
            }

            if (item.properties.end_node_uid === nodeUid) {
              let newCoordinates = item.geometry.coordinates.slice(0, -1);
              newCoordinates = [...newCoordinates, coordinates];
              item.geometry.coordinates = newCoordinates;
            }

            return item;
          },
        );
        const filteredSegmentsSourceFeatures = segmentsSource._data.features.filter(
          (item: any) =>
            item.properties.start_node_uid !== nodeUid ||
            item.properties.end_node_uid !== nodeUid,
        );
        filteredSegmentsSourceFeatures.concat(newSegmentsSourceFeatures);
        const newData = {
          type: 'FeatureCollection',
          features: filteredSegmentsSourceFeatures,
        };
        segmentsSource.setData(newData);
      }
    });
  };

  onMove = (e: any) => {
    this.nodeMoveCoords = e.lngLat;
    const drawState = store.getState().map.drawState;
    if (this.cursor === 'grabbing' && !drawState.isActive && this.dragFeature) {
      this.setCursorType('grabbing');
      const { properties, layer } = this.dragFeature;
      const featureUid = properties.uid;
      const newCoordinates: [number, number] = [e.lngLat.lng, e.lngLat.lat];
      this.moveSegments(featureUid, newCoordinates);
      const newFeature = this.prepareFeature(
        layer.id,
        'Point',
        {
          uid: featureUid,
          name: properties.name,
        },
        newCoordinates,
      );

      const currentSource = this.getSources(layer.id);
      const currentSourcesFeatures = currentSource._data.features.filter(
        (item: any) => item.properties.uid !== featureUid,
      );

      const newData = {
        type: 'FeatureCollection',
        features: currentSourcesFeatures,
      };

      currentSourcesFeatures.push(newFeature);
      currentSource.setData(newData);
    }

    if (
      drawState.isActive &&
      drawState.activeElement === 'segment' &&
      this.startCoords
    ) {
      const sourceId = 'lines_draw';
      const currentSource: any = this.getSources(sourceId);
      if (currentSource) {
        const drawElements = [
          this.prepareFeature(sourceId, 'LineString', {}, [
            this.startCoords,
            [e.lngLat.lng, e.lngLat.lat],
          ]),
        ];
        const drawData = {
          type: 'FeatureCollection',
          features: drawElements,
        };
        currentSource.setData(drawData);
      }
    }
  };

  // Событие MouseUp
  onUp = (e: any) => {
    e.preventDefault();
    const { properties } = this.dragFeature;
    if (this.cursor === 'grabbing') {
      this.handleNodesOnUp(properties.uid, [e.lngLat.lng, e.lngLat.lat]);
    }
    this.dragFeature = null;
    this.nodeMoveCoords = null;
    this.activeIconId = null;
    this.hoveredStateId = null;
    this.mouseClickEvents = false;
    this.handleMouseLeave('nodes');
    if (this.cursor !== 'pointer' && !this.drawState?.isActive) {
      this.setCursorType('grab');
      this.disableElementSelected();

      this.map?.off('mousemove', this.onMove);
      this.map?.off('touchmove', this.onMove);
    }
  };

  handleNodeMouseDown = (e: any) => {
    const drawState = store.getState().map.drawState;
    this.setCursorType('grabbing');
    if (!drawState.isActive) {
      this.dragFeature = e.features[0];
      const { layer } = this.dragFeature;
      const currentSource = this.getSources(layer.id);
      this.handleMouseLeave(`${currentSource}_hovered`);
      e.preventDefault();
      setTimeout(() => {
        if (this.cursor === 'grabbing') {
          this.map?.on('mousemove', this.onMove);
          this.map?.once('mouseup', this.onUp);
        }
      }, 400);
    }
  };

  handleNodesMouseTouchStart = (e: any) => {
    if (e.points.length !== 1) return;
    e.preventDefault();
    const dragCoords = e.lngLat;
    e.preventDefault();
    setTimeout(() => {
      if (JSON.stringify(this.nodeMoveCoords) === JSON.stringify(dragCoords)) {
        e.preventDefault();
        this.map?.on('touchmove', this.onMove);
        this.map?.once('touchend', this.onUp);
      }
    }, 700);
  };

  // Наведение курсора на элемент
  handleMouseMove = (e: any, type: string) => {
    const drawState = store.getState().map.drawState;

    // Для линий
    if (type === 'lines' && this.cursor !== 'grabbing' && !drawState.isActive) {
      this.hoveredStateId = e.features[0].id;
      this.handleMouseLeave('nodes');
      this.setCursorType('pointer');
      if (this.hoveredStateId !== null) {
        this.map.setFeatureState(
          { source: 'lines', id: this.hoveredStateId },
          { hover: false },
        );
      }
      this.map.setFeatureState(
        { source: type, id: this.hoveredStateId },
        { hover: true },
      );
    }
    // Для точек
    else if (type === 'nodes') {
      if (this.cursor === 'pointer' || this.cursor === 'grabbing') return;
      if (!drawState.isActive) {
        this.handleMouseLeave('lines');
        this.setCursorType('pointer');
      }

      const { properties, layer, geometry } = e.features[0];
      const coordinates = geometry.coordinates;
      const sourceId = `${layer.source}_hovered`;
      const currentSource: any = this.getSources(sourceId);
      const hoverElements = [
        this.prepareFeature(sourceId, 'Point', properties, coordinates),
      ];

      const hoverData = {
        type: 'FeatureCollection',
        features: hoverElements,
      };

      currentSource.setData(hoverData);

      this.activeIconId = sourceId;
    }
  };

  // Покидание курсора элемента
  handleMouseLeave = (type: string) => {
    const drawState = store.getState().map.drawState;
    if (!drawState.isActive) {
      // Для линий
      if (type === 'lines' && this.cursor !== 'grabbing') {
        this.setCursorType('grab');
        this.map.setFeatureState(
          { source: 'lines', id: this.hoveredStateId },
          { hover: false },
        );
        this.hoveredStateId = null;
      }
      // Для точек
      else if (this.activeIconId) {
        const currentSource: any = this.getSources(this.activeIconId);
        if (this.cursor !== 'grabbing') {
          this.setCursorType('grab');
        }

        currentSource.setData({
          type: 'FeatureCollection',
          features: [],
        });
        this.activeIconId = null;
      }

      // // Режим добавления элементов
      // if (!this.drawState?.isActive && !this.isMoving) {
      //   this.map.getCanvasContainer().style.cursor = 'grab';
      //   this.cursor = 'grab';
      // }
    }
  };
}

export default MapDraw;
