import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../../Components/Button';
import { components } from '../../../../../generated/apiTypes';
import {
  getWellModel,
  setWellModel as saveNewWellModel,
} from '../../../../../services/apiRequests';
import { selectActiveObject } from '../../../../../store/mapSlice';
import {
  removeUpdatedModel,
  selectProjectId,
  selectWellModelById,
  updateWellModal,
} from '../../../../../store/projectSlice';
import { IChart } from './types';
import { getOptions } from './utils';

import '../../../ResultsPanel/chartStyle.scss';

const Chart: React.FC<IChart> = ({
  chartType,
  wellOperationType,
  chartData,
  tableState,
  setTableState,
}) => {
  const dispatch = useDispatch();
  const projectUid = useSelector(selectProjectId);
  const wellsModelsById = useSelector(selectWellModelById);
  const activeObject = useSelector(selectActiveObject);
  const [wellModel, setWellModel] = React.useState<
    components['schemas']['PNOWellModel']
  >();

  React.useEffect(() => {
    setWellModel(wellsModelsById[activeObject.object_uid as string]);
  }, [
    activeObject.object_uid,
    wellsModelsById[activeObject.object_uid as string],
  ]);

  const getServerWellModel = async () => {
    try {
      const response = await getWellModel(
        projectUid as string,
        activeObject.object_uid as string,
      );
      if (response.data) {
        dispatch(removeUpdatedModel(activeObject.object_uid as string));
        dispatch(updateWellModal(response.data));
      }
    } catch (e) {
      //
    }
  };

  const saveWellModel = async () => {
    if (wellModel) {
      try {
        const response = await saveNewWellModel(
          projectUid as string,
          activeObject.object_uid as string,
          wellModel,
        );
        if (response.data) {
          dispatch(removeUpdatedModel(activeObject.object_uid as string));
        }
      } catch (e) {
        //
      }
    }
  };

  const onCancelClick = () => {
    getServerWellModel();
    setTableState('view');
  };

  const onSaveClick = () => {
    saveWellModel();
    setTableState('view');
  };
  return (
    <>
      <div className="results__single-chart">
        <div className="well-model-panel__chart-container">
          <Line
            type="line"
            data={chartData}
            options={getOptions(chartType, wellOperationType)}
          />
        </div>
      </div>
      <div className="well-model-panel__buttons">
        {tableState === 'view' && (
          <Button
            type="button"
            mode="secondary"
            onClick={() => {
              setTableState('edit');
            }}
          >
            Редактировать
          </Button>
        )}
        {tableState === 'edit' && (
          <>
            <Button
              type="button"
              className="well-model-panel__save-btn"
              onClick={onSaveClick}
            >
              Сохранить
            </Button>
            <Button type="button" mode="secondary" onClick={onCancelClick}>
              Отменить
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Chart;
