import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { selectSolution } from 'store/taskSlice';

import { components } from '../../../../../generated/apiTypes';
import TableWrapper from '../../components/TableWrapper';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { segmentsTableHead, templateRowsNumber } from './utils';

const Table: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNONetworkFactAdaptationProblem'];
  const headKeys = Object.keys(segmentsTableHead);
  const [data, setData] = React.useState<any[]>([]);

  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.segment_table?.integral_segments_states.forEach(
      node_state => {
        const pipelineSegment = solution?.solution?.segment_table.pipeline_segments.find(
          node => node.uid === node_state.segment_uid,
        );
        newData.push({
          start_node_name: solution?.task?.network.nodes.find(
            node => node.uid === pipelineSegment?.start_uid,
          )?.name,
          end_node_name: solution?.task?.network.nodes.find(
            node => node.uid === pipelineSegment?.end_uid,
          )?.name,
          length: pipelineSegment?.length,
          diameter:
            pipelineSegment?.diameter && pipelineSegment?.diameter * 1000,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          aspo_thickness: node_state?.aspo?.mm,
          wall_thickness: pipelineSegment?.wall_thickness,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          p_in: node_state?.pressure_in?.atm,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fluid_flow_rate: node_state?.fluid_flow_rate_start_node?.m3_per_day,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          oil_flow_rate: node_state?.oil_flow_rate_start_node?.t_per_day,
          gas_flow_rate:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            node_state?.gas_flow_rate_start_node?.thousand_m3_per_day,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dp_dl: node_state?.dp_dl?.atm_per_km,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dt_dl: node_state?.dt_dl?.celsius_per_km,
        });
      },
    );
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__nodes-table')}>
        <TableHead headKeys={headKeys} tableHead={segmentsTableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default Table;
