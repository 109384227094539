import React from 'react';

import classNames from 'classnames';

import { ReactComponent as Avatar } from '../../images/Projects/default-avatar.svg';
import { ReactComponent as LogoutIcon } from '../../images/Projects/icn-logout.svg';
import { handleStorage } from '../../services/handleStorage';
import MenuList from '../Project/ProjectHeader/menuList';

interface IProjectsHeader {
  logout(): void;
}

const ProjectsHeader: React.FC<IProjectsHeader> = ({ logout }) => {
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const controlRef = React.useRef<HTMLButtonElement>(null);
  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };
  const closeMenu = () => {
    setActiveMenu(false);
  };
  return (
    <div className="projects__header">
      <div className="projects-logo" />
      <div className="projects-user-info-wrapper">
        <div className="projects-user-info">
          <div className="projects-user-info-user-name">
            {handleStorage.getUserName()}
          </div>
          <button
            type="button"
            className={classNames(
              'projects-user-info-default-avatar',
              activeMenu && 'active',
            )}
            onClick={toggleMenu}
          >
            <Avatar className="projects__avatar" />
          </button>
        </div>
        {activeMenu && (
          <MenuList
            closeMenu={closeMenu}
            controlRef={controlRef}
            className="projects__user-menu-list"
          >
            <button
              type="button"
              className="project-header__menu-list-item"
              onClick={logout}
            >
              <div className="project-header__menu-list-item-delete">
                <LogoutIcon className="project-header__icon-delete" />
                Выйти
              </div>
            </button>
          </MenuList>
        )}
      </div>
    </div>
  );
};

export default ProjectsHeader;
