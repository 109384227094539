import * as React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { selectSolution } from 'store/taskSlice';

import { components } from '../../../../../../generated/apiTypes';
import StepInput from '../../../components/StepInput';
import SourcesAvgTable from './AvgTable';
import SourcesByStepTable from './ByStepTable';
import { wellfieldsAvgTableHead, wellfieldsByStepTableHead } from './utils';

const Table: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechNetworkOptimizationProblem'];
  const [tabIndex, setTabIndex] = React.useState(0);
  const initStep = '1';
  const [step, setStep] = React.useState<string>(initStep);

  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className="results__tab-list tables-tab-list">
          <div className="results__table-tabs">
            <Tab className="results__tab">Средние</Tab>
            <Tab className="results__tab">По шагам</Tab>
          </div>
          <StepInput
            value={step}
            setStep={setStep}
            tabIndex={tabIndex}
            initStep={initStep}
            max={
              solution?.solution?.well_step_table?.time2states
                ? Object.entries(
                    solution?.solution.well_step_table?.time2states,
                  ).length.toString()
                : '1'
            }
          />
        </TabList>
        <div>
          <TabPanel>
            <SourcesAvgTable
              tableHead={wellfieldsAvgTableHead}
              wellsControlModels={solution?.task?.wells_control_models}
            />
          </TabPanel>
          <TabPanel>
            <SourcesByStepTable
              tableHead={wellfieldsByStepTableHead}
              step={parseInt(step, 10)}
              wellsControlModels={solution?.task?.wells_control_models}
            />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default Table;
