import { ChartOptions } from 'chart.js';

export const seriesColors = [
  '#019DDC',
  '#FF72BE',
  '#FAB400',
  '#23D692',
  '#FA8700',
  '#F9D100',
  '#F153FF',
  '#644BA5',
  '#FA3C00',
];

export const options: ChartOptions = {
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        pointStyle: 'line',
        usePointStyle: true,
      },
    },
  },
  scales: {
    y: {
      ticks: {
        padding: 8,
        color: '#999999',
        font: {
          size: 10,
          family: 'Inter, sans-serif',
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        padding: 8,
        color: '#999999',
        font: {
          size: 10,
          family: 'Inter, sans-serif',
        },
      },
    },
  },
};

export const dropdownOptions = [
  { label: 'Qн, т/сут', value: 'oil_flow_rate_mass' },
  { label: 'Qж, м3/сут', value: 'fluid_flow_rate_volume' },
  { label: 'Qг, тыс. м3/сут', value: 'gas_flow_rate_volume' },
  { label: 'P, атм', value: 'pressure' },
  { label: 't, °С', value: 'temperature' },
];
