import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';

import classNames from 'classnames';

import { ReactComponent as DeleteIcon } from '../../../images/Project/icn-delete-project.svg';
import { ReactComponent as MenuIcon } from '../../../images/Project/menu-icon.svg';
import {
  getExcelProjectFile,
  getJSONProjectFile,
} from '../../../services/apiRequests';
import { setShowDialog } from '../../../store/commonSlice';
import { selectProjectName } from '../../../store/projectSlice';
import { IMenuPanel } from '../types';
import MenuList from './menuList';

const MenuPanel: React.FC<IMenuPanel> = ({ title, history }) => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const projectName = useSelector(selectProjectName);
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const controlRef = React.useRef<HTMLButtonElement>(null);
  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };
  const closeMenu = () => {
    setActiveMenu(false);
  };
  const downloadJSONFile = async () => {
    setActiveMenu(false);
    try {
      const response = await getJSONProjectFile(params.id);
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${title}.json`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      //
    }
  };
  const downloadExcelFile = async () => {
    setActiveMenu(false);
    try {
      const response = await getExcelProjectFile(params.id);
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${title}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      //
    }
  };

  const onDeleteProject = () => {
    dispatch(
      setShowDialog({
        dialogType: 'DELETE_CONFIRM',
        objectType: 'PROJECT',
        objectName: projectName,
        objectId: params.id,
      }),
    );
  };
  return (
    <div>
      <div className="d-flex">
        <button
          className={classNames(
            'project-header__menu-icon-wrapper',
            activeMenu && 'active',
          )}
          type="button"
          ref={controlRef}
          onClick={toggleMenu}
        >
          <MenuIcon className="project-header__menu-icon" />
        </button>
        <h1 className="project-header__title">{title}</h1>
      </div>
      {activeMenu && (
        <MenuList closeMenu={closeMenu} controlRef={controlRef}>
          <button
            className="project-header__menu-list-item"
            type="button"
            onClick={downloadExcelFile}
          >
            Экспорт в Excel
          </button>
          <button
            className="project-header__menu-list-item"
            type="button"
            onClick={downloadJSONFile}
          >
            Экспорт в JSON
          </button>
          <div className="project-header__menu-list-separator" />
          <button
            type="button"
            className="project-header__menu-list-item"
            onClick={onDeleteProject}
          >
            <div className="project-header__menu-list-item-delete">
              <DeleteIcon className="project-header__icon-delete" />
              Удалить проект
            </div>
          </button>
          <div className="project-header__menu-list-separator" />
          <button
            type="button"
            className="project-header__menu-list-item"
            onClick={() => {
              history.push('/');
            }}
          >
            К списку проектов
          </button>
        </MenuList>
      )}
    </div>
  );
};

export default withRouter(MenuPanel);
