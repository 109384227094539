import * as React from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { selectSolution } from 'store/taskSlice';

import Skeleton from '../../../../../Components/Skeleton';
import { components } from '../../../../../generated/apiTypes';
import { dashboardItems } from './utils';

const Dashboard: React.FC = () => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNONetworkFactAdaptationProblem'];
  const [data, setData] = React.useState<any>(null);

  const getData = () => {
    const newData = {
      pressures_sko:
        solution?.solution?.integral_characteristic
          ?.standard_deviation_on_wells,
      pressures_avg_abs_error:
        solution?.solution?.integral_characteristic?.average_absolute_error,
      pressures_avg_rel_error:
        solution?.solution?.integral_characteristic?.average_relative_error,
      pressures_max_abs_error:
        solution?.solution?.integral_characteristic?.max_absolute_error,
      pressures_max_rel_error:
        solution?.solution?.integral_characteristic?.max_relative_error,
    };
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);
  return (
    <div className="dashboard">
      {data &&
        dashboardItems(data)?.map((el, key) => {
          return (
            <div key={key} className={classNames('dashboard__item af_item')}>
              <p className="dashboard__item-dem">{el.dem}</p>
              <div className="dashboard__bottom">
                <p className="dashboard__item-value">
                  {el.value === undefined ? (
                    <Skeleton className={classNames('skeleton-dashboard')} />
                  ) : (
                    el.value
                  )}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Dashboard;
