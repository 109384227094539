import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { RootState } from './store';
import { IProjectsSlice } from './types';

const projectsInitialState = initialState as IProjectsSlice;

export const projectsSlice = createSlice({
  name: 'projects',
  initialState: { projects: projectsInitialState.projects },
  reducers: {
    setProjects: (
      state,
      action: PayloadAction<IProjectsSlice['projects']['items']>,
    ) => {
      state.projects = {
        items: action.payload,
        isFetching: true,
      };
    },
    resetProjectsSliceState: state => {
      state.projects = projectsInitialState.projects;
    },
  },
});

export const { setProjects, resetProjectsSliceState } = projectsSlice.actions;

const selectSelf = (state: RootState) => state.projects;

export const selectProjects = createSelector(
  selectSelf,
  projects => projects.projects.items,
);

export default projectsSlice.reducer;
