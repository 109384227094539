import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as EditIcon } from 'images/Project/icn-pencil.svg';
import { selectActiveObject } from 'store/mapSlice';
import {
  addUpdatedModel,
  removeUpdatedModel,
  selectProjectId,
  selectWellModelById,
  updateWellModal,
} from 'store/projectSlice';

import Button from '../../../../../Components/Button';
import { components } from '../../../../../generated/apiTypes';
import {
  getWellModel,
  setWellModel as saveNewWellModel,
} from '../../../../../services/apiRequests';
import { oneDigitsFormatter } from '../../../../../utils';
import ParameterInput from './ParameterInput';
import { IEditModel } from './types';
import {
  getInitTableByType,
  getTableHeaderByType,
  getTableValueByType,
} from './utils';

const EditModel: React.FC<IEditModel> = ({
  tableType,
  wellOperationType,
  setViewMode,
}) => {
  const dispatch = useDispatch();
  const projectUid = useSelector(selectProjectId);
  const wellsModelsById = useSelector(selectWellModelById);
  const activeObject = useSelector(selectActiveObject);
  const [tableData, setTableData] = React.useState<
    components['schemas']['PNOWellModel']
  >();
  const [wellModel, setWellModel] = React.useState<
    components['schemas']['PNOWellModel']
  >();

  React.useEffect(() => {
    setWellModel(wellsModelsById[activeObject.object_uid as string]);
  }, [
    activeObject.object_uid,
    wellsModelsById[activeObject.object_uid as string],
  ]);

  React.useEffect(() => {
    if (wellModel) {
      const initData = getInitTableByType(tableType, wellModel);
      if (initData.model) {
        dispatch(updateWellModal(initData.model));
        setTableData(initData.model);
      }
      if (initData.modified)
        dispatch(addUpdatedModel(activeObject.object_uid as string));
    }
  }, [wellModel]);

  const getServerWellModel = async () => {
    try {
      const response = await getWellModel(
        projectUid as string,
        activeObject.object_uid as string,
      );
      if (response.data) {
        dispatch(removeUpdatedModel(activeObject.object_uid as string));
        dispatch(updateWellModal(response.data));
      }
    } catch (e) {
      //
    }
  };

  const saveWellModel = async () => {
    if (wellModel) {
      try {
        const response = await saveNewWellModel(
          projectUid as string,
          activeObject.object_uid as string,
          wellModel,
        );
        if (response.data) {
          dispatch(removeUpdatedModel(activeObject.object_uid as string));
        }
      } catch (e) {
        //
      }
    }
  };

  const onCancelClick = () => {
    getServerWellModel();
    setViewMode();
  };

  const onSaveClick = () => {
    saveWellModel();
    setViewMode();
  };

  return (
    <>
      <div className="well-model-panel__tables-wrapper">
        <table className="well-model-panel__non-scrollable-table">
          <thead>
            <tr>
              <th
                rowSpan={2}
                colSpan={2}
                className="well-model-panel__table-cell well-model-panel__table-header-cell"
              >
                {' '}
              </th>
              <th
                colSpan={tableData?.pressures.length}
                className="well-model-panel__table-cell well-model-panel__table-header-cell caption-cell"
              >
                P лин, атм
              </th>
            </tr>
            <tr>
              {tableData?.pressures?.map(() => (
                <th className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell right-aligned">
                  {' '}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.control_actions?.map((item, control_action_index) => (
              <tr>
                {control_action_index === 0 && (
                  <td
                    rowSpan={tableData?.control_actions?.length}
                    className="well-model-panel__table-cell well-model-panel__table-header-cell vertical-text"
                  >
                    {getTableHeaderByType(wellOperationType)}
                  </td>
                )}
                <td className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell control-action-cell right-aligned">
                  {wellOperationType === 'Газлифт'
                    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      item?.m3_per_day &&
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      oneDigitsFormatter.format(item?.m3_per_day)
                    : item?.hertz && oneDigitsFormatter.format(item?.hertz)}
                </td>
                {tableData?.pressures.map(() => (
                  <td> </td>
                ))}
              </tr>
            ))}
            <tr>
              <td className="well-model-panel__table-bottom-header-cell"> </td>
              <td className="well-model-panel__table-bottom-header-cell"> </td>
              {tableData?.pressures.map(() => (
                <td className="well-model-panel__table-bottom-cell"> </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="well-model-panel__scrollable-table-wrapper">
          <table className="well-model-panel__scrollable-table">
            <thead>
              <tr>
                {tableData?.pressures?.map(item => (
                  <th className="well-model-panel__table-cell well-model-panel__table-header-cell value-cell pressure-cell right-aligned">
                    {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      item?.atm && oneDigitsFormatter.format(item?.atm)
                    }
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData?.control_actions?.map((item, control_action_index) => (
                <tr>
                  {tableData?.pressures.map((val, pressure_index) => (
                    <td className="well-model-panel__table-cell value-cell pressure-cell editable-cell">
                      <ParameterInput
                        tableType={tableType}
                        initValue={
                          getTableValueByType(
                            tableType,
                            tableData,
                            pressure_index,
                            control_action_index,
                          ) as string
                        }
                        pressure_index={pressure_index}
                        control_action_index={control_action_index}
                      />
                      <EditIcon className="editable-cell-icon" />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="well-model-panel__buttons">
        <Button
          type="button"
          className="well-model-panel__save-btn"
          onClick={onSaveClick}
        >
          Сохранить
        </Button>
        <Button type="button" mode="secondary" onClick={onCancelClick}>
          Отменить
        </Button>
      </div>
    </>
  );
};

export default EditModel;
