import { components } from 'generated/apiTypes';

import { ExperimentType } from '../types';

type Issues = components['schemas']['PNOProjectValidationResult'];

type Issue = components['schemas']['PNOProjectIssue'][];

type ComposeIssues = (type: ExperimentType) => (issues: Issues) => Issue;

export const composeIssues: ComposeIssues = type => issues => {
  if (type === 'Узловой анализ') {
    return issues.nodal_analysis_issues;
  }
  if (type === 'Гидравлический расчет') {
    return issues.hydraulic_issues;
  }
  if (type === 'Адаптация на факт') {
    return issues.calibration_issues;
  }
  if (type === 'Оптимизация режима') {
    return issues.tech_mode_optimization_issues;
  }
  return [];
};
