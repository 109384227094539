import * as React from 'react';

interface ILegendButton {
  uid: string;
  label: string;
  active: boolean;
  toggleLegendButton(uid: string): void;
}

const LegendButton: React.FC<ILegendButton> = ({
  uid,
  label,
  active,
  toggleLegendButton,
}) => {
  return (
    <button
      style={{
        backgroundColor: active ? '#f5f5f5' : 'white',
      }}
      type="button"
      onClick={() => toggleLegendButton(uid)}
    >
      <p>{label}</p>
    </button>
  );
};

export default LegendButton;
