import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import classNames from 'classnames';

import { ReactComponent as AppliedIcon } from '../../../images/Project/icn-aspo-applied.svg';
import { ReactComponent as AspoIcon } from '../../../images/Project/icn-aspo-reset.svg';
import { ReactComponent as ProgressIcon } from '../../../images/Project/progress-icon.svg';
import { resetAspo } from '../../../services/apiRequests';
import {
  selectSegments,
  setNodes,
  setSegments,
} from '../../../store/projectSlice';

const AllSegments: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const segments = useSelector(selectSegments);
  const [resetingAspo, setResetingAspo] = React.useState<boolean>(false);
  const [aspoReseted, setAspoReseted] = React.useState<boolean>(false);
  const [nonZeroAspos, setNonZeroAspos] = React.useState<boolean>(false);

  React.useEffect(() => {
    const nonZero: boolean = segments.items.some(item => {
      return item.aspo?.m && item.aspo?.m !== 0;
    });
    setNonZeroAspos(nonZero);
  }, [segments.isFetching]);

  const onResetAspoClick = async () => {
    try {
      setResetingAspo(true);
      const response = await resetAspo(params.id);
      dispatch(setNodes(response?.data?.nodes));
      dispatch(setSegments(response?.data?.segments));
      setAspoReseted(true);
    } catch (e) {
      //
    }
    setResetingAspo(false);
  };

  const buttonByState = () => {
    if (resetingAspo)
      return (
        <>
          <ProgressIcon className="all-segments__progress-icon" />
          <p>Идёт сброс...</p>
        </>
      );
    if (aspoReseted)
      return (
        <>
          <AppliedIcon className="all-segments__reseted-icon" />
          <p>АСПО сброшены</p>
        </>
      );
    return (
      <>
        <AspoIcon className="all-segments__aspo-icon" />
        <p>Сбросить АСПО</p>
      </>
    );
  };
  return (
    <div>
      <div className="active-object__top-row">
        <p className="active-object__title">Все трубопроводы</p>
      </div>
      <div>
        <div className="active-object__row">
          <p className="active-object__subtitle aspo-subtitle">Толщина АСПО</p>
          <button
            type="button"
            className={classNames('active-object__reset-aspo-btn', {
              resetingAspo,
            })}
            onClick={onResetAspoClick}
            disabled={resetingAspo || aspoReseted || !nonZeroAspos}
          >
            {buttonByState()}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllSegments;
