import * as React from 'react';

import { formatter } from '../../../../../utils';

export const dashboardItems = ({
  fluid_flow_rate_avg,
  fluid_flow_rate_min,
  fluid_flow_rate_max,
  oil_flow_rate_avg,
  oil_flow_rate_min,
  oil_flow_rate_max,
  gas_flow_rate_avg,
  gas_flow_rate_min,
  gas_flow_rate_max,
  pressure_avg,
  temperature_avg,
}: any = {}) => [
  {
    dem: 'Qн, т/сут',
    value:
      oil_flow_rate_avg !== undefined
        ? formatter.format(oil_flow_rate_avg)
        : oil_flow_rate_avg,
    min:
      oil_flow_rate_min !== undefined
        ? formatter.format(oil_flow_rate_min)
        : oil_flow_rate_min,
    max:
      oil_flow_rate_max !== undefined
        ? formatter.format(oil_flow_rate_max)
        : oil_flow_rate_max,
  },
  {
    dem: (
      <>
        Qж,{' '}
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
    value:
      fluid_flow_rate_avg !== undefined
        ? formatter.format(fluid_flow_rate_avg)
        : fluid_flow_rate_avg,
    min:
      fluid_flow_rate_min !== undefined
        ? formatter.format(fluid_flow_rate_min)
        : fluid_flow_rate_min,
    max:
      fluid_flow_rate_max !== undefined
        ? formatter.format(fluid_flow_rate_max)
        : fluid_flow_rate_max,
  },
  {
    dem: (
      <>
        Qг,{' тыс '}
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
    value:
      gas_flow_rate_avg !== undefined
        ? formatter.format(gas_flow_rate_avg / 1000)
        : gas_flow_rate_avg,
    min:
      gas_flow_rate_min !== undefined
        ? formatter.format(gas_flow_rate_min / 1000)
        : gas_flow_rate_min,
    max:
      gas_flow_rate_max !== undefined
        ? formatter.format(gas_flow_rate_max / 1000)
        : gas_flow_rate_max,
  },
  {
    dem: 'P, атм',
    value:
      pressure_avg !== undefined
        ? formatter.format(pressure_avg)
        : pressure_avg,
    style: 'short',
  },
  {
    dem: 't, ˚C',
    value:
      temperature_avg !== undefined
        ? formatter.format(temperature_avg)
        : temperature_avg,
    style: 'short',
  },
];
