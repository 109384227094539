import * as React from 'react';

export const templateRowsNumber = 5;

export const segmentsTableHead = {
  start_node_name: {
    title: 'V0',
    component: (
      <>
        V<span className="subscript">0</span>
      </>
    ),
  },
  end_node_name: {
    title: 'V1',
    component: (
      <>
        V<span className="subscript">1</span>
      </>
    ),
  },
  length: 'L, м',
  diameter: 'D, мм',
  aspo_thickness: {
    title: 'Толщина АСПО, мм',
    component: (
      <>
        Толщина
        <br />
        АСПО, мм
      </>
    ),
  },
  wall_thickness: 'S, м',
  p_in: {
    title: 'P0, атм',
    component: (
      <>
        Р<span className="subscript">0</span>,
        <br /> атм
      </>
    ),
  },
  oil_flow_rate: {
    title: 'Qн, т/сут',
    component: (
      <>
        Qн,
        <br />
        т/cут
      </>
    ),
  },
  fluid_flow_rate: {
    title: 'Qж, м3/сут',
    component: (
      <>
        Qж,
        <br />
        <span className="relative">
          м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  gas_flow_rate: {
    title: 'Qг, тыс. м3/сут',
    component: (
      <>
        Qг, <br />
        <span className="relative">
          тыс. м<span className="index">3</span>
        </span>
        &nbsp; /сут
      </>
    ),
  },
  dp_dl: {
    title: 'ΔP/ΔL, атм/км',
    component: (
      <>
        ΔP/ΔL,
        <br />
        атм/км
      </>
    ),
  },
  dt_dl: {
    title: 'Δt/ΔL, °С/км',
    component: (
      <>
        Δt/ΔL,
        <br />
        °С/км
      </>
    ),
  },
};
