import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { selectSolution } from 'store/taskSlice';

import { components } from '../../../../../../generated/apiTypes';
import TableWrapper from '../../../components/TableWrapper';
import { INodesTableProps } from '../../../types';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

const NodesAvgTable: React.FC<INodesTableProps> = ({ tableHead }) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechModeOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any[] = [];
    solution?.solution?.node_avg_table?.nodes?.forEach(node => {
      const stateById = solution?.solution?.node_avg_table?.node_states.find(
        val => val.node_uid === node.uid,
      );
      newData.push({
        name: node?.name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fluid_flow_rate: stateById?.fluid_flow_rate_volume?.m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        oil_flow_rate: stateById?.oil_flow_rate_mass?.t_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gas_flow_rate: stateById?.gas_flow_rate_volume?.thousand_m3_per_day,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        temperature_avg: stateById?.temperature?.celsius,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pressure_avg: stateById?.pressure?.atm,
      });
    });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__nodes-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return <TableRow key={index} headKeys={headKeys} el={value} />;
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default NodesAvgTable;
