import dayjs from 'dayjs';

import { components } from '../../generated/apiTypes';

export const sortByDate = () => {
  return (
    a: components['schemas']['PNOProjectData'],
    b: components['schemas']['PNOProjectData'],
  ) => {
    if (dayjs(a.project_timestamp) < dayjs(b.project_timestamp)) return 1;
    if (dayjs(a.project_timestamp) > dayjs(b.project_timestamp)) return -1;
    return 0;
  };
};
