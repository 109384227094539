import { IMapSlice } from '../../../../store/types';

export const getSources = (mapContext: any, sourceId?: string) => {
  const sources: any = sourceId
    ? mapContext?.getSource(sourceId)
    : mapContext?.getStyle().sources;

  return sources;
};

export const deleteFeature = (
  mapContext: any,
  uid: string | null | undefined,
) => {
  const sources = getSources(mapContext);

  if (sources && Object.keys(sources).length) {
    Object.keys(sources).forEach((item: any) => {
      if (
        item.includes('lines') ||
        item.includes('junction') ||
        item.includes('sink') ||
        item.includes('source')
      ) {
        if (sources[item].data.features.length) {
          const sourceData: any = getSources(mapContext, item);
          let features = sourceData._data.features.filter(
            (feature: any) => feature.properties.uid === uid,
          );

          if (features.length) {
            features = sourceData._data.features.filter(
              (feature: any) => feature.properties.uid !== uid,
            );
            sourceData.setData({
              type: 'FeatureCollection',
              features,
            });
          }
        }
      }
    });
  }
};

export const prepareFeature = (
  mapContext: any,
  sourceId: string,
  geomenryType: string,
  properties?: any,
  coordinates?: any,
) => {
  const sources: any = getSources(mapContext, sourceId);
  const featuresIds = sources._data.features.map((item: any) => {
    return item.id;
  });

  const id = featuresIds.length
    ? featuresIds[featuresIds.length - 1].id + 1
    : 1;

  const feature = {
    type: 'Feature',
    id,
    properties: properties || {},
    geometry: {
      type: geomenryType,
      coordinates: coordinates || [],
    },
  };

  return feature;
};

export const disableElementSelected = (mapContext: any) => {
  const sources: any = getSources(mapContext);
  if (sources && Object.keys(sources).length) {
    Object.keys(sources).forEach((item: any) => {
      if (
        item.includes('selected') ||
        item.includes('hovered') ||
        item.includes('edit') ||
        item.includes('draw')
      ) {
        if (sources[item].data.features.length) {
          const sourceData: any = getSources(mapContext, item);
          sourceData.setData({
            type: 'FeatureCollection',
            features: [],
          });
        }
      }
    });
  }
};

export const setElementSelected = (
  mapContext: any,
  activeObject: IMapSlice['activeObject'],
) => {
  console.log('setElementSelected -> activeObject', activeObject);
  let sourceId: any = activeObject.type?.toLowerCase();
  if (sourceId === 'segment') sourceId = 'lines';
  const currentSource: any = getSources(mapContext, sourceId);

  const coordinates = currentSource._data.features.find(
    (item: any) =>
      item.properties?.uid ===
      (sourceId !== 'source' ? activeObject.object_uid : activeObject.node_uid),
  )?.geometry?.coordinates;

  const feature = currentSource._data.features.filter(
    (item: any) =>
      item.properties?.uid ===
      (sourceId !== 'source' ? activeObject.object_uid : activeObject.node_uid),
  );

  const obj = {
    ...currentSource._data,
    features: feature,
  };

  const selectedSource: any = mapContext?.getSource(`${sourceId}_selected`);

  selectedSource.setData(obj);

  if (coordinates) {
    mapContext?.flyTo({
      center: Array.isArray(coordinates[0]) ? coordinates[0] : coordinates,
      maxDuration: 500,
      essential: true,
    });
  }
};

// export const handleMouseClick = () => {};

export const handleMouseMove = (
  mapContext: any,
  event: any,
  stateId: string,
  type: string,
) => {
  if (type === 'lines') {
    mapContext.getCanvasContainer().style.cursor = 'pointer';
    if (stateId !== null) {
      mapContext.setFeatureState(
        { source: 'lines', id: stateId },
        { hover: false },
      );
    }
    mapContext.setFeatureState({ source: type, id: stateId }, { hover: true });
  } else {
    const cursor = mapContext?.getCanvasContainer().style.cursor;
    if (cursor === 'pointer') return;
    const { properties, layer, geometry } = event.features[0];
    const coordinates = geometry.coordinates;
    const sourceId = `${layer.source}_hovered`;
    const currentSource: any = getSources(mapContext, sourceId);
    const hoverElements = [
      prepareFeature(mapContext, sourceId, 'Point', properties, coordinates),
    ];

    const hoverData = {
      type: 'FeatureCollection',
      features: hoverElements,
    };

    currentSource.setData(hoverData);
  }
};

export const handleMouseLeave = (
  mapContext: any,
  stateId: string | null,
  type: string,
) => {
  if (type === 'lines') {
    mapContext.getCanvasContainer().style.cursor = 'grab';
    mapContext.setFeatureState(
      { source: 'lines', id: stateId },
      { hover: false },
    );
  } else if (stateId) {
    const currentSource: any = getSources(mapContext, stateId);
    currentSource.setData({
      type: 'FeatureCollection',
      features: [],
    });
  }
};
