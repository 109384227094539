import * as React from 'react';

import classnames from 'classnames';

import Input from '../../Components/Input';
import { FormFieldPropsType } from './types';

const FormField: React.FC<FormFieldPropsType> = React.memo(
  ({ type, placeholder, onChangeHandler, formValue, error, onEnter }) => {
    const [value, setValue] = React.useState<string>('');

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
      onChangeHandler(e, formValue);
      setValue(e.currentTarget.value);
    };

    return (
      <div
        className={classnames('login-page-input-container relative', formValue)}
      >
        <Input
          value={value}
          type={type}
          label={placeholder}
          onChange={onChange}
          error={error}
          onEnter={onEnter}
        />
      </div>
    );
  },
);

export default FormField;
