import { components } from '../../../../generated/apiTypes';
import {
  ViscosityType,
  WellControlType,
  WellOperationType,
  WellPidStateType,
} from '../../types';
import { OptionType } from './ObjectSelect';

export const getWellOperationTypeOptions = () => {
  const wellOperationTypes: WellOperationType[] = ['Фонтан', 'Газлифт', 'ЭЦН'];
  const wellOperationTypeOptions: OptionType<
    components['schemas']['PNOWellOperationType']
  >[] = Object.keys(wellOperationTypes).map(key => {
    return {
      value: (wellOperationTypes as any)[key],
      label: (wellOperationTypes as any)[key],
    };
  });
  return wellOperationTypeOptions;
};

export const getWellControlTypeOptions = () => {
  const wellControlTypes: WellControlType[] = ['ПДФ', 'ПКВ', 'ЧЧ'];
  const wellControlTypeOptions: OptionType<WellControlType>[] = Object.keys(
    wellControlTypes,
  ).map(key => {
    return {
      value: (wellControlTypes as any)[key],
      label: (wellControlTypes as any)[key],
    };
  });
  return wellControlTypeOptions;
};

export const getWellPidStateOptions = () => {
  const wellPidStateTypes: WellPidStateType[] = [
    'Отсутствует',
    'Ток',
    'Загрузка',
  ];
  const wellPidStateTypeOptions: OptionType<
    components['schemas']['PNOPidType']
  >[] = Object.keys(wellPidStateTypes).map(key => {
    return {
      value: (wellPidStateTypes as any)[key],
      label: (wellPidStateTypes as any)[key],
    };
  });
  return wellPidStateTypeOptions;
};

export const getHeatTransferCoefficientOptions = () => {
  const options: { value: number; label: string }[] = [
    { value: 1.1348934, label: 'Изолированный' },
    { value: 11.348934, label: 'С покрытием' },
    { value: 113.48934, label: 'Без изоляции (воздух)' },
    { value: 1134.8934, label: 'Без изоляции (вода)' },
    { value: 0.1, label: 'Пользовательский' },
  ];
  return options;
};

export const getViscosityTypeOptions = () => {
  const viscosityTypes: { value: ViscosityType; label: string }[] = [
    { value: 'TWO_POINTS', label: 'Две точки' },
    { value: 'STANDING', label: 'Стендинг' },
    { value: 'ROBINSON', label: 'Беггз-Робинсон' },
    { value: 'TABLE', label: 'Таблица' },
  ];
  return viscosityTypes;
};

export const getWaterFractionOptions = () => {
  const waterFractionTypes: { value: string; label: string }[] = [
    { value: 'wct', label: 'WCut' },
    { value: 'wor', label: 'WOR' },
  ];
  return waterFractionTypes;
};

export const getGasFractionOptions = () => {
  const gasFractionTypes: { value: string; label: string }[] = [
    { value: 'gas_factor', label: 'ГФ' },
    { value: 'gor', label: 'GOR' },
  ];
  return gasFractionTypes;
};

export const getSegmentLengthOptions = () => {
  const segmentLengthTypes: { value: string; label: string }[] = [
    { value: 'Простой', label: 'Простой' },
    { value: 'Высотный профиль', label: 'Высотный проф' },
  ];
  return segmentLengthTypes;
};
