import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectExperimentStatus,
  selectExperimentType,
  selectSolution,
  setExperimentStatus,
} from 'store/taskSlice';

import TaskStatus from '../ProjectHeader/TaskStatus';
import AspoButton from './components/AspoButton';
import ErrorDetails from './components/ErrorDetails';
import ExportButton from './components/ExportButton';
import SegmentsScale from './components/SegmentsScale';
import AFDashboard from './Dashboard/AFDashboard';
import GRDashboard from './Dashboard/GRDashboard';
import MODashboard from './Dashboard/MODashboard';
import NODashboard from './Dashboard/NODashboard';
import UADashboard from './Dashboard/UADashboard';
import AFResults from './Results/AFResults';
import GRResults from './Results/GRResults';
import MOResults from './Results/MOResults';
import NOResults from './Results/NOResults';
import UAResults from './Results/UAResults';

import './style.scss';

interface IResultsPanel {
  taskId: string | null;
}

const ResultsPanel: React.FC<IResultsPanel> = ({ taskId }) => {
  const solutionType = useSelector(selectSolution)?.type;
  const experimentType = useSelector(selectExperimentType);
  const status = useSelector(selectExperimentStatus);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (status === 'SOLVED') dispatch(setExperimentStatus('SOLVED_VIEWED'));
    if (status === 'FAILED') dispatch(setExperimentStatus('FAILED_VIEWED'));
    if (status === 'VALIDATION_ERROR')
      dispatch(setExperimentStatus('VALIDATION_ERROR_VIEWED'));
  }, [status]);

  if (
    [
      'FAILED',
      'FAILED_VIEWED',
      'VALIDATION_ERROR',
      'VALIDATION_ERROR_VIEWED',
    ].includes(status)
  )
    return (
      <div className="project-results-panel">
        <div className="project-results-panel__title-wrapper">
          <p className="project-results-panel__title">{solutionType}</p>
          <TaskStatus className="with-status without-stop-btn error" />
        </div>
        <p className="project-results-panel__message error">
          Расчет завершился с ошибкой.
        </p>
        <ErrorDetails />
      </div>
    );

  if (status === 'NONE')
    return (
      <div className="project-results-panel">
        <div className="project-results-panel__title-wrapper initial">
          <p className="project-results-panel__title">{experimentType}</p>
        </div>
        <p className="project-results-panel__message error">
          Расчет еще не проводился.
        </p>
      </div>
    );

  if (status === 'INTERRUPTED')
    return (
      <div className="project-results-panel">
        <div className="project-results-panel__title-wrapper initial">
          <p className="project-results-panel__title">{experimentType}</p>
        </div>
        <p className="project-results-panel__message error">
          Расчет был прерван пользоватлем.
        </p>
      </div>
    );

  if (status === 'INITIAL')
    return (
      <div className="project-results-panel">
        <div className="project-results-panel__title-wrapper initial">
          <p className="project-results-panel__title">{experimentType}</p>
        </div>
      </div>
    );

  return (
    <div className="project-results-panel">
      <div className="project-results-panel__title-wrapper d-flex">
        {[
          'DRAFT',
          'SENT',
          'PREPARING',
          'PREPARED',
          'SOLVING',
          'INTERRUPTING',
        ].includes(status) ? (
          <>
            <p className="project-results-panel__title">{solutionType}</p>
            <TaskStatus className="with-status" />
          </>
        ) : (
          <div className="project-results-panel-header">
            <p className="project-results-panel__title">{solutionType}</p>
            <div className="project-results-panel__buttons">
              {solutionType === 'Адаптация на факт' && (
                <AspoButton taskId={taskId} />
              )}
              <ExportButton taskId={taskId} />
            </div>
          </div>
        )}
      </div>
      {solutionType === 'Узловой анализ' && (
        <>
          <UADashboard />
          <UAResults />
        </>
      )}
      {solutionType === 'Адаптация на факт' && (
        <>
          <AFDashboard />
          <AFResults />
        </>
      )}
      {solutionType === 'Гидравлический расчет' && (
        <>
          <GRDashboard />
          <GRResults />
        </>
      )}
      {solutionType === 'Оптимизация режима' && (
        <>
          <MODashboard />
          <MOResults />
        </>
      )}
      {solutionType === 'Оптимизация сети' && (
        <>
          <NODashboard />
          <NOResults />
        </>
      )}
      <SegmentsScale />
    </div>
  );
};

export default ResultsPanel;
