import React from 'react';

import { components } from 'generated/apiTypes';

import { getExperimentSettings } from 'services/apiRequests';

const useGetExperimentSettings = (id: string) => {
  const [data, setData] = React.useState<
    components['schemas']['ExperimentSettings']
  >();

  React.useEffect(() => {
    const getData = async () => {
      try {
        const response = await getExperimentSettings(id);
        setData(response.data);
      } catch (e) {
        //
      }
    };

    getData();
  }, [id]);

  return data;
};

export default useGetExperimentSettings;
