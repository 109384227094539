import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { selectExperimentStatus } from 'store/taskSlice';

import { components } from '../../../generated/apiTypes';
import { setExperimentSettings } from '../../../services/apiRequests';
import useGetExperimentSettings from './hooks/use-get-experiment-settings';
import TaskSettingsInputRow from './TaskSettingInputRow';

const AFSettings: React.FC = () => {
  const params = useParams() as { id: string };
  const settings = useGetExperimentSettings(params.id);
  const status = useSelector(selectExperimentStatus);
  const [settingsState, setSettingsState] = React.useState<
    components['schemas']['ExperimentSettings'] | undefined
  >(undefined);

  React.useEffect(() => {
    setSettingsState(settings as components['schemas']['ExperimentSettings']);
  }, [settings]);

  const saveNewValue = (value: number, name: string) => {
    setExperimentSettings(params.id, {
      ...(settingsState as components['schemas']['ExperimentSettings']),
      [name]: value,
    });
    setSettingsState({
      ...(settingsState as components['schemas']['ExperimentSettings']),
      [name]: value,
    });
  };

  return (
    <div className="project-task-settings__row-wrapper full-size">
      <div className="project-task-settings__row">
        <TaskSettingsInputRow
          label="Точность расчетов"
          value={settingsState?.periodic_nodal_analysis_settings?.accuracy}
          unit="aтм"
          saveNewValue={saveNewValue}
          name="periodic_nodal_analysis_settings"
          subName="accuracy"
          disabled={[
            'DRAFT',
            'SENT',
            'PREPARING',
            'PREPARED',
            'SOLVING',
          ].includes(status)}
        />
      </div>
    </div>
  );
};

export default AFSettings;
