import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectNodeById,
  updateNodes,
  updateNodesNetwork,
} from 'store/projectSlice';

import { components } from '../../../generated/apiTypes';
import { setNode as saveNode } from '../../../services/apiRequests';
import { IObject } from '../types';
import ObjectInput from './components/ObjectInput';
import ObjectStringInput from './components/ObjectStringInput';

const Junction: React.FC<IObject> = ({ objectUid, projectUid }) => {
  const nodeById = useSelector(selectNodeById);
  const dispatch = useDispatch();

  const saveNewNodeValue = async (value: number | string, name: string) => {
    let newValue: components['schemas']['PNOPipelineNode'];
    if (name === 'lat')
      newValue = {
        ...nodeById[objectUid as string],
        coordinates: [
          value as number,
          nodeById[objectUid as string].coordinates?.[1],
        ],
      };
    else if (name === 'lon')
      newValue = {
        ...nodeById[objectUid as string],
        coordinates: [
          nodeById[objectUid as string].coordinates?.[0],
          value as number,
        ],
      };
    else newValue = { ...nodeById[objectUid as string], [name]: value };
    try {
      const response = await saveNode(
        projectUid,
        nodeById[objectUid as string]?.uid as string,
        newValue,
      );
      if (response.data) {
        dispatch(updateNodes(newValue));
        dispatch(updateNodesNetwork(true));
      }
    } catch (e) {
      //
    }
  };

  return (
    <div>
      <div className="active-object__top-row">
        <p className="active-object__title">Узел</p>
      </div>
      <div className="active-object__row">
        <ObjectStringInput
          className="input active-object__row-input long"
          label="Идентификатор"
          name="name"
          initValue={nodeById[objectUid as string]?.name}
          saveNewValue={saveNewNodeValue}
        />
      </div>
      <div className="active-object__row">
        <label className="input-label">Координаты</label>
        <div className="d-flex">
          <div className="active-object__row-input-prefix">lon</div>
          <ObjectInput
            className="input active-object__row-input short with-prefix left"
            label=""
            name="lon"
            initValue={nodeById[objectUid as string]?.coordinates?.[1]}
            saveNewValue={saveNewNodeValue}
          />
          <div className="active-object__row-input-prefix">lat</div>
          <ObjectInput
            className="input active-object__row-input short with-prefix"
            label=""
            name="lat"
            initValue={nodeById[objectUid as string]?.coordinates?.[0]}
            saveNewValue={saveNewNodeValue}
          />
        </div>
      </div>
    </div>
  );
};

export default Junction;
