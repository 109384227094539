import { useContext, useEffect } from 'react';
import { MapContext } from 'react-mapbox-gl';

function AddLayer(props: any) {
  const mapContext = useContext(MapContext);

  useEffect(() => {
    const layer = mapContext?.getLayer(
      props.layer_name ? props.layer_name : props.source_id,
    );
    const source = mapContext?.getSource(props.source_id);
    if (layer)
      mapContext?.removeLayer(
        props.layer_name ? props.layer_name : props.source_id,
      );
    if (source) {
      mapContext?.addLayer({
        id: props.layer_name ? props.layer_name : props.source_id,
        minzoom: 10,
        type: props.layer_type,
        source: props.source_id, // reference the data source
        layout: props.layer_layout ? props.layer_layout : {},
        paint: props.layer_paint ? props.layer_paint : {},
      });
    }
  }, []);

  return null;
}

export default AddLayer;
