import * as React from 'react';
import { ValueType } from 'react-select';

import Dropdown from '../../../../Components/Dropdown';

type OptionValue = string | number;

export type OptionType<T extends OptionValue> = {
  label: string;
  value: T;
};

interface IObjectSelect<T extends OptionValue> {
  saveNewValue?(value: T, name: string): void;
  saveNewOption?(value: ValueType<OptionType<T>, false>): void;
  options: OptionType<T>[];
  value: ValueType<OptionType<T>, false> | undefined;
  label: string;
  name: string;
  classNames: string;
  classNamesWrapper: string;
}

const ObjectSelect = <T extends OptionValue>({
  value,
  saveNewValue,
  options,
  label,
  name,
  classNames,
  classNamesWrapper,
  saveNewOption,
}: IObjectSelect<T>) => {
  const [option, setOption] = React.useState<
    ValueType<OptionType<T>, false> | undefined
  >(undefined);

  React.useEffect(() => {
    if (value) setOption(value);
  }, [value]);

  const changeValue = (newValue: any) => {
    setOption(newValue);
    if (saveNewValue) saveNewValue(newValue.value, name);
    if (saveNewOption) saveNewOption(newValue);
  };

  return (
    <div className={classNamesWrapper as string}>
      {label && <label className="input-label">{label}</label>}
      <Dropdown
        className={classNames}
        placeholder=""
        options={options as OptionType<any>[]}
        value={option as ValueType<any, any>}
        onChange={changeValue}
        menuWidth={name === 'heat_transfer_coef' ? '160px' : '100%'}
        isSearchable={false}
      />
    </div>
  );
};

export default ObjectSelect;
