import React from 'react';

import './style.scss';

interface ITaskSettingsCheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  checkedValue: boolean;
  setCheckedValue(value: boolean, name: string): void;
  label: string;
  id: string;
}

const Checkbox: React.FC<ITaskSettingsCheckboxProps> = ({
  checkedValue,
  setCheckedValue,
  label,
  id,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState<boolean>();

  React.useEffect(() => {
    setInputValue(checkedValue);
  }, [checkedValue]);

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.checked);
    setCheckedValue(e.target.checked, id);
  };
  return (
    <div className="checkbox-row">
      <input
        id={id}
        type="checkbox"
        className="checkbox-input"
        checked={inputValue}
        {...props}
        onChange={onChangeValue}
      />
      <label htmlFor={id} className="checkbox-label">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
