import { configureStore } from '@reduxjs/toolkit';

// import { createLogger } from 'redux-logger';
import socketMiddleware from '../utils/socketMiddleware';
import commonSlice from './commonSlice';
import mapSlice from './mapSlice';
import projectSlice from './projectSlice';
import projectsSlice from './projectsSlice';
import socketSlice from './socketSlice';
import taskSlice from './taskSlice';

// const logger = createLogger();

const store = configureStore({
  reducer: {
    project: projectSlice,
    projects: projectsSlice,
    common: commonSlice,
    map: mapSlice,
    task: taskSlice,
    socket: socketSlice,
  },
  middleware: [
    socketMiddleware,
    // logger
  ],
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
