import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { selectSolution } from 'store/taskSlice';

import { components } from '../../../../../generated/apiTypes';
import TableHead from '../../components/TableHead';
import TableWrapper from '../../components/TableWrapper';
import { ISegmentsTableProps } from '../../types';
import TableRow from './TableRow';
import TemplateTableRow from './TemplateTableRow';
import { templateRowsNumber } from './utils';

const SegmentsByStepTable: React.FC<ISegmentsTableProps> = ({
  tableHead,
  step = 1,
}) => {
  const solution = useSelector(selectSolution)
    ?.solution as components['schemas']['PNOTechNetworkOptimizationProblem'];
  const [data, setData] = React.useState<any[]>([]);
  const headKeys = Object.keys(tableHead);

  const getData = () => {
    const newData: any = [];
    solution?.solution?.segment_step_table?.segments
      ?.filter(
        (item, index, arr) => arr.findIndex(e => item.uid === e.uid) === index,
      )
      .forEach(segment => {
        const segmentStateBySegmentId =
          solution?.solution?.segment_step_table?.time2states &&
          Object.values(solution?.solution?.segment_step_table?.time2states)
            .find((val, index) => index === step - 1)
            ?.find(segment_state => segment_state.segment_uid === segment.uid);
        newData.push({
          looping: solution.solution.looping_segments.some(
            val => val.uid === segment.uid,
          ),
          start_node_name: segment?.start_node_name,
          end_node_name: segment?.end_node_name,
          length: segment?.length?.m,
          diameter: segment?.diameter?.m,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          p_in: segmentStateBySegmentId?.pressure_in?.atm,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dp_dl: segmentStateBySegmentId?.dp_dl?.atm_per_km,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dt_dl: segmentStateBySegmentId?.dt_dl?.celsius_per_km,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          oil_flow_rate: segmentStateBySegmentId?.oil_flow_rate_mass?.t_per_day,
          fluid_flow_rate:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            segmentStateBySegmentId?.fluid_flow_rate_volume?.m3_per_day,
          gas_flow_rate:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            segmentStateBySegmentId?.gas_flow_rate_volume?.thousand_m3_per_day,
          fluid_velocity: segmentStateBySegmentId?.fluid_velocity?.m_per_second,
          gas_velocity: segmentStateBySegmentId?.gas_velocity?.m_per_second,
        });
      });
    setData(newData);
  };

  React.useEffect(() => {
    getData();
  }, [solution, step]);

  return (
    <TableWrapper vertical horizontal smoothScrolling>
      <table className={classnames('table', 'table__segments-table')}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data?.length > 0
            ? data.map((value: any, index: number) => {
                return (
                  <TableRow
                    key={index}
                    headKeys={headKeys}
                    el={value}
                    className={value?.looping && 'looping'}
                  />
                );
              })
            : Array.from({ length: templateRowsNumber }, (item, index) => (
                <TemplateTableRow key={index} headKeys={headKeys} />
              ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default SegmentsByStepTable;
