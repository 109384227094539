import * as React from 'react';

import classNames from 'classnames';

import { components } from '../../../../generated/apiTypes';
import ObjectInput from '../components/ObjectInput';

interface ISinkViewAF {
  gatheringCenterFactPressure:
    | components['schemas']['PNOGatheringCenterFactPressure']
    | undefined;
  saveNewGatheringCenterFactPressureValue(
    value: number | string,
    name: string,
  ): void;
}

const SinkViewAF: React.FC<ISinkViewAF> = ({
  gatheringCenterFactPressure,
  saveNewGatheringCenterFactPressureValue,
}) => {
  return (
    <div className="active-object__row">
      <label className="input-label">Давление на входе</label>
      <div className="d-flex">
        <div className="active-object__input-with-unit-wrapper short">
          <div className="active-object__row-input-prefix">факт</div>
          <ObjectInput
            className={classNames(
              'input active-object__row-input short with-unit',
              'with-prefix',
            )}
            label=""
            name="pressure"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            initValue={gatheringCenterFactPressure?.pressure.atm}
            saveNewValue={saveNewGatheringCenterFactPressureValue}
          />
          <p className="active-object__row-input-unit">атм</p>
        </div>
      </div>
    </div>
  );
};

export default SinkViewAF;
