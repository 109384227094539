import * as React from 'react';

import Button from '../../../../../Components/Button';

interface INoModelView {
  createNewModel(): void;
}

const NoModelView: React.FC<INoModelView> = ({ createNewModel }) => {
  return (
    <>
      <p className="no-model__message">
        Модель скважины для данного параметра отсутствует в данных
        конфигурационного файла.
      </p>
      <div className="no-model__create-btn">
        <Button type="button" mode="secondary" onClick={createNewModel}>
          Создать модель
        </Button>
      </div>
    </>
  );
};

export default NoModelView;
